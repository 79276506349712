import { create } from "zustand";
import {
  getContentAwareConfig,
  updateContentAwareConfig,
  getContentAwareLicense,
  createContentAwareLicense,
  deleteContentAwareLicense,
  deactivateContentAwareLicense,
  reactivateContentAwareLicense,
  updatesContentAwareLicense,
  ContentAwareConfig,
  ContentAwareLicense,
  ContentAwareLicenseStatus,
} from "../utils/api/ContentAware";
import { calculateTrialPeriodDetails } from "../utils/DateTimeUtil";
import { useSessionStore } from "./SessionStore";

/** Defines Trial license status message to be displayed to user */
export interface ContentAwareTrialLicenseBannerMessage {
  title: string;
  status: "info" | "warning" | "success" | "error";
  description: string;
  trialEnded?: boolean;
}

export interface ContentAwareTrialLicenseModalMessage {
  title: string;
  status: "warning" | "danger" | undefined;
  description: string;
  seen?: boolean;
}

interface ContentAwareStore {
  config: ContentAwareConfig | null;
  license: ContentAwareLicense | null;
  loading: boolean;
  error: string | null;
  bannerMessage: ContentAwareTrialLicenseBannerMessage | null;
  modalMessage: ContentAwareTrialLicenseModalMessage | null;
  fetchContentAwareConfig: () => Promise<void>;
  updateContentAwareConfig: (config: ContentAwareConfig) => Promise<void>;
  fetchContentAwareLicense: () => Promise<void>;
  createLicense: (config: ContentAwareLicense) => Promise<void>;
  updateTrialLicense: (config: ContentAwareLicense) => Promise<void>;
  deleteLicense: () => Promise<void>;
  deactivateLicense: () => Promise<void>;
  reactivateLicense: () => Promise<void>;
  notificationMessages: () => void;
  modalMessageIsSeen: (seen: boolean) => void;
  getLicense: () => ContentAwareLicense | null;
  getBannerMessage: () => ContentAwareTrialLicenseBannerMessage | null;
  getModalMessage: () => ContentAwareTrialLicenseModalMessage | null;
  reset: () => void;
}

export const useContentAwareStore = create<ContentAwareStore>((set, get) => ({
  config: null,
  license: null,
  loading: false,
  error: null,
  bannerMessage: null,
  modalMessage: null,
  fetchContentAwareConfig: async () => {
    const existingConfig = get().config;
    if (existingConfig?.defaultRules) {
      return;
    }

    set({ loading: true, error: null });
    try {
      const config = await getContentAwareConfig();
      set({ config });
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },
  getLicense: () => {
    return get().license;
  },

  getBannerMessage: () => {
    return get().bannerMessage;
  },
  getModalMessage: () => {
    return get().modalMessage;
  },

  updateContentAwareConfig: async (config: ContentAwareConfig) => {
    set({ loading: true, error: null });
    try {
      const updatedConfig = await updateContentAwareConfig(config);
      set({ config: updatedConfig });
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  fetchContentAwareLicense: async () => {
    const existingLicense = get().license;
    if (existingLicense?.key) {
      return;
    }
    set({ loading: true, error: null });
    try {
      const license = await getContentAwareLicense();
      set({ license });
      get().notificationMessages();
    } catch (error) {
      const errorMessage = (error as Error).message;
      // No license is a valid response, don't show an error toast for this.
      if (errorMessage !== "License not found for appliance") {
        set({ error: (error as Error).message });
        throw error;
      }
    } finally {
      set({ loading: false });
    }
  },

  createLicense: async (config: ContentAwareLicense) => {
    set({ loading: true, error: null });
    try {
      const license = await createContentAwareLicense(config);
      set({ license });
      get().notificationMessages();
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  updateTrialLicense: async (config: ContentAwareLicense) => {
    set({ loading: true, error: null });
    try {
      const license = await updatesContentAwareLicense(config);
      set({ license });
      get().notificationMessages();
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  deleteLicense: async () => {
    set({ loading: true, error: null });
    try {
      await deleteContentAwareLicense();
      set({ license: {} as ContentAwareLicense, bannerMessage: null, modalMessage: null });
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  deactivateLicense: async () => {
    set({ loading: true, error: null });
    try {
      const deactivatedLicense = await deactivateContentAwareLicense();
      set({ license: deactivatedLicense, bannerMessage: null, modalMessage: null });
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  reactivateLicense: async () => {
    set({ loading: true, error: null });
    try {
      const reactivatedLicense = await reactivateContentAwareLicense();
      set({ license: reactivatedLicense });
      get().notificationMessages();
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  reset: (): void => {
    set({ license: null });
  },
  notificationMessages: () => {
    const license = get().license;
    if (license?.status === ContentAwareLicenseStatus.Suspended || !license?.startDate) {
      set({
        modalMessage: null,
        bannerMessage: null,
      });
      return;
    }
    const { trialStartDate, trialExpiryDate, trialDuration, trialDaysLapsed } = calculateTrialPeriodDetails(
      license?.startDate,
      license?.endDate
    );

    const isSupportAdmin = useSessionStore.getState().isSupportAdmin();
    const deviceName = useSessionStore.getState().getDevice().name;
    const existingModalMessage = get().modalMessage;

    if (trialDaysLapsed <= 0) {
      if (isSupportAdmin) {
        set({
          bannerMessage: {
            status: "info",
            title: "",
            description: `The last trial license was active for ${trialDuration} days from ${trialStartDate} to ${trialExpiryDate}`,
            trialEnded: true,
          },
        });

        set({
          modalMessage: {
            status: "warning",
            title: `${deviceName}'s Content Aware trial license has expired`,
            description: `The trial license has expired on <b>${trialExpiryDate}</b>.
            <br>
            <br>
            Visit <b>Configuration > Licenses > Filtering</b> page to activate the license again.
            `,
            seen: existingModalMessage?.seen,
          },
        });
      } else {
        set({
          bannerMessage: {
            status: "error",
            title: `The trial license is expired on ${trialExpiryDate}.`,
            description: `Please reach out to support team to get the full access of content aware filtering.`,
          },
        });

        set({
          modalMessage: {
            status: "warning",
            title: `Your Content Aware trial license has expired`,
            description: `The trial license has expired on <b>${trialExpiryDate}</b>.
            <br>
            <br>
            Please reach out to support team to upgrade to a full license and get the full access of content aware filtering.
            `,
            seen: existingModalMessage?.seen,
          },
        });
      }
    } else if (trialDaysLapsed > 0 && trialDaysLapsed <= 5) {
      if (isSupportAdmin) {
        set({
          bannerMessage: {
            status: "warning",
            title: `The trial license is active for ${trialDaysLapsed} days and will expire on ${trialExpiryDate}.`,
            description: `After ${trialExpiryDate}, the Content Aware configuration under Filter will be automatically disabled.`,
          },
        });

        set({
          modalMessage: {
            status: "warning",
            title: `${deviceName}'s Content Aware trial license is expiring in ${trialDaysLapsed} days`,
            description: `The trial license will expire on <b>${trialExpiryDate}</b>.
            <br>
            <br>
            After the license is expired, the Content Aware configuration under Filter will be automatically disabled.
            <br>
            <br>
            Visit <b>Configuration > Licenses > Filtering</b> page to extend or deactivate the licence
            `,
            seen: existingModalMessage?.seen,
          },
        });
      } else {
        set({
          bannerMessage: {
            status: "warning",
            title: `The trial license is active for ${trialDaysLapsed} days and will expire on ${trialExpiryDate}.`,
            description: `After ${trialExpiryDate}, the configuration below will be automatically disabled. You can reach out to the support team to upgrade to a full license`,
          },
        });
        set({
          modalMessage: {
            status: "warning",
            title: `Your Content Aware trial license is expiring in ${trialDaysLapsed} days`,
            description: `The trial license will expire on <b>${trialExpiryDate}</b>.
            <br>
            <br>
            After the license is expired, the Content Aware configuration under Filter will be automatically disabled.
            <br>
            <br>
            You can reach out to the support team to upgrade to a full license.
            `,
            seen: existingModalMessage?.seen,
          },
        });
      }
    } else if (trialDaysLapsed > 5) {
      set({
        bannerMessage: {
          status: "success",
          title: `The trial license is active for ${trialDaysLapsed} days and will expire on ${trialExpiryDate}.`,
          description: `After ${trialExpiryDate}, the Content Aware configuration under Filter will be automatically disabled.`,
        },
      });

      if (!isSupportAdmin) {
        set({
          modalMessage: {
            status: undefined,
            title: `Content Aware trial license has been activated`,
            description: `
            The trial license for Content Aware has been activated for ${trialDaysLapsed} days and it will expire on <b>${trialExpiryDate}</b>.
            <br>
            <br>
            Visit  <b>Filtering > Content Aware</b> page to configure the Content Aware filtering.
            `,
            seen: existingModalMessage?.seen,
          },
        });
      }
    }
  },
  modalMessageIsSeen: (seen: boolean) => {
    const existingMessage = get().modalMessage;

    if (existingMessage?.title && existingMessage.description) {
      set({
        modalMessage: {
          status: undefined,
          title: "",
          description: "",
          seen,
        },
      });
    }
  },
}));
