import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Box, Button, useTranslation, useToast, Checkbox, Radio, RadioGroup, InlineNotification } from "@familyzone/component-library";

import CardBasedPage from "../../templates/CardBasedPage";
import { SectionHeading } from "../../shared/SectionHeading";

import { ContentAwareConfig, getContentAwareConfig, updateContentAwareConfig } from "../../../utils/api/ContentAware";
import { DomainsMultiSelectInput } from "../../shared/DomainsMultiSelectInput";
import { useContentAwareStore } from "../../../storez/ContentAwareStore";
import { calculateTrialPeriodDetails } from "../../..//utils/DateTimeUtil";

const ContentAwareConfigPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const title = t("Content Aware");
  const breadcrumbs = [
    { title: t("Filtering"), url: "/filtering", isActive: false },
    { title: t("Content Aware"), isActive: true },
  ];

  const { successToast, errorToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  const DEFAULTS = {
    gore: {
      enabled: false,
      sensitivity: 0.5,
    },
    porn: {
      enabled: true,
      sensitivity: 0.5,
    },
    intimateApparel: {
      enabled: true,
      sensitivity: 0,
    },
    guiltByAssociation: false,
    excludedDomains: [],
  };

  const [goreDetectionEnabled, setGoreDetectionEnabled] = useState<boolean>(DEFAULTS.gore.enabled);
  const [goreDetectionSensitivity, setGoreDetectionSensitivity] = useState<number>(DEFAULTS.gore.sensitivity);

  const [intimateApparelDetectionEnabled, setIntimateApparelDetectionEnabled] = useState<boolean>(DEFAULTS.porn.enabled);
  const [intimateApparelDetectionSensitivity, setIntimateApparelDetectionSensitivity] = useState<number>(DEFAULTS.porn.sensitivity);

  const [pornDetectionEnabled, setPornDetectionEnabled] = useState<boolean>(DEFAULTS.intimateApparel.enabled);
  const [pornDetectionSensitivity, setPornDetectionSensitivity] = useState<number>(DEFAULTS.intimateApparel.sensitivity);

  const [guiltByAssociationEnabled, setGuiltByAssociationEnabled] = useState<boolean>(DEFAULTS.guiltByAssociation);

  const [excludedDomains, setExcludedDomains] = useState<string[]>([]);

  const fetchContentAwareLicense = useContentAwareStore((state) => state.fetchContentAwareLicense);
  const contentAwareLicense = useContentAwareStore((state) => state.license);

  // Check if the license is not expired
  let licenseIsNotExpired = true;
  if (contentAwareLicense?.startDate && contentAwareLicense?.endDate) {
    const { trialDaysLapsed } = calculateTrialPeriodDetails(contentAwareLicense?.startDate, contentAwareLicense?.endDate);
    if (trialDaysLapsed <= 0) {
      licenseIsNotExpired = false;
    }
  }

  // Disable controls if the trial license is not active, or if the page is loading or saving
  const controlsDisabled = !licenseIsNotExpired || loading || saving;

  const bannerMessage = useContentAwareStore((state) => state.getBannerMessage);

  function handleToggleGoreDetection(event: ChangeEvent<HTMLInputElement>) {
    setGoreDetectionEnabled(event.target.checked);
  }

  function handleTogglePornographyDetection(event: ChangeEvent<HTMLInputElement>) {
    setPornDetectionEnabled(event.target.checked);
  }

  function handleToggleIntimateApparelDetection(event: ChangeEvent<HTMLInputElement>) {
    setIntimateApparelDetectionEnabled(event.target.checked);
  }

  function handleToggleGuiltByAssociation(event: ChangeEvent<HTMLInputElement>) {
    setGuiltByAssociationEnabled(event.target.checked);
  }

  const fetchContentAwareConfig = useCallback(() => {
    setLoading(true);

    getContentAwareConfig()
      .then((config) => {
        const goreDetectionEnabled = config.defaultRules.gore !== -1;
        const pornDetectionEnabled = config.defaultRules.porn !== -1;
        const intimateApparelDetectionEnabled = config.defaultRules.swimwear !== -1;

        setGoreDetectionEnabled(goreDetectionEnabled);
        setGoreDetectionSensitivity(goreDetectionEnabled ? config.defaultRules.gore : 0.5);

        setPornDetectionEnabled(pornDetectionEnabled);
        setPornDetectionSensitivity(pornDetectionEnabled ? config.defaultRules.porn : 0.5);

        setIntimateApparelDetectionEnabled(intimateApparelDetectionEnabled);
        setIntimateApparelDetectionSensitivity(intimateApparelDetectionEnabled ? config.defaultRules.swimwear : 0.5);

        setGuiltByAssociationEnabled(config.guiltByAssociationEnabled);
        setExcludedDomains(config.neverRunOn);
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
          // No config is a valid response, don't show an error toast for this.
          if (errorMessage === "Content Aware config not found") return;
        }
        errorToast({
          title: t("Couldn't fetch Content Aware config"),
          description: errorMessage || "Unexpected issue fetching config, please contact Support if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, errorToast]);

  useEffect(() => {
    fetchContentAwareConfig();
  }, [fetchContentAwareConfig]);

  useEffect(() => {
    void fetchContentAwareLicense();
  }, [fetchContentAwareLicense]);

  function saveContentAwareConfig(): void {
    setSaving(true);

    const contentAwareConfig: ContentAwareConfig = {
      defaultRules: {
        gore: goreDetectionEnabled ? goreDetectionSensitivity : -1,
        porn: pornDetectionEnabled ? pornDetectionSensitivity : -1,
        swimwear: intimateApparelDetectionEnabled ? intimateApparelDetectionSensitivity : -1,
      },
      guiltByAssociationEnabled: guiltByAssociationEnabled,
      neverRunOn: excludedDomains,
    };

    if (excludedDomains.length > 50) {
      errorToast({
        title: t("Couldn't save Content Aware config"),
        description:
          "The number of excluded domains exceeds the maximum allowed amount. Please make sure that there are no more than 50 domains entered.",
        position: "bottom-right",
      });
      setSaving(false);
      return;
    }

    updateContentAwareConfig(contentAwareConfig)
      .then(() => {
        successToast({
          title: t("Changes Applied Successfully"),
          description: t("Your settings have now been saved and applied. "),
          position: "bottom-right",
        });
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        errorToast({
          title: t("Couldn't save Content Aware config"),
          description: errorMessage || "Unexpected issue saving config, please contact Support if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
      });

    return;
  }

  function handleGoreSensitivityChange(nextValue: string) {
    setGoreDetectionSensitivity(Number(nextValue));
  }
  function handlePornSensitivityChange(nextValue: string) {
    setPornDetectionSensitivity(Number(nextValue));
  }
  function handleIntimateApparelSensitivityChange(nextValue: string) {
    setIntimateApparelDetectionSensitivity(Number(nextValue));
  }

  function handleResetConfig() {
    setExcludedDomains([]);
    setGoreDetectionEnabled(DEFAULTS.gore.enabled);
    setGoreDetectionSensitivity(DEFAULTS.gore.sensitivity);
    setPornDetectionEnabled(DEFAULTS.porn.enabled);
    setPornDetectionSensitivity(DEFAULTS.porn.sensitivity);
    setIntimateApparelDetectionEnabled(DEFAULTS.intimateApparel.enabled);
    setIntimateApparelDetectionSensitivity(DEFAULTS.intimateApparel.sensitivity);
    setGuiltByAssociationEnabled(DEFAULTS.guiltByAssociation);
  }

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box p="sp24">
        <Box>
          {bannerMessage() && contentAwareLicense?.startDate && (
            <InlineNotification
              notificationTitle={bannerMessage()?.title}
              notificationDescription={bannerMessage()?.description || ""}
              status={bannerMessage()?.status || "warning"}
            />
          )}
          <Box my={10}></Box>

          <SectionHeading title={t("Categories")} tooltip={t("The content categories that can be detected by Content Aware filtering.")} />
          <Box p={10}>
            <Box my={20}>
              <Checkbox
                id="gore_checkbox"
                isChecked={goreDetectionEnabled}
                isDisabled={controlsDisabled}
                onChange={handleToggleGoreDetection}
              >
                <Text fontWeight="medium">{t("Gore detection")}</Text>
              </Checkbox>
              {goreDetectionEnabled && (
                <Flex my={10} ml={10}>
                  <Text mr={10} color={"neutrals.600"}>
                    Select sensitivity level:
                  </Text>
                  <RadioGroup defaultValue={0.5} direction="row" value={goreDetectionSensitivity} onChange={handleGoreSensitivityChange}>
                    <Radio isDisabled={controlsDisabled} value={0}>
                      Low
                    </Radio>
                    <Radio isDisabled={controlsDisabled} value={0.5}>
                      Medium
                    </Radio>
                    <Radio isDisabled={controlsDisabled} value={1}>
                      High
                    </Radio>
                  </RadioGroup>
                </Flex>
              )}
            </Box>
            <Box my={20}>
              <Checkbox
                id="pornography_checkbox"
                isChecked={pornDetectionEnabled}
                isDisabled={controlsDisabled}
                onChange={handleTogglePornographyDetection}
              >
                <Text fontWeight="medium">{t("Pornography detection")}</Text>
              </Checkbox>
              {pornDetectionEnabled && (
                <Flex alignItems={"center"} my={10} ml={10}>
                  <Text mr={10} color={"neutrals.600"}>
                    Select sensitivity level:
                  </Text>
                  <RadioGroup defaultValue={0.5} direction="row" value={pornDetectionSensitivity} onChange={handlePornSensitivityChange}>
                    <Radio isDisabled={controlsDisabled} value={0}>
                      Low
                    </Radio>
                    <Radio isDisabled={controlsDisabled} value={0.5}>
                      Medium
                    </Radio>
                    <Radio isDisabled={controlsDisabled} value={1}>
                      High
                    </Radio>
                  </RadioGroup>
                </Flex>
              )}
            </Box>
            <Box my={20}>
              <Checkbox
                id="intimate_apparel_checkbox"
                isChecked={intimateApparelDetectionEnabled}
                isDisabled={controlsDisabled}
                onChange={handleToggleIntimateApparelDetection}
              >
                <Text fontWeight="medium">{t("Intimate Apparel detection")}</Text>
              </Checkbox>
              {intimateApparelDetectionEnabled && (
                <Flex my={10} ml={10}>
                  <Text mr={10} color={"neutrals.600"}>
                    Select sensitivity level:
                  </Text>
                  <RadioGroup
                    defaultValue={0.5}
                    direction="row"
                    value={intimateApparelDetectionSensitivity}
                    onChange={handleIntimateApparelSensitivityChange}
                  >
                    <Radio isDisabled={controlsDisabled} value={0}>
                      Low
                    </Radio>
                    <Radio isDisabled={controlsDisabled} value={0.5}>
                      Medium
                    </Radio>
                    <Radio isDisabled={controlsDisabled} value={1}>
                      High
                    </Radio>
                  </RadioGroup>
                </Flex>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <SectionHeading
            title={t("Guilt by association")}
            tooltip={t(
              "Blocks all images on a webpage, if a certain number of images on the page are found to be associated with a blocked category."
            )}
          />
          <Box p={10}>
            <Text mb={20}>Configure guilt by association settings.</Text>
            <Box my={10}>
              <Checkbox
                id="guilt_by_association_checkbox"
                isChecked={guiltByAssociationEnabled}
                isDisabled={controlsDisabled}
                onChange={handleToggleGuiltByAssociation}
              >
                {t("Enable guilt by association")}
              </Checkbox>
            </Box>
          </Box>
        </Box>

        <Box>
          <SectionHeading
            title={t("Excluded domains")}
            tooltip={t("Any domains added here will not have Content Aware filters applied to them.")}
          />
          <Box p={10}>
            <Text mb={20}>Add a URL to exempt it from current settings.</Text>
            <Box data-testid="excluded-domains-selector">
              <Text mb="6">Excluded domains/subdomains (max 50 domains)</Text>
              <DomainsMultiSelectInput
                placeholder={t("Paste domain/subdomain URLs")}
                domains={excludedDomains}
                onDomainsChanged={setExcludedDomains}
                disabled={controlsDisabled}
              />
              <Text mt="6" color="text.paragraph.regular" fontSize={"sm"}>
                You can exclude individual domains (example.com) and subdomains (test.example.com), or use the * wildcard to exclude a
                domain and all its subdomains (*.example.com)
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Flex bg={"#F5FCFF"} p="sp24" overflow={"hidden"}>
        <Button
          variant="primary"
          disabled={controlsDisabled}
          onClick={saveContentAwareConfig}
          data-testid="save-content-aware-config-button"
          mr={10}
        >
          {saving ? t("Applying") : t("Apply")}
        </Button>
        <Button
          variant="outline"
          disabled={controlsDisabled}
          onClick={handleResetConfig}
          data-testid="reset-content-aware-config-button"
          mr={10}
        >
          {t("Reset to defaults")}
        </Button>
      </Flex>
    </CardBasedPage>
  );
};

export default ContentAwareConfigPage;
