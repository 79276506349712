/**
 * Represents the 'selected' device available via {@code useSessionStore}.
 */
export interface Device {
  id: string;
  name: string;
  region: "us-1" | "syd-2" | "uk-1" | "beta-1" | "sit" | "unittest";
  timezone: string;
}

/**
 * Represents the full device config available via {code useDeviceOptionsStore}.
 *
 * NOTE: Currently, only the properties used in Typescript components are listed
 * here, but there are more (see API response); define additional props as needed.
 */
export interface DeviceOptions {
  deviceid?: string;
  display_name?: string;
  display_machine_name?: boolean;
  timezone?: string;
  allow_extension_connections?: boolean;
  allow_support_school_manager?: boolean;
  allow_support_zendesk?: boolean;
  allow_support_forethought_ai?: boolean;
}

export const DeviceOptionsEquals = (o1?: DeviceOptions, o2?: DeviceOptions): boolean => {
  if (!o1 || !o2) {
    return o1 === o2;
  }

  const k1 = Object.keys(o1);
  const k2 = Object.keys(o2);

  if (k1.length === k2.length) {
    for (const k of k1) {
      const tk = k as keyof DeviceOptions;
      if (o1[tk] !== o2[tk]) {
        return false;
      }
    }
    return true;
  }

  return false;
};
