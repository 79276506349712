import React, { useState } from "react";
import PropTypes from "prop-types";

import { UnifiedUserUMS } from "../../../types/Users";
import { deleteUser, purgeUsers, searchUnifiedUsers } from "../../../utils/api/Users";
import UnifiedUsersNew from "./UnifiedUsersNew";
import UserSearchBox from "../../UserSearch/UserSearchBox";
import { useToast } from "@familyzone/component-library";
import { PurgeType } from "../../../utils/api/Helpers";

const UnifiedUsers: React.FC = (): JSX.Element => {
  const { successToast, errorToast } = useToast();
  const [users, setUsers] = useState<UnifiedUserUMS[]>([]);
  const [loading, setLoading] = useState(false);

  const handlePurgeUsers = (type: PurgeType) => {
    purgeUsers(type)
      .then(() => {
        successToast({
          title: "Success!",
          description: "We've received the request and it has been sent for processing.",
        });
      })
      .catch(() => {
        errorToast({
          title: "Error",
          description: "We're not able to process the purge at this time. Please try again later.",
        });
      });
  };

  const handleDelete = (id: string) => {
    deleteUser(id)
      .then(() => {
        successToast({
          title: "Success!",
          description: "We've received the request and it has been sent for processing.",
        });
      })
      .catch(() => {
        errorToast({
          title: "Error",
          description: "We're not able to delete the user at this time. Please try again later.",
        });
      });
  };

  return (
    <UnifiedUsersNew
      users={users}
      loaded={!loading}
      onPurgeUsers={handlePurgeUsers}
      onDeleteUser={handleDelete}
      withinTableChildren={
        <UserSearchBox
          searchEnabled={true}
          setUsers={setUsers}
          setLoading={setLoading}
          excludeArchivedUsers={false}
          searchUsers={searchUnifiedUsers}
        />
      }
      isSearching={loading}
    />
  );
};

UnifiedUsers.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default UnifiedUsers;
