import { InlineNotification, MultiSearchSelector, Option, Text, useTranslation } from "@familyzone/component-library";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";
import { School } from "../../types/Schools";
import { searchSchools } from "../../utils/api/Schools";
import { mapSchoolsToOptions } from "./SchoolSearchHelper";

interface Props {
  preselected?: Option[];
  onChangeSchools?: (schools: Option[]) => void;
  onClickSchool?: (school: Option) => void;
  disabled?: boolean;
}

export const MultiSchoolSearchSelector: FC<Props> = ({ preselected, onChangeSchools, onClickSchool, disabled }) => {
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<School[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const impliedSchoolsText = "Note: Only assigned schools from SIS syncs are shown. Classroom enrolments may include additional schools.";

  // Sometimes preselected values are not set immediately upon page load, so we include the object as a dependency.
  useEffect(() => {
    // This prevents overwriting a user's selection if preselected somehow changes after the user has already picked a school.
    if (preselected && selectedOptions.length === 0) {
      setSelectedOptions(preselected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselected]);

  const handleSchoolChange = (selected: Option[]) => {
    setSelectedOptions(selected);

    if (onChangeSchools && preselected) {
      onChangeSchools([...selected]);
    }
  };

  useDebounce(
    () => {
      setDebouncedSearch(searchTerm?.trim());
    },
    500,
    [searchTerm]
  );

  useEffect(() => {
    if (debouncedSearch) {
      void searchSchools(debouncedSearch).then((result) => setSearchResults(result));
    }
  }, [debouncedSearch]);

  const handleInput = (input: string) => {
    if (input.trim().length >= 3) setSearchTerm(input);
    else setSearchResults([]);
  };

  return (
    <>
      <MultiSearchSelector
        disabled={disabled}
        name="school-search-selector"
        placeholder={t("Search Schools")}
        selected={selectedOptions}
        options={mapSchoolsToOptions(searchResults)}
        onChange={handleSchoolChange}
        clickOption={onClickSchool}
        onInputChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e.target.value)}
        filterOptions={false}
      />
      <Text color="neutrals.200" ml="4" mt="6">
        Please enter 3 or more characters to search.
      </Text>
      <InlineNotification notificationDescription={t(impliedSchoolsText)} status="info" />
    </>
  );
};
