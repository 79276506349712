import React from "react";
import PropTypes from "prop-types";
import Api from "../../utils/Api";

import SessionActions from "../../actions/SessionActions";
import SessionStore from "../../stores/SessionStore";
import { Spinner } from "@chakra-ui/spinner";
import { Flex, LinewizeLogoWithText, Text } from "@familyzone/component-library";

export default class LoginGoogle extends React.Component {
  componentDidMount() {
    SessionStore.listen(this.onChange);
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
  }

  onChange = () => {
    if (SessionStore.isAuthenticated()) {
      this.context.router.push("/");
    }

    if (SessionStore.failedLogin()) {
      this.context.router.push("/");
    }
  };

  render() {
    Api.unauthenticated_post(
      "/login/google/callback?code=" + this.props.location.query.code,
      {},
      function (data) {
        SessionStore.resetLoginFailure();
        SessionActions.authenticateSuccess(data);
      },
      function (error) {
        SessionActions.authenticateFailure(error);
      }
    );

    return (
      <Flex height="100%" direction="column">
        <Flex backgroundColor="white" height="58px" pl="12px" alignItems="center">
          <LinewizeLogoWithText color="#0075DB" height="24px" width="110px" />
        </Flex>
        <Flex justifyContent="center" alignItems="center" direction="column" grow={1}>
          <Spinner width="40px" height="40px" />
          <Text color="#051839" fontFamily="Roboto" mt="12px">
            Signing in...
          </Text>
        </Flex>
      </Flex>
    );
  }
}

LoginGoogle.contextTypes = {
  router: PropTypes.object.isRequired,
};
