import { Box, Input, Text, useTranslation } from "@familyzone/component-library";
import React, { useState, useEffect, useCallback } from "react";

export interface UserSearchBoxProps<TUser> {
  searchEnabled: boolean;
  excludeArchivedUsers?: boolean;
  setUsers: React.Dispatch<React.SetStateAction<TUser[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchUsers: (query: string, excludeArchived?: boolean) => Promise<TUser[]>;
}

const UserSearchBox = <TUser,>({
  searchEnabled,
  excludeArchivedUsers = true,
  searchUsers,
  setLoading,
  setUsers,
}: UserSearchBoxProps<TUser>): JSX.Element => {
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const minimumSearchLength = 3;

  const search = useCallback(
    async (searchString: string) => {
      try {
        setLoading(true);
        setUsers([]);
        const result = await searchUsers(searchString, excludeArchivedUsers);
        setUsers(result);
      } catch (e) {
        console.log(e);
        setUsers([]);
      } finally {
        setLoading(false);
      }
    },
    [searchUsers, setUsers, setLoading, excludeArchivedUsers]
  );

  useEffect(() => {
    if (searchInput.length < minimumSearchLength) return;
    setLoading(true);
    const debounce = setTimeout(() => {
      setDebouncedValue(searchInput);
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
  }, [searchInput, setLoading, minimumSearchLength]);

  useEffect(() => {
    if (searchEnabled) {
      void search(debouncedValue);
    }
  }, [debouncedValue, search, searchEnabled]);

  return (
    <Box width="536px">
      <Input placeholder={t("Search")} onChange={(e) => setSearchInput(e.target.value)} value={searchInput} />
      <Text color="neutrals.200" ml="4" mt="6">
        To search, please enter 3 or more characters.
      </Text>
    </Box>
  );
};

export default UserSearchBox;
