import React from "react";
import { UserUMS } from "../../../types/Users";
import SortSearchTable from "../../templates/SortSearchTable";
import { Tr, Td, useTranslation, Text } from "@familyzone/component-library";
import Link from "../../Link";

interface SourceUsersProps {
  sourceUsers: UserUMS[];
}

export const SourceUsers = ({ sourceUsers }: SourceUsersProps): JSX.Element => {
  const { t } = useTranslation();

  const columns = [
    { headerText: t("Username"), columnName: "username", sortable: false, searchable: false },
    { headerText: t("First Name"), columnName: "firstName", sortable: false, searchable: false },
    { headerText: t("Last Name"), columnName: "lastName", sortable: false, searchable: false },
    { headerText: t("Email"), columnName: "email", sortable: false, searchable: false },
    { headerText: t("Provider Type"), columnName: "sourceType", sortable: false, searchable: false },
  ];

  const tableDataMap = (user: UserUMS, index: number): JSX.Element => {
    return (
      <Tr key={index}>
        <Td data-testid="user-username">
          <Link to={`/config/device/userdb/users/id/${user.id}`}>
            <Text fontSize={"sm"}>{user.username}</Text>
          </Link>
        </Td>
        <Td data-testid="user-first-name">
          <Text>{user.firstName}</Text>
        </Td>
        <Td data-testid="user-last-name">
          <Text>{user.lastName}</Text>
        </Td>
        <Td data-testid="user-email">
          <Text>{user.email}</Text>
        </Td>
        <Td data-testid="source-type">
          <Text>{user.sourceType}</Text>
        </Td>
      </Tr>
    );
  };

  return (
    <SortSearchTable
      showSearch={false}
      loaded={true}
      columns={columns}
      data={sourceUsers}
      tableDataMap={tableDataMap}
      tableTopMargin={"0"}
      tableMargin={"0"}
      tablePadding={"0"}
    />
  );
};
