import { School } from "../../types/Schools";
import { Option } from "@familyzone/component-library";

export const schoolLabelFormatter = (name: string, description?: string): string => {
  if (description) {
    return description + " (" + name + ")";
  }
  return name;
};

export const mapSchoolToOption = (school: School): Option => {
  const result: Option = { value: school.id, id: school.id, name: school.name };
  if (school.description) {
    result.label = schoolLabelFormatter(school.name, school.description);
  }
  return result;
};

export const mapSchoolsToOptions = (schools: School[]): Option[] => {
  return schools.map(mapSchoolToOption);
};
