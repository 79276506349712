import React, { FC } from "react";
import { Button, Checkbox, Flex, Icon, Link, Modal, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";

interface RedirectNotificationProps {
  open: boolean;
  onClose: (dontShowAgain: boolean) => void;
  url: string;
}

export const RedirectNotification: FC<RedirectNotificationProps> = ({ open, onClose, url }) => {
  const [dontShowAgain, setDontShowAgain] = React.useState<boolean>(false);

  return (
    <Modal isOpen={open} onClose={() => onClose(dontShowAgain)} size="sm">
      <ModalOverlay bg="rgba(0, 0, 0, 0.68)" />
      <ModalContent>
        <Flex textAlign="center" justifyContent="center" alignItems="center" flexDir="column">
          <Flex bgColor="#FFF2D9" borderRadius="50%" w="50px" h="50px" justifyContent="center" alignItems="center" margin="auto">
            <Icon color="#CB4655" w="20px" h="20px">
              <i className="fa fa-bookmark" />
            </Icon>
          </Flex>

          <Text fontFamily="heading" fontWeight="500" fontSize="22px" padding="28px" textColor="neutrals.900">
            Bookmark the new URL
          </Text>
          <Text fontSize="16px" lineHeight="28px" textColor="neutrals.900">
            Your School Manager URL is changing to
          </Text>
          <Link href={`https://${url}`} target="_blank">
            <Text fontSize="16px" lineHeight="28px" textDecoration="underline" textColor="neutrals.900">
              {url}
            </Text>
          </Link>
          <Text fontSize="16px" lineHeight="28px" textColor="neutrals.900">
            Please update your bookmarks.
          </Text>
          <Text fontSize="16px" lineHeight="28px" textColor="neutrals.900" paddingY="28px">
            The new URL reflects our place in the Qoria family and helps make Linewize Filter even more secure.
          </Text>
          <Checkbox
            checked={dontShowAgain}
            onChange={(e) => {
              setDontShowAgain(!!e.target.checked);
            }}
            pb="28"
          >
            Don&apos;t show me this again
          </Checkbox>
          <Button onClick={() => onClose(dontShowAgain)} variant="primary" autoFocus={true}>
            Got it
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
