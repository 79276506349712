import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Link from "../../Link";
import { Flex, Icon, Text } from "@familyzone/component-library";
import NavItem from "./NavItem";
import AuthorizationWrapper from "../../../modules/AuthorizationWrapper";

const RootNavItem = ({ name, icon, dataCy, to, childItems, forceShowChildren, isActive, currentPath }) => {
  const [isChildActive, setIsChildActive] = useState(false);

  useEffect(() => {
    setIsChildActive(window.location.pathname.startsWith(to));
  }, [currentPath, to]);

  const color = isChildActive || isActive ? "neutrals.0" : "neutrals.70";

  return (
    <Flex my="sp2" flexDirection="column" _first={{ mt: "sp4" }} _last={{ mb: "sp4" }}>
      <Link to={to} data-cy={dataCy}>
        <Flex
          alignItems="center"
          p="sp8"
          mx="sp8"
          mb="sp2"
          bg={isActive || isChildActive ? "brand.500" : "transparent"}
          _hover={{ bg: "neutrals.800" }}
          borderRadius="xs"
        >
          <Icon boxSize="12px" icon={icon} color={color} mr="sp8" />

          <Text fontSize="md" color={color} mr="auto">
            {name}
          </Text>
          {childItems && (isActive || isChildActive) && <Icon color={color} icon="fa-chevron-down" />}
          {childItems && !(isActive || isChildActive) && <Icon color={color} icon="fa-chevron-right" />}
        </Flex>
      </Link>
      {(isChildActive || isActive || forceShowChildren) && childItems && (
        <Flex ml="sp12" flexDirection="column">
          {childItems
            .filter((childItems) => !childItems.hidden)
            .map((childItem) => (
              <AuthorizationWrapper allowedRoles={childItem.allowedRoles} key={childItem.to}>
                <NavItem to={childItem.to} name={childItem.name} childItems={childItem.childItems} isNew={childItem.isNew} />
              </AuthorizationWrapper>
            ))}
        </Flex>
      )}
    </Flex>
  );
};

RootNavItem.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  to: PropTypes.string,
  dataCy: PropTypes.string,
  childItems: PropTypes.arrayOf(PropTypes.object),
  forceShowChildren: PropTypes.bool,
  isActive: PropTypes.bool,
  currentPath: PropTypes.string,
};

export default RootNavItem;
