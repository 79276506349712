import React from "react";
import PropTypes from "prop-types";
import SessionStore from "../../stores/SessionStore";
import SessionActions from "../../actions/SessionActions";
import { Flex, Icon, MenuList, Text, withTranslation } from "@familyzone/component-library";
import { HeaderMenu } from "../header/components/HeaderMenu";
import { HeaderMenuButton } from "../header/components/HeaderMenuButton";
import { HeaderMenuItem } from "../header/components/HeaderMenuItem";
import { useLoginStore } from "../../storez/LoginStore";

class AccountTab extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user_status_menu_open: false,
      username: SessionStore.getUsername(),
    };

    this.unsubAccountStore = null;
  }

  componentDidMount() {
    SessionStore.listen(this.onChange);
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      username: SessionStore.getUsername(),
    });
  };

  handle_ClickOpenUserMenu = () => {
    this.setState({
      user_status_menu_open: true,
    });
  };

  handle_ClickCloseUserMenu = () => {
    this.setState({
      user_status_menu_open: false,
    });
  };

  handle_ClickLogout = () => {
    useLoginStore
      .getState()
      .getOrFetch()
      .then((qauthLoginFeature) => {
        if (!qauthLoginFeature.enabled) {
          this.context.router.push("/login");
        }
      })
      .then(() => SessionActions.logout());
  };

  handle_ClickMyAccount = () => {
    this.setState({
      user_status_menu_open: false,
    });

    this.context.router.push("/account");
  };

  render() {
    if (this.state.username !== undefined) {
      const { t } = this.props;
      return (
        <HeaderMenu>
          <HeaderMenuButton p="sp8" mr="sp16" borderRadius="round">
            <Icon variant="solid" icon="fa-user" color="neutrals.30" boxSize="24px" p="sp4" bg="neutrals.200" borderRadius="round" />
          </HeaderMenuButton>
          <MenuList>
            <HeaderMenuItem onClick={this.handle_ClickMyAccount} flexDirection="column" alignItems="start">
              <Flex flexDirection="row">
                <Text>{t("My account settings")}</Text>
              </Flex>
              <Flex flexDirection="row">
                <Text color="neutrals.500" fontSize="sm">
                  {this.state.username}
                </Text>
              </Flex>
            </HeaderMenuItem>
            <HeaderMenuItem onClick={this.handle_ClickLogout}>{t("Sign out")}</HeaderMenuItem>
          </MenuList>
        </HeaderMenu>
      );
    } else {
      return <div />;
    }
  }
}

AccountTab.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default withTranslation()(AccountTab);
