import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import Api from "../utils/Api";
import { QAuthLogin } from "../types/Login";

const AUTH_DELEGATE_BASE_URL = "/auth";
export const QAUTH_FEATURE_TOGGLE_URL = `${AUTH_DELEGATE_BASE_URL}/v1/feature-enabled`;

export interface LoginStore {
  flags?: QAuthLogin;
  getOrFetch: () => Promise<QAuthLogin>;
  fetch: () => Promise<QAuthLogin>;
}

const synchronized: { fetchResult?: Promise<QAuthLogin> } = {};

export const useLoginStore = create<LoginStore>((set, get) => ({
  flags: undefined,

  /**
   * Fetches the QAuth feature toggle from auth-delegate service
   * the feature indicates if we need to use the new QAuth login flow.
   *
   * @returns promise that completes the fetch with QAuth feature toggle.
   */
  getOrFetch: async (): Promise<QAuthLogin> => {
    // This is to have access to local development
    // and email/passwords are working as expected
    if (process.env.NODE_ENV === "development") {
      return {
        enabled: false,
      };
    }

    let flags = get().flags;
    if (flags && Object.keys(flags).length > 0) {
      return flags;
    }

    if (synchronized.fetchResult) {
      return await synchronized.fetchResult;
    }

    synchronized.fetchResult = get().fetch();
    flags = await synchronized.fetchResult;
    synchronized.fetchResult = undefined;

    return flags;
  },

  fetch: async (): Promise<QAuthLogin> => {
    let flags,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsync(QAUTH_FEATURE_TOGGLE_URL).then(
      (response: QAuthLogin) => (flags = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!flags || statusCode !== 200) {
      throw new ResponseError("Failed to fetch QAuth feature toggle", statusCode);
    }

    set({ flags });

    return flags;
  },
}));
