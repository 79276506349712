import React from "react";
import Layout from "../utils/LayoutConfig";

/**
 * @deprecated
 * No longer used in the app.
 */
export default class LoginPageHeader extends React.Component {
  render() {
    return (
      <div className="login-heading">
        <div className="login-heading-logo">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img src={Layout.login_logo()} />
          <div>Linewize Filter</div>
        </div>
      </div>
    );
  }
}
