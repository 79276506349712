import { UserUMS } from "../../../types/Users";

export const formatUsers = (users: UserUMS[]): UserUMS[] => {
  const results: UserUMS[] = [];
  users.forEach((user) => {
    results.push({
      ...user,
      // Having undefined values mixed in with the boolean messes up the sorting, so we force it to be either true or false
      archived: user.archived ? true : false,
    });
  });
  return results;
};

export const validateUsername = (username: string): string | undefined => {
  if (username.trim() === "") return "";
  if (username !== username.toLowerCase()) {
    return "Usernames containing capital letters are not supported";
  }
  if (/[\s~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?()_ ]/.test(username)) {
    return "Usernames containing special characters or spaces are not supported";
  }

  return undefined;
};
