import React, { useCallback } from "react";
import { Modal, useTranslation, InlineNotification, Box } from "@familyzone/component-library";

import { FormLabel, Select } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { ModalBody } from "@chakra-ui/modal";

interface UserSchoolRoleModalProps {
  userFirstName: string;
  userLastName: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  saveUser: (role: string) => void;
}

const UserSchoolRoleModal: React.FC<UserSchoolRoleModalProps> = ({ open = false, onClose, userFirstName, userLastName, saveUser }) => {
  const titleText = "Add School Role";
  const bodyText = "Adding a School requires a Role at the School to be specified.";
  const inputLabelText = `Select a Role in order to save Schools for ${userFirstName} ${userLastName}`;
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = React.useState("");

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value);
  };

  const handleSave = useCallback(() => {
    saveUser(selectedRole);
  }, [selectedRole, saveUser]);

  return (
    <Modal
      headerText={t(titleText)}
      size="md"
      isOpen={open}
      onClose={handleClose}
      contentProps={{ style: { overflow: "visible" } }}
      primaryCTALabel={"Save"}
      secondaryCTALabel={t("Cancel")}
      onPrimaryCTAClick={handleSave}
      primaryCTADisabled={selectedRole.trim() === "" || selectedRole === null}
      onSecondaryCTAClick={handleClose}
    >
      <ModalBody>
        <Box mb="sp24">
          <InlineNotification notificationDescription={t(bodyText)} status="info" />
        </Box>
        <FormLabel
          sx={{
            alignContent: "center",
            flexShrink: "0",
          }}
        >
          {inputLabelText}
        </FormLabel>
        <Select
          placeholder="   "
          data-testid={"role-select"}
          iconSize={"0"}
          size="lg"
          variant={"outline"}
          onChange={handleRoleChange}
          sx={{
            width: "100%",
            height: "40px",
            backgroundColor: "white",
            borderRadius: "6px",
            border: "1px solid #EBECF0",
            paddingLeft: "16px",
          }}
        >
          <option value={"TEACHER"}>Teacher</option>
          <option value={"STUDENT"}>Student</option>
        </Select>
      </ModalBody>
    </Modal>
  );
};

export default UserSchoolRoleModal;
UserSchoolRoleModal.contextTypes = {
  router: PropTypes.object.isRequired,
};
