import React, { useEffect, useState } from "react";
import { useLocation } from "react-use";
import { TokenExchange } from "./LoginHelpers";
import { useToast, useTranslation } from "@familyzone/component-library";
import { Box } from "@chakra-ui/react";
import SessionStore from "../../stores/SessionStore";
import { loginRedirectUser } from "../../utils/api/Users";

const LoginCallback: React.FC = () => {
  const [code, setCode] = useState<string>();
  const [state, setState] = useState<string>();
  const { hash } = useLocation();
  const { errorToast } = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-call */
    try {
      code && state && TokenExchange(code, state);
    } catch (e) {
      errorToast({
        title: t("Login failed"),
        description: t("Please try again"),
        position: "top",
        isClosable: true,
      });
    }
  }, [code, state, errorToast, t]);

  const sessionChanged = () => {
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    if (SessionStore.isAuthenticated()) {
      window.location.href = window.location.href.replace("/login/redirect/callback", "/");
    }

    // Navigate the customer back to QAuth login page with new parameters to login again.
    if (SessionStore.failedLogin()) {
      loginRedirectUser();
    }
  };

  useEffect(() => {
    SessionStore.listen(sessionChanged);
    return () => {
      SessionStore.unlisten(sessionChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hash) {
      let splitString: string[];
      // Cleanup the callback URL before searching for required parameters
      // The additional cleanup is needed as we use hashHistory for locally running SMUI.
      // When this is deployed in an environment we use bwoserHistory which does not have the leading "#".
      // Thus making it cleaner to parse the hash parameters passed in the calledback after successful login redirect from QAuth.
      if (process.env.NODE_ENV === "development") {
        splitString = hash.split("#/login/redirect/callback#");
      } else {
        splitString = hash.split("#");
      }
      const params = new URLSearchParams(splitString[1]);
      const code = params.get("code") as string;
      const state = params.get("state") as string;
      setCode(code);
      setState(state);
    }
  }, [hash]);

  return <Box data-testid="loginCallback"></Box>;
};

export default LoginCallback;
