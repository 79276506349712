import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  useTranslation,
  Text,
  InlineNotification,
  Checkbox,
  Button,
  useToast,
  Link,
  Spinner,
} from "@familyzone/component-library";
import { Link as ReactLink } from "react-router";
import CardBasedPage from "../templates/CardBasedPage";
import { FormControl } from "@chakra-ui/form-control";
import { useOutsideSchoolHoursReportingStore } from "../../storez/OutsideSchoolHoursReportingStore";
import { useSchoolCalendarStore } from "../../storez/SchoolCalendarStore";
import { ResponseError } from "../../types/Api";
import { OutsideSchoolHoursReporting, equal as ReportingConfigEquals } from "../../types/OutsideSchoolHoursReporting";
import { zIndices } from "../../utils/ZIndexUtil";
import { useCommunityConfigStore } from "../../storez/CommunityConfigStore";
import { DelegationSchoolReportingScope } from "../../types/Community";

export const OutsideSchoolHours = (): JSX.Element => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const breadcrumbs = [
    { title: t("Filtering"), url: "/filtering", isActive: false },
    { title: t("Outside School Hours"), url: "/filtering/outside-school-hours", isActive: true },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  const [reporting, setReporting] = useState<OutsideSchoolHoursReporting>({});
  const [storedReporting, fetchReporting, saveReporting, resetReportingStore] = useOutsideSchoolHoursReportingStore(
    useCallback((state) => [state.config, state.fetch, state.save, state.reset] as const, [])
  );

  const [communityConfig, fetchCommunityConfig] = useCommunityConfigStore(useCallback((state) => [state.config, state.fetch] as const, []));

  const [schoolTime, fetchSchoolTime] = useSchoolCalendarStore(useCallback((state) => [state.schoolTimeRule, state.fetch] as const, []));

  const handleSave = (_event: ChangeEvent<HTMLInputElement>): void => {
    setSaving(true);

    void saveReporting(reporting)
      .then(() => {
        successToast({
          title: t("Update successful"),
          description: t("Outside school hours reporting configuration has been successfully saved"),
          isClosable: true,
        });
      })
      .catch((err: ResponseError) => {
        errorToast({
          title: t("Please try again"),
          description: t(err.message),
          isClosable: true,
        });
      })
      .finally(() => setSaving(false));
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const keys = event.target.id.split(".");
    const newReporting: OutsideSchoolHoursReporting = {
      includeActivity: !!reporting?.includeActivity,
      triggerRedFlags: !!reporting?.triggerRedFlags,
    };

    if ("outsideSchoolHours" === keys[0]) {
      if ("includeActivity" === keys[1]) {
        newReporting.includeActivity = event.target.checked;
      } else if ("triggerRedFlags" === keys[1]) {
        newReporting.triggerRedFlags = event.target.checked;
      }
    }

    setReporting(newReporting);
  };

  useEffect(() => {
    Promise.all([fetchReporting(), fetchCommunityConfig(), fetchSchoolTime()]).then(
      ([reporting]) => {
        setReporting(reporting);
        setLoading(false);
      },
      (err: ResponseError) => {
        errorToast({
          title: t("Please try again"),
          description: t(err.message),
          isClosable: true,
        });
      }
    );

    return resetReportingStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchReporting, fetchCommunityConfig, fetchSchoolTime]);

  const isParentalControlsReportingEnabled = useMemo(() => {
    const scope = communityConfig?.policyDelegation?.schoolReportingScope;
    return scope && scope !== DelegationSchoolReportingScope.NONE;
  }, [communityConfig]);

  const hasChanges = !ReportingConfigEquals(reporting, storedReporting);

  return (
    <CardBasedPage title={t("Outside School Hours")} breadcrumbs={breadcrumbs}>
      <>
        {!loading && !schoolTime && (
          <InlineNotification
            status="warning"
            notificationDescription={
              <Box color="text.title">
                {t("Outside School Hours Filtering cannot be enabled until the School Calendar is setup in ")}
                <Link as={ReactLink} to="/config/device/calendar">
                  {t("Configuration | Calendar.")}
                </Link>
              </Box>
            }
          />
        )}
      </>

      <Box minHeight="200px" p="sp24" position="relative">
        <>
          {loading && (
            <Flex
              position="absolute"
              top={0}
              left={0}
              bottom={0}
              right={0}
              justifyContent="center"
              alignItems="center"
              borderRadius="md"
              backgroundColor="rgba(255, 255, 255, 0.3)"
              zIndex={zIndices.tooltip}
            >
              <Spinner size="md" />
            </Flex>
          )}
        </>

        <Flex direction="column" px="sp12" py="sp16" gap="sp16">
          <Text>
            Control if Linewize Filter will include activity that takes place outside of school hours in filtering policy compliance reports
            and alerts.
          </Text>
          <Box maxWidth="576px">
            <InlineNotification status="warning" notificationDescription={t("Changes here will affect all school reporting.")} />
          </Box>
          <FormControl isDisabled={loading || saving || !schoolTime}>
            <Checkbox
              id="outsideSchoolHours.includeActivity"
              isChecked={reporting?.includeActivity}
              isDisabled={loading || saving || !schoolTime || (reporting.includeActivity && isParentalControlsReportingEnabled)}
              onChange={handleCheckboxChange}
            >
              {t("Include activity outside school hours in reporting")}
            </Checkbox>

            {reporting.includeActivity && isParentalControlsReportingEnabled && (
              <Box ml="sp24" mt="sp8" width="fit-content">
                <InlineNotification
                  status="info"
                  notificationDescription={
                    <Box pr="sp8" color="text.title">
                      {t("To disable this option, outside school hours activity reporting option must be set to 'No usage' in")}{" "}
                      <Link as={ReactLink} to="/community/configure">
                        {t("Community | Parental Controls")}
                      </Link>
                      {"."}
                    </Box>
                  }
                />
              </Box>
            )}
          </FormControl>
          <FormControl isDisabled={loading || saving || !schoolTime}>
            <Checkbox
              id="outsideSchoolHours.triggerRedFlags"
              isChecked={reporting?.triggerRedFlags}
              isDisabled={loading || saving || !schoolTime}
              onChange={handleCheckboxChange}
            >
              {t("Trigger red flags for activity outside school hours")}
            </Checkbox>
          </FormControl>
        </Flex>
      </Box>

      <Box p="sp24" backgroundColor="brand.100" borderBottomRadius="sm">
        <Button variant="primary" disabled={loading || saving || !hasChanges} onClick={handleSave}>
          {saving ? t("Saving..") : hasChanges ? t("Save") : t("Saved")}
        </Button>
      </Box>
    </CardBasedPage>
  );
};

export default OutsideSchoolHours;
