import { ClassroomFeatureFlags, defaultClassroomFlags } from "../../../storez/ClassroomFeatureFlagStore";
import { ClassroomFeatureFlag } from "../../../utils/api/ClassroomFeatureFlags";

export function convertArrayToFeatureFlags(features: ClassroomFeatureFlag[]): ClassroomFeatureFlags {
  const flags = defaultClassroomFlags;
  flags.screenShare = features.includes(ClassroomFeatureFlag.ScreenShare);
  flags.schoolAssociations = features.includes(ClassroomFeatureFlag.SchoolAssociations);
  return flags;
}

export function convertFeatureFlagsToArray(flags: ClassroomFeatureFlags): ClassroomFeatureFlag[] {
  const features: ClassroomFeatureFlag[] = [];

  if (flags.screenShare) {
    features.push(ClassroomFeatureFlag.ScreenShare);
  }

  if (flags.schoolAssociations) {
    features.push(ClassroomFeatureFlag.SchoolAssociations);
  }

  return features;
}
