import { MultiSearchSelector, Option, Text, useTranslation } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { UserUMS } from "../../types/Users";
import { mapUserToOptionWithID, mapUserToOptionWithStableID, search } from "./UserSearchHelper";

interface Props {
  preselected?: Option[];
  onChangeUsers?: (users: Option[]) => void;
  disabled?: boolean;
  /**
   * For legacy reasons where required. If true, the UUID will be used as each option value instead of the stable ID.
   */
  useLegacyId?: boolean;
}

// This selector is used to select multiple users from a search, and it will return a array of Options.
export const MultiUserSearchSelectorReturningOptions: React.FC<Props> = ({ preselected = [], onChangeUsers, disabled, useLegacyId }) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [searchResults, setSearchResults] = useState<UserUMS[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  useEffect(() => {
    if (preselected) setSelectedOptions(preselected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserChange = (selection: Option[]) => {
    setSelectedOptions(selection);

    if (onChangeUsers) onChangeUsers([...selection]);
  };

  useDebounce(
    () => {
      setDebouncedSearch(searchTerm?.trim());
    },
    500,
    [searchTerm]
  );

  useEffect(() => {
    if (debouncedSearch) {
      void search(debouncedSearch).then((result) => setSearchResults(result));
    }
  }, [debouncedSearch]);

  const handleInput = (input: string) => {
    if (input.trim().length >= 3) setSearchTerm(input);
    else setSearchResults([]);
  };
  return (
    <>
      <MultiSearchSelector
        disabled={disabled}
        name="user-search-selector"
        placeholder={t("Search Users")}
        selected={selectedOptions}
        options={
          !!useLegacyId
            ? searchResults.map((user) => mapUserToOptionWithID(user))
            : searchResults.map((user) => mapUserToOptionWithStableID(user))
        }
        onChange={(e) => handleUserChange(e)}
        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e.target.value)}
        filterOptions={false}
      />
      <Text color="neutrals.200" ml="4" mt="6">
        Please enter 3 or more characters to search.
      </Text>
    </>
  );
};
