import { Modal, MultiSearchSelector, useTranslation, Box, Flex, Text, Input, Button } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";
import { MultiUserSearchSelector } from "../UserSearch/MultiUserSearchSelector";

interface DevicePermissionsModalProps {
  visible: boolean;
  handleHide: () => void;
  permissionOptions: string[][];
  onChange: () => void;
  selectedPermissions: string[];
  onChangeUser: () => void;
  onChangeGroup: () => void;
  permission: permissionObject;
  onSave: () => void;
  email: string;
}

export interface permissionObject {
  accountid: number;
  admin: boolean;
  deviceid: string;
  email: string;
  groups: string[];
  meta: string;
  permissions: string[];
  users: string[];
}

const DevicePermissionsModal: React.FC<DevicePermissionsModalProps> = ({
  visible = false,
  handleHide,
  permissionOptions,
  onChange,
  selectedPermissions,
  onChangeGroup,
  onChangeUser,
  permission,
  onSave,
}: DevicePermissionsModalProps) => {
  const { t } = useTranslation();
  const titleText = "Edit Permission";
  const [sortedPermissions, setSortedPermissions] = useState<string[][]>();

  const sortPermissions = () => {
    const sortedPermissions = permissionOptions.filter((permission) => selectedPermissions.includes(permission[0]));
    setSortedPermissions(sortedPermissions);
  };

  useEffect(() => {
    sortPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPermissions]);

  return (
    <Modal headerText={t(titleText)} size="lg" isOpen={visible} onClose={handleHide} contentProps={{ style: { overflow: "visible" } }}>
      <Box mb="sp24" mt="12px">
        <Flex flexDir="column">
          <Flex flexDir="row" justifyContent={"space-between"} width="100%">
            <Box width="15%">
              <Text margin="14px">Email Address</Text>
            </Box>
            <Box width="85%">
              <Input isDisabled value={permission.email} margin="0px" mb="sp2" data-testid="email-input" />
            </Box>
          </Flex>
          <Flex flexDir="row" justifyContent={"space-between"} width="100%">
            <Box width="15%">
              <Text margin="14px">Roles</Text>
            </Box>
            <Box width="85%">
              <MultiSearchSelector
                placeholder={t("Add Role")}
                options={permissionOptions.map((item) => ({ value: item[1], text: item[0] }))}
                onChange={onChange}
                selected={sortedPermissions?.map((permission: string[]) => ({ value: permission[1], text: permission[0] }))}
                data-testid="permission-selector"
              />
            </Box>
          </Flex>
          {selectedPermissions.includes("surfwize_reporting") && (
            <Box>
              <Flex flexDir="row" justifyContent={"space-between"} width="100%">
                <Box width="15%">
                  <Text margin="14px">Groups</Text>
                </Box>
                <Box width="85%">
                  <MultiGroupSearchSelector
                    onChangeGroups={onChangeGroup}
                    preselected={permission.groups.map((group) => ({ value: group, name: group }))}
                    data-testid="group-selector"
                    useLegacyId={true}
                  />
                </Box>
              </Flex>
              <Flex flexDir="row" justifyContent={"space-between"} width="100%">
                <Box width="15%">
                  <Text margin="14px">Users</Text>
                </Box>
                <Box width="85%">
                  <MultiUserSearchSelector preselected={permission.users} onChangeUsers={onChangeUser} data-testid="user-selector" />
                </Box>
              </Flex>
            </Box>
          )}
        </Flex>
      </Box>
      <Box>
        <Button variant="primary" onClick={onSave} data-testid="save-button">
          {t("Save")}
        </Button>
      </Box>
    </Modal>
  );
};

export default DevicePermissionsModal;
