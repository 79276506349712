import React, { useEffect, useState } from "react";
import DeleteButton from "../../modules/DeleteButton";

import EditButton from "../../modules/EditButton";

import FlexibleTable from "../../modules/FlexibleTable";
import Separator from "../../modules/Separator";
import TableCentricPage from "../../modules/TableCentricPage";

import Api from "../../utils/Api";
import { batchResolveGroupDescriptions } from "../../utils/api/Groups";
import TableLeftPanel from "../../utils/TableLeftPanel";
import { groupLabelFormatter } from "../GroupSearch/GroupSearchHelper";
import DevicePermissionsModal, { permissionObject } from "./ManageDevicePermissionsModal";
import { Group, UserFriendlyGroupNames } from "../../types/Groups";
import { Button, Flex, Input, Modal, useToast, useTranslation } from "@familyzone/component-library";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { isValidEmail } from "../../utils/Validation";
import { Device } from "../../types/Device";
import { useSessionStore } from "../../storez/SessionStore";

import { SignupUserBody } from "../../types/Users";
import { useLoginStore } from "../../storez/LoginStore";

class PermissionEnumerator {
  static available() {
    return [
      { id: "owner", name: "Owner/Global Administrator" },
      { id: "surfwize_admin", name: "Filtering/Reporting Administrator" },
      { id: "surfwize_filtering", name: "Filtering Administrator" },
      { id: "surfwize_reporting", name: "Reporting/Pastoral Care Administrator" },
      { id: "surfwize_settings", name: "Configuration Administrator" },
      { id: "surfwize_guest_settings", name: "Guest Configuration" },
      { id: "edgewize", name: "Wan and Firewall Administrator" },
      { id: "classroom_admin", name: "Classroom Administrator" },
      { id: "classroom_ed-tech", name: "Classroom Ed-Tech Manager" },
      { id: "surfwize_cloud_filter_admin", name: "Cloud Only Filter Administrator" },
      { id: "surfwize_cloud_dns_filter_admin", name: "Cloud DNS Filter Administrator" },
      { id: "community_admin", name: "Community Administrator" },
    ];
  }

  static get_name(id: string) {
    let mappedPermission = "";
    for (const permission of PermissionEnumerator.available()) {
      if (permission.id === id) {
        mappedPermission = permission.name;
      }
    }
    return mappedPermission;
  }
}

interface IEditPermission {
  permission: permissionObject;
  visible: boolean;
  handleClose: () => void;
  handleSave: (permission: permissionObject) => void;
}

const EditPermission: React.FC<IEditPermission> = ({ permission, visible, handleClose, handleSave }) => {
  const [userPermission, setUserPermission] = useState(permission);

  useEffect(() => {
    setUserPermission(permission);
  }, [permission]);

  const handleHide = () => {
    handleClose();
  };

  const handle_Submit = () => {
    handleSave(userPermission);
    handleClose();
  };

  const get_SelectorItems = () => {
    const ret = [];
    for (const permission of PermissionEnumerator.available()) {
      ret.push([permission.id, permission.name]);
    }
    return ret;
  };

  const handle_ChangePermission = (selected?: { text: string }[]) => {
    const permission = userPermission;
    if (permission) {
      permission.permissions = selected?.map((selected: { text: string }) => selected.text) || [];
      setUserPermission({ ...permission });
    }
  };

  const handle_ChangeGroup = (selected?: Group[]) => {
    const permission = userPermission;
    if (permission) {
      permission.groups = selected?.map((selected) => selected.name) || [];
      setUserPermission({ ...permission });
    }
  };

  const handle_ChangeUser = (selected?: string[]) => {
    const permission = userPermission;
    if (permission) {
      permission.users = selected || [];
      setUserPermission({ ...permission });
    }
  };

  return (
    <>
      {userPermission ? (
        <DevicePermissionsModal
          permission={userPermission}
          email={userPermission.email}
          selectedPermissions={userPermission.permissions}
          permissionOptions={get_SelectorItems()}
          handleHide={handleHide}
          visible={visible}
          onChange={handle_ChangePermission}
          onChangeGroup={handle_ChangeGroup}
          onChangeUser={handle_ChangeUser}
          onSave={handle_Submit}
        ></DevicePermissionsModal>
      ) : (
        <div />
      )}
    </>
  );
};

interface IAddPermission {
  visible: boolean;
  handleClose: () => void;
  handleAdd: (email: string) => Promise<void>;
}

const AddPermission: React.FC<IAddPermission> = ({ visible, handleClose, handleAdd }) => {
  const [email, setEmail] = useState("");
  const [modalVisible, setModalVisible] = useState(visible);
  const [showEmailError, setEmailError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  const handleHide = () => {
    handleClose();
  };

  const renderActions = () => {
    return (
      <Button data-testid="add-permission" variant="primary" onClick={handle_Submit} disabled={showEmailError || email.length === 0}>
        Add Permission
      </Button>
    );
  };

  const changeEmailAddress = (event: { target: { value: string } }) => {
    const _email = event.target.value;
    const validEmail = isValidEmail(_email);
    setEmailError(!validEmail);
    setEmail(_email);
  };

  const handle_Submit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    void handleAdd(email);
    handleHide();
  };

  const renderEmailError = () => (
    <FormErrorMessage fontWeight="regular" mt="sp4">
      Invalid email
    </FormErrorMessage>
  );

  return (
    <>
      {modalVisible ? (
        <Modal
          headerText={t("Add User")}
          size="sm"
          isOpen={modalVisible}
          onClose={() => {
            setModalVisible(false);
            handleHide();
          }}
        >
          <Flex mt="sp24" direction="column">
            <FormControl mb="sp24" isRequired={true} isInvalid={!!email}>
              <FormLabel fontWeight="medium">{t("Email Address")}</FormLabel>
              <Input placeholder={t("Enter email address")} value={email} onChange={changeEmailAddress} data-testid="email-input" />
              {showEmailError && renderEmailError()}
            </FormControl>
            {renderActions()}
          </Flex>
        </Modal>
      ) : (
        <div />
      )}
    </>
  );
};

const ManageDevicePermissions: React.FC = () => {
  const [permissions, setPermissions] = useState<permissionObject[]>();
  const [resolvedGroupDescriptions, setResolvedGroupDescriptions] = useState<UserFriendlyGroupNames[]>();
  const [visible__add_permission, setVisibleAddPermission] = useState(false);
  const [visible__edit_permission, setVisibleEditPermission] = useState(false);
  const [editing__permission, setEditingPermission] = useState<permissionObject>();
  const [loaded, setLoaded] = useState(false);

  const { errorToast } = useToast();
  const { t } = useTranslation();

  const [device, setDevice] = useState<Device>();

  const handle_load = () => {
    setLoaded(false);

    Api.get("/managedevice/ajax/device/permissions", (result: { data: permissionObject[] }) => {
      if (result.data) {
        const groupsToResolve: string[] = result.data.map((permission) => permission.groups).flat();
        void batchResolveGroupDescriptions(groupsToResolve).then((groups: UserFriendlyGroupNames[]) => {
          setPermissions(result.data);
          setResolvedGroupDescriptions(groups);
          setLoaded(true);
        });
      } else {
        setPermissions([{}] as permissionObject[]);
        setResolvedGroupDescriptions([]);
        setLoaded(true);
      }
    });
  };

  useEffect(() => {
    handle_load();
  }, []);

  useEffect(() => {
    setDevice(useSessionStore.getState().getDevice());
  }, [loaded]);

  const handler__clickCloseAdd = () => {
    setVisibleAddPermission(false);
  };

  const handler__clickOpenAdd = () => {
    setVisibleAddPermission(true);
  };

  const handler__clickCloseEdit = () => {
    setVisibleEditPermission(false);
  };

  const render__buttons = () => {
    return (
      <Button data-testid="add-permissions-button" variant={"primary"} onClick={handler__clickOpenAdd}>
        Add Permissions
      </Button>
    );
  };

  const handle_Delete = (row: permissionObject) => {
    Api.delete("/managedevice/ajax/device/permissions", { accountid: row.accountid }, () => handle_load());
  };

  const handle__SaveRule = (permission: permissionObject) => {
    Api.post("/managedevice/ajax/device/permissions", permission, () => handle_load());
  };

  const handle_Edit = (row: permissionObject) => {
    setEditingPermission(row);
    setVisibleEditPermission(true);
  };

  const handle__AddPermission = async (email: string): Promise<void> => {
    if (!device) return;

    // check if the email already exists in the permissions page
    // we wish to avoid overwritting the existing users permission if they already exist.
    const emailExists = permissions?.some((userPermission) => userPermission.email.toLowerCase() === email.toLowerCase());
    if (emailExists) {
      // show error toast saying user already exists
      errorToast({
        title: t("Error"),
        description: t("User email already exists."),
        isClosable: true,
      });
      return;
    }

    const data: SignupUserBody = {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
      applianceName: device.name,
      email: email,
      timezone: device.timezone,
    };

    await useLoginStore
      .getState()
      .getOrFetch()
      .then((qauthFeatureToggle) => {
        if (qauthFeatureToggle.enabled) {
          Api.put(
            `/auth/v1/appliances/${device.id}/user/signup`,
            data,
            () => {
              handle_load();
            },
            () => {
              errorToast({
                title: t("Please try again"),
                description: t("Failed to add user, please try again later."),
                isClosable: true,
              });
            }
          );
        } else {
          Api.put("/managedevice/ajax/device/permissions", data, () => {
            handle_load();
          });
        }
      });
  };

  const displayPermissions = (row: permissionObject): string[] => {
    const _permissions: string[] = [];
    if (row.permissions && row.permissions.length > 0) {
      for (const permission of row.permissions) {
        if (permission) {
          _permissions.push(PermissionEnumerator.get_name(permission));
        }
      }
    }
    return _permissions;
  };

  const sortablePermissions = (rowA: permissionObject, rowB: permissionObject) => {
    return FlexibleTable.sort_caseinsensitive_strings(displayPermissions(rowA).join(), displayPermissions(rowB).join());
  };

  const render__content = () => {
    const columns = [
      {
        title: "Account",
        data: function (row: permissionObject) {
          return row.email;
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("email"),
        onclick: undefined,
      },
      {
        title: "Permissions",
        data: function (row: permissionObject) {
          const ret = [];
          const _displayPermissions: string[] = displayPermissions(row);
          for (let i = 0; i < _displayPermissions.length; i++) {
            const separator = _displayPermissions.length >= 2 && ret.length < _displayPermissions.length - 1 ? ", " : "";
            ret.push(
              <div key={i}>
                {_displayPermissions[i]}
                {separator}
              </div>
            );
          }
          return ret;
        },
        sortable: sortablePermissions,
        onclick: undefined,
      },
      {
        title: "Users",
        data: function (row: permissionObject) {
          if (row.users.length === 0) {
            return "*";
          }

          return row.users.join(", ");
        },
        sortable: FlexibleTable.sortable__caseinsensitive_string_array_field("users"),
        onclick: undefined,
      },
      {
        title: "Groups",
        data: function (row: permissionObject) {
          const ret = [];

          if (row.groups.length === 0) {
            ret.push(<span key="*">*</span>);
          } else {
            for (let i = 0; i < row.groups.length; i++) {
              const groupName = row.groups[i];
              const separator = row.groups.length >= 2 && ret.length < row.groups.length - 1 ? ", " : "";
              const group = resolvedGroupDescriptions?.find((group) => group.name === groupName);
              const label = group ? groupLabelFormatter(group.name, group.description, group.distinguishedName) : groupName;
              ret.push(
                <div key={i}>
                  {label}
                  {separator}
                </div>
              );
            }
          }

          return ret;
        },
        sortable: FlexibleTable.sortable__caseinsensitive_string_array_field("groups"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row: permissionObject) {
          return (
            <div>
              <EditButton onClick={() => handle_Edit(row)} />
              <Separator />
              <DeleteButton onClick={() => handle_Delete(row)} />
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={permissions} loaded={loaded} className="cell-vertical-align-top" />;
  };

  return (
    <>
      <TableLeftPanel>
        {visible__edit_permission && (
          <EditPermission
            visible={visible__edit_permission}
            handleSave={handle__SaveRule}
            handleClose={handler__clickCloseEdit}
            permission={editing__permission as permissionObject}
          ></EditPermission>
        )}
        {visible__add_permission && (
          <AddPermission
            visible={visible__add_permission}
            handleAdd={handle__AddPermission}
            handleClose={handler__clickCloseAdd}
          ></AddPermission>
        )}
        <TableCentricPage title="Permissions" buttons={render__buttons()} content={render__content()} />
      </TableLeftPanel>
    </>
  );
};

export default ManageDevicePermissions;
