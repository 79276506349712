import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Flex, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { Box, Button, useTranslation, useToast, InlineNotification, RadioGroup, Radio, TextInput } from "@familyzone/component-library";
import CardBasedPage from "../../../templates/CardBasedPage";
import { SectionHeading } from "../../../shared/SectionHeading";
import { useContentAwareStore } from "../../../../storez/ContentAwareStore";
import { ContentAwareLicense, ContentAwareLicenseStatus } from "../../../../utils/api/ContentAware";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import dayjs from "dayjs";
import { calculateTrialPeriodDetails } from "../../../../utils/DateTimeUtil";

/** Defines the types of licenses */
enum LicenseType {
  Full = "full",
  Trial = "trial",
}

/** Defines license trial periods */
enum TrialDays {
  Fifteen = 15,
  Thirty = 30,
}

const FilterLicensesPage: FunctionComponent = () => {
  const fetchContentAwareLicense = useContentAwareStore((state) => state.fetchContentAwareLicense);
  const contentAwareLicense = useContentAwareStore((state) => state.getLicense);
  const deleteLicense = useContentAwareStore((state) => state.deleteLicense);
  const deactivateLicense = useContentAwareStore((state) => state.deactivateLicense);
  const createLicense = useContentAwareStore((state) => state.createLicense);
  const updateTrialLicense = useContentAwareStore((state) => state.updateTrialLicense);
  const bannerMessage = useContentAwareStore((state) => state.getBannerMessage);

  const { t } = useTranslation();
  const title = t("License Features");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("License Features"), url: "/config/licenses", isActive: false },
  ];

  const { successToast, errorToast, infoToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [isChanged, setChanged] = useState<boolean>(false);

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);
  const [confirmDeactivateModalOpen, setConfirmDeactivateModalOpen] = useState<boolean>(false);
  const [licenseType, setLicenseType] = useState<LicenseType>();
  const [trialDays, setTrialDays] = useState(TrialDays.Fifteen);
  const [numLicenses, setNumLicenses] = useState<number>();

  const setContentAwareLicenseDetails = useCallback(() => {
    setChanged(false);
    setLicenseType(undefined);
    setSaving(false);
    const license = contentAwareLicense();
    if (!license) return;

    setNumLicenses(license.numberOfLicenses);
    if (license.key && license.status === ContentAwareLicenseStatus.Active) {
      license.endDate && license.startDate ? setLicenseType(LicenseType.Trial) : setLicenseType(LicenseType.Full);
    }

    const { trialDuration } = calculateTrialPeriodDetails(license.startDate, license.endDate);
    setTrialDays(trialDuration);
  }, [contentAwareLicense]);

  const fetchFilterLicenses = useCallback(() => {
    setLoading(true);

    fetchContentAwareLicense()
      .then(() => {
        setContentAwareLicenseDetails();
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
          // No license is a valid response, don't show an error toast for this.
          if (errorMessage === "License not found for appliance") return;
        }
        errorToast({
          title: t("Couldn't fetch Content Aware license"),
          description: "Please raise a dev esco if the issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [errorToast, fetchContentAwareLicense, setContentAwareLicenseDetails, t]);

  useEffect(() => {
    fetchFilterLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDeleteLicense(): void {
    setSaving(true);
    deleteLicense()
      .then(() => {
        setContentAwareLicenseDetails();

        successToast({
          title: t("License key deleted"),
          description: t("Successfully deleted third-party license key for Content Aware functionality for this appliance."),
          position: "bottom-right",
        });
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        errorToast({
          title: t("Couldn't delete license key"),
          description: errorMessage || "Unexpected issue, please raise a dev esco if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
        setChanged(false);
      });
  }

  function handleDeactivateLicense(): void {
    setSaving(true);

    if (contentAwareLicense()?.status === ContentAwareLicenseStatus.Suspended) {
      handleNoLicenseToDeactivate();
      return;
    }

    deactivateLicense()
      .then(() => {
        setContentAwareLicenseDetails();

        successToast({
          title: t("License deactivated"),
          description: t(
            "Successfully deactivated this appliance's Content Aware license, while maintaing their third-party license key for it."
          ),
          position: "bottom-right",
        });
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        errorToast({
          title: t("Couldn't deactivate license"),
          description: errorMessage || "Unexpected issue, please raise a dev esco if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
        setChanged(false);
      });
  }

  function handleGenerateLicense(): void {
    const successToastMessage = !contentAwareLicense()?.key ? "Generate" : "Updated";
    const failToastMessage = !contentAwareLicense()?.key ? "Create" : "Update";
    setSaving(true);
    createLicense({ ...contentAwareLicense(), numberOfLicenses: numLicenses } as ContentAwareLicense)
      .then(() => {
        setContentAwareLicenseDetails();
        successToast({
          title: t(`Key Successfully ${successToastMessage}`),
          description: t("You can now configure the Content Aware settings in the Filtering tab in Linewize Filter."),
          position: "bottom-right",
        });
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        errorToast({
          title: t(`Couldn't ${failToastMessage} license key`),
          description: errorMessage || "Unexpected issue, please raise a dev esco if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
        setChanged(false);
      });
  }

  function handleGenerateTrialLicense(): void {
    const successToastMessage = !contentAwareLicense()?.key ? "Generate" : "Updated";
    const failToastMessage = !contentAwareLicense()?.key ? "Create" : "Update";
    setSaving(true);

    let startDate = dayjs().startOf("day");
    if (contentAwareLicense()?.startDate) {
      startDate = dayjs.unix(parseInt(contentAwareLicense()?.startDate as string));
    }

    updateTrialLicense({
      ...contentAwareLicense(),
      startDate: startDate.unix().toString(),
      endDate: startDate.add(trialDays, "days").unix().toString(),
      numberOfLicenses: numLicenses,
    } as ContentAwareLicense)
      .then(() => {
        setContentAwareLicenseDetails();
        if (contentAwareLicense() && (contentAwareLicense()?.startDate || contentAwareLicense()?.endDate)) {
          fetchFilterLicenses();
        }
        successToast({
          title: t(`Trial Key Successfully ${successToastMessage}`),
          description: t("You can now configure the Content Aware settings in the Filtering tab."),
          position: "bottom-right",
        });
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
          // No license is a valid response, don't show an error toast for this.
          if (errorMessage === "License not found for appliance") return;
        }
        errorToast({
          title: t(`Couldn't Not ${failToastMessage} Trial Content Aware license`),
          description: "Please raise a dev esco if the issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
        setChanged(false);
      });
  }

  function handleNoLicenseToDeactivate(): void {
    infoToast({
      title: t("No license to deactivate"),
      description: t("This appliance doesn't have a Content Aware license to deactivate."),
      position: "bottom-right",
    });
    setSaving(false);
  }

  function handleClickSaveButton(): void {
    if (licenseType === LicenseType.Full) {
      handleGenerateLicense();
    }

    if (licenseType === LicenseType.Trial) {
      handleGenerateTrialLicense();
    }
  }

  const handleSettingLicenseType = (type: string) => {
    setChanged(true);
    setLicenseType(String(type) as LicenseType);
  };

  const handleSettingNumLicenses = (e: ChangeEvent<HTMLInputElement>) => {
    if (contentAwareLicense()) {
      setChanged(true);
    }
    setNumLicenses(Number(e.target.value));
  };

  function handleSettingTrialDays(days: string): void {
    setChanged(true);
    setTrialDays(Number(days) as TrialDays);
  }

  function handleContentAwareDeactivated() {
    setConfirmDeactivateModalOpen(true);
  }

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box p="sp24">
        {contentAwareLicense()?.status === ContentAwareLicenseStatus.Active &&
          !bannerMessage()?.trialEnded &&
          contentAwareLicense()?.startDate && (
            <InlineNotification
              notificationTitle={bannerMessage()?.title}
              notificationDescription={bannerMessage()?.description || ""}
              status={bannerMessage()?.status || "warning"}
            />
          )}

        <Box my={10}></Box>

        <SectionHeading
          title={t("Activate Content Aware License")}
          tooltip={t(
            "Activating this license will allow this school to use Content Aware Filtering. Only activate this if the school is paying for this product."
          )}
        />

        <Box p={10}>
          <Flex direction="column">
            <Text mt="10" mb={20}>
              In order to use Content Aware config filter, please enter the number of licenses and select the appropriate license below.
            </Text>

            <FormControl mb="20" display="flex" alignItems="center" gap="4">
              <FormLabel fontWeight="medium">Enter the number of licenses</FormLabel>
              <TextInput type="number" width="20" value={String(numLicenses || "")} onChange={handleSettingNumLicenses} />
            </FormControl>

            <FormControl mb="4">
              <RadioGroup direction="column" value={licenseType ?? ""} onChange={handleSettingLicenseType}>
                <Radio value={LicenseType.Full}>Activate Full License</Radio>
                <Radio value={LicenseType.Trial}>Activate Trial License</Radio>
              </RadioGroup>
            </FormControl>
          </Flex>

          {bannerMessage()?.trialEnded && (
            <Box mb="4" pl="22" width="80%">
              <InlineNotification
                isRemovable={false}
                notificationTitle={bannerMessage()?.title}
                notificationDescription={bannerMessage()?.description || ""}
                status={bannerMessage()?.status || "warning"}
              />
            </Box>
          )}

          {!bannerMessage()?.trialEnded && licenseType === LicenseType.Trial && (
            <FormControl mt="10" mb="4" pl="25" display="flex" alignItems="center" gap="7">
              <FormLabel pr="15" fontWeight="medium">
                Trial duration:
              </FormLabel>
              <RadioGroup onChange={handleSettingTrialDays} value={trialDays}>
                <Radio value={TrialDays.Fifteen}>15 days</Radio>
                <Radio value={TrialDays.Thirty}>30 days</Radio>
              </RadioGroup>
            </FormControl>
          )}

          {contentAwareLicense()?.key && contentAwareLicense()?.status === ContentAwareLicenseStatus.Active && (
            <Box p={10}>
              <Text my={10}>Activation Key</Text>
              <Flex alignItems={"center"}>
                <Text borderBottom={1} borderColor={"neutrals.40"} borderStyle={"solid"} color={"neutrals.300"} height={30} px={10} mr={10}>
                  {contentAwareLicense()?.key}
                </Text>
                <Button variant="outline" ml={10} onClick={() => setConfirmDeleteModalOpen(true)} disabled={saving || loading}>
                  Remove key
                </Button>
              </Flex>
            </Box>
          )}
          <ConfirmationModal
            open={confirmDeleteModalOpen}
            onClose={() => setConfirmDeleteModalOpen(false)}
            onConfirm={() => {
              handleDeleteLicense();
              setConfirmDeleteModalOpen(false);
            }}
            heading="Warning"
            description="Removing the license key will result in a deactivation of
Content aware and its configuration settings."
            confirmationCheckbox="I understand that the license key will be removed."
            CTALabel="Remove License"
          />

          <ConfirmationModal
            open={confirmDeactivateModalOpen}
            onClose={() => setConfirmDeactivateModalOpen(false)}
            onConfirm={() => {
              handleDeactivateLicense();
              setConfirmDeactivateModalOpen(false);
            }}
            heading="Deactivate License"
            description="You are about to deactivate the Content Aware license. This will result in disabling Content Aware configuration under Filtering."
            confirmationCheckbox="I understand that Content Aware will be disabled."
            CTALabel="Disable Content Aware"
          />
        </Box>
      </Box>
      <Box bg={"#F5FCFF"} p="sp24" overflow={"hidden"} display="flex" alignItems="center" gap="20">
        {contentAwareLicense()?.key &&
          contentAwareLicense()?.status === ContentAwareLicenseStatus.Active &&
          !bannerMessage()?.trialEnded && (
            <Button
              variant="danger"
              disabled={saving || loading}
              onClick={handleContentAwareDeactivated}
              data-testid="deactivate-filter-licenses-button"
            >
              Deactivate
            </Button>
          )}

        <Button
          variant="primary"
          disabled={saving || loading || !isChanged}
          onClick={handleClickSaveButton}
          data-testid="save-filter-licenses-button"
        >
          {saving ? t("Saving") : t("Save")}
        </Button>
      </Box>
    </CardBasedPage>
  );
};

export default FilterLicensesPage;
