import React from "react";
import { MenuButton, Flex, Icon, Menu, MenuList, Text, useTranslation, MenuItem, MenuGroup, Box } from "@familyzone/component-library";
import AuthorizationWrapper from "../../modules/AuthorizationWrapper";
import { zIndices } from "../../utils/ZIndexUtil";
import DropDownDeviceVersion from "./DeviceVersion";
import DropDownDeviceAlerts from "./DeviceAlerts";
import Tools from "../Tools";
import DeviceFilteringModal from "./DeviceFilteringModal";
import RestartDevice from "./RestartDevice";

interface Props {
  openDevicePermissions: () => void;
  openDeviceOptions: () => void;
  openDiagnostics: () => void;
  openDevices: () => void;
  openDeviceUpdate: () => void;
  openAlarms: () => void;
  deviceId: string;
  deviceName: string;
}

const DeviceMenu: React.FC<Props> = ({
  openDeviceOptions,
  openDevicePermissions,
  openDiagnostics,
  openDevices,
  openDeviceUpdate,
  openAlarms,
  deviceId,
  deviceName,
}) => {
  const { t } = useTranslation();
  const title: string = deviceName && String(deviceName) !== "null" ? deviceName : "Linewize Filter";
  const [toolsOpen, setToolsOpen] = React.useState(false);
  const [restartOpen, setRestartOpen] = React.useState(false);
  const [filteringModalOpen, setFilteringModalOpen] = React.useState(false);

  return (
    <>
      <Box marginRight="10px">
        <Menu>
          <MenuButton width="5px" style={{ alignItems: "center" }} aria-label={t("Device Menu")}>
            <Icon icon="fa-ellipsis-vertical" marginLeft="-5px" />
          </MenuButton>
          <MenuList zIndex={zIndices.menu}>
            <AuthorizationWrapper allowedRoles={["owner"]}>
              <MenuGroup title="Owner Tools">
                <MenuItem onClick={() => setToolsOpen(true)}>{t("Tools")}</MenuItem>
                <MenuItem onClick={() => setFilteringModalOpen(true)}>
                  Disable Filtering Temporarily
                  <DeviceFilteringModal modalOpen={filteringModalOpen} setModalOpen={setFilteringModalOpen} />
                </MenuItem>
                <MenuItem onClick={openDevicePermissions}>{t("Permissions")}</MenuItem>
                <MenuItem onClick={openDeviceOptions}>{t("Device Options")}</MenuItem>
                <MenuItem onClick={openDiagnostics}>{t("Diagnostics")}</MenuItem>
                <MenuItem onClick={() => setRestartOpen(true)}>{t("Restart")}</MenuItem>
              </MenuGroup>
            </AuthorizationWrapper>
            <MenuGroup title="Device">
              <MenuItem onClick={openDevices}>{t("Switch Device")}</MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
      <Box marginRight="15px">
        <Text color="neutrals.900" fontWeight="bold">
          {title}
        </Text>
        <Text color="neutrals.400" fontSize="sm">
          {deviceId}
        </Text>
      </Box>
      <Flex alignItems="center">
        <DropDownDeviceVersion allowedRoles={["owner"]} onClick={openDeviceUpdate} />
        <DropDownDeviceAlerts allowedRoles={["owner"]} onClick={openAlarms} />
      </Flex>
      <RestartDevice visible={restartOpen} handleClose={() => setRestartOpen(false)} />
      <Tools visible={toolsOpen} handleClose={() => setToolsOpen(false)} />
    </>
  );
};

export default DeviceMenu;
