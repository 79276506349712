import { MultiSearchSelector, Option, Text, useTranslation } from "@familyzone/component-library";
import { isArray } from "lodash";
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { FilteringSourceCriteria } from "../../modules/criteria/criteriaTypes";
import { GroupUMS } from "../../types/Groups";
import { searchGroups } from "../../utils/api/Groups";
import { fetchDescriptionsFromStoreOptions, mapGroupsToOptionsWithID, mapGroupsToOptionsWithStableID } from "./GroupSearchHelper";
import { useGroupDescriptionStore } from "../../storez/GroupDescriptionStore";

interface Props {
  criteria?: FilteringSourceCriteria;
  onChange?: (criteria: FilteringSourceCriteria) => void;
  preselected?: Option[];
  onChangeGroups?: (groups: Option[]) => void;
  onClickGroup?: (group: Option) => void;
  disabled?: boolean;

  /**
   * For legacy reasons where required. If true, the UUID will be used as each option value instead of the stable ID.
   */
  useLegacyId?: boolean;
}

export const MultiGroupSearchSelector: FC<Props> = ({
  criteria,
  onChange: onChangeFilterSourceCriteria,
  preselected,
  onChangeGroups,
  onClickGroup,
  disabled,
  useLegacyId,
}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [searchResults, setSearchResults] = useState<GroupUMS[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const getGroups = useGroupDescriptionStore((state) => state.getGroups);

  // If the preselected groups are provided, set the selected groups.
  useEffect(() => {
    // This prevents overwriting a user's selection if preselected somehow changes after the user has already picked a group.
    if (preselected && selectedOptions.length === 0) {
      setPreselectedWithDescriptions(preselected);
    }
    // Sometimes preselected values are not set immediately upon page load, so we include the object as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselected]);

  const setPreselectedWithDescriptions = (preselected: Option[]) => {
    fetchDescriptionsFromStoreOptions(preselected, getGroups)
      .then((result) => {
        result.forEach((group) => {
          // If there's no label set, we'll show a name, since we don't want to show a uuid.
          if (!group.label && group["name"] !== undefined) group.label = String(group["name"]);
        });
        setSelectedOptions(result);
      })
      .catch((_) => setSelectedOptions(preselected));
  };

  const handleGroupChange = (selection: Option[]) => {
    setPreselectedWithDescriptions(selection);

    // Used by the policy modal to update the criteria.
    if (onChangeFilterSourceCriteria && criteria) {
      // This map here is to ensure that the value returned to the criteria selector is set to the name of the group.
      // Not to be used for calls to UMS.
      selection.map((group) => {
        if (group.name && typeof group.name === "string") group.value = group.name;
        return group;
      });
      onChangeFilterSourceCriteria({ ...criteria, conditions: [...selection.map((group) => group.value)] });
    }

    if (onChangeGroups && preselected) {
      onChangeGroups([...selection]);
    }
  };

  useMemo(() => {
    if (criteria?.conditions && isArray(criteria.conditions)) {
      const selected: Option[] = criteria.conditions.map((group) => ({
        name: group.toString(),
        value: group.toString(),
        id: group.toString(),
      }));
      setPreselectedWithDescriptions(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria]);

  useDebounce(
    () => {
      setDebouncedSearch(searchTerm?.trim());
    },
    500,
    [searchTerm]
  );

  useEffect(() => {
    if (debouncedSearch) {
      void searchGroups(debouncedSearch).then((result) => setSearchResults(result));
    }
  }, [debouncedSearch]);

  const handleInput = (input: string) => {
    if (input.trim().length >= 3) setSearchTerm(input);
    else setSearchResults([]);
  };

  return (
    <>
      <MultiSearchSelector
        disabled={disabled}
        name="group-search-selector"
        placeholder={t("Search Groups")}
        selected={selectedOptions}
        options={!!useLegacyId ? mapGroupsToOptionsWithID(searchResults) : mapGroupsToOptionsWithStableID(searchResults)}
        onChange={handleGroupChange}
        clickOption={onClickGroup}
        onInputChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e.target.value)}
        filterOptions={false}
      />
      <Text color="neutrals.200" ml="4" mt="6">
        Please enter 3 or more characters to search.
      </Text>
    </>
  );
};
