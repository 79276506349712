import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, Button, Checkbox, InlineNotification, Tag, Text, useToast, useTranslation } from "@familyzone/component-library";
import { Flex } from "@chakra-ui/layout";
import { Img } from "@chakra-ui/image";
import { Link } from "@familyzone/component-library";
import WindowsLogo from "../../../images/Windows_logo_-_2012.svg";
import MacLogo from "../../../images/Apple_logo_black.svg";
import ChromebookLogo from "../../../images/Google_Chrome_icon_February_2022.svg";
import SessionStore from "../../stores/SessionStore";
import { useAgentAuthStore } from "../../stores/AgentAuthStore";
import { useFeatureFlagStore } from "../../storez/FeatureFlagStore";
import { ResponseError } from "../../types/Api";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { useAgentConfigStore } from "../../storez/AgentConfigStore";
import { objectsEqual } from "../../utils/EqualityUtils";
import CardBasedPage from "../templates/CardBasedPage";
import { SectionHeading } from "../shared/SectionHeading";
import { AutoSetPoliciesConfig, AgentConfig } from "../../types/AgentConfig";
import { useContentAwareStore } from "../../storez/ContentAwareStore";
import { ContentAwareLicenseStatus } from "../../utils/api/ContentAware";
import ConfirmationModal from "../shared/ConfirmationModal";
import { calculateTrialPeriodDetails } from "../../utils/DateTimeUtil";

const MobileAgentDownloads: React.FC = () => {
  const { t } = useTranslation();
  const { successToast, errorToast, warningToast } = useToast();
  const contentAwareLicense = useContentAwareStore((state) => state.license);
  const fetchContentAwareLicense = useContentAwareStore((state) => state.fetchContentAwareLicense);

  const [agentAuth, fetchAgentAuth] = useAgentAuthStore(useCallback((state) => [state.agentAuth, state.getAgentAuth] as const, []));
  const [featureFlags, getOrFetchFeatureFlags] = useFeatureFlagStore(useCallback((state) => [state.flags, state.getOrFetch] as const, []));
  const [storedAgentConfig, getOrFetchAgentConfig, setAgentConfig, saveAgentConfig] = useAgentConfigStore(
    (state) => [state.config, state.getOrFetch, state.set, state.save] as const
  );
  const [autoSetPoliciesConfig, setAutoSetPoliciesConfig] = useState<AutoSetPoliciesConfig>({
    enabled: false,
    companion: { enabled: false },
    content_aware: { enabled: false },
  });

  const [optInConfirmation, setOptInConfirmation] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    void Promise.all([fetchAgentAuth(), getOrFetchFeatureFlags(), getOrFetchAgentConfig(), fetchContentAwareLicense()])
      .then(([, , AgentConfig, ,]) => {
        setAutoSetPoliciesConfig(AgentConfig.auto_set_policies || {});
      })
      .catch((err: ResponseError) => {
        errorToast({
          title: t("Please try again"),
          description: t(err?.message ?? "Failed to fetch auto update policy config"),
          isClosable: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAgentAuth, getOrFetchFeatureFlags, getOrFetchAgentConfig, fetchContentAwareLicense]);

  const copyToClipboard = async (text: string) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      errorToast({
        title: t("Failed to Copy"),
        description: t("Clipboard not supported"),
        isClosable: true,
      });
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      successToast({
        title: t("Success"),
        description: "Copied!",
        isClosable: true,
      });
    } catch (error) {
      console.warn("Copy failed", error);
    }

    await navigator.clipboard.writeText(text);
  };

  const getRegion = (region: string) => {
    if (region === "us-1") {
      return "syd-1";
    }

    return region;
  };

  /** Handles form submission */
  const handleSubmit = () => {
    if (storedAgentConfig) {
      setSaving(true);

      const autoSetOptedInBefore = storedAgentConfig.auto_set_policies?.enabled ?? false;
      const companionEnabledBefore = storedAgentConfig.auto_set_policies?.companion?.enabled ?? false;
      const contentAwareEnabledBefore = storedAgentConfig.auto_set_policies?.content_aware?.enabled ?? false;

      const agentConfig = {
        ...storedAgentConfig,
        auto_set_policies: {
          ...storedAgentConfig.auto_set_policies,
          ...autoSetPoliciesConfig,
        },
      };
      if (!objectsEqual<AgentConfig>(agentConfig, storedAgentConfig)) {
        saveAgentConfig(agentConfig)
          .then((saved) => {
            setAgentConfig(agentConfig || {});
            setAutoSetPoliciesConfig(saved.auto_set_policies || {});
          })
          .then(
            () => {
              if (!autoSetOptedInBefore && agentConfig.auto_set_policies.enabled) {
                successToast({
                  title: t("Opted in for automatic browser polices"),
                  description: t(`Selected polices will be deployed via the agents automatically.`),
                  isClosable: true,
                  position: "bottom-right",
                });
              }

              if (autoSetOptedInBefore && !agentConfig.auto_set_policies.enabled) {
                warningToast({
                  title: t("Opted out of automatic browser polices"),
                  description: t(`The agents will no longer enforce the selected polices.`),
                  isClosable: true,
                  position: "bottom-right",
                });
              }

              if (!companionEnabledBefore && agentConfig.auto_set_policies.companion?.enabled) {
                successToast({
                  title: t("Deploy Companion Mode Policy enabled"),
                  description: t(`Companion policies and the Linewize extension now deploying to devices.`),
                  isClosable: true,
                  position: "bottom-right",
                });
              }
              if (!contentAwareEnabledBefore && agentConfig.auto_set_policies.content_aware?.enabled) {
                successToast({
                  title: t("Deploy Content Aware Policy enabled"),
                  description: t(`Content Aware extension is now deploying to devices.`),
                  isClosable: true,
                  position: "bottom-right",
                });
              }
              if (companionEnabledBefore && !agentConfig.auto_set_policies.companion?.enabled) {
                warningToast({
                  title: t("Deploy Companion Mode Policy disabled"),
                  description: t(`Companion policies and the Linewize extension are now removing from devices.`),
                  isClosable: true,
                  position: "bottom-right",
                });
              }
              if (contentAwareEnabledBefore && !agentConfig.auto_set_policies.content_aware?.enabled) {
                warningToast({
                  title: t("Deploy Content Aware Policy disabled"),
                  description: t(`Content Aware extension is now removing from devices.`),
                  isClosable: true,
                  position: "bottom-right",
                });
              }
            },
            (err: ResponseError) => {
              errorToast({
                title: t("Please try again"),
                description: t(err.message),
                isClosable: true,
                position: "bottom-right",
              });
            }
          )
          .finally(() => setSaving(false));
      }

      setLoading(false);
    }
  };

  /** Updates the auto update policy config state */
  const handleAgentConfigChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.id as keyof AutoSetPoliciesConfig;
    const value = event.target.checked;

    if (autoSetPoliciesConfig) {
      if (name !== "enabled") {
        setAutoSetPoliciesConfig({
          ...autoSetPoliciesConfig,
          ...{
            [name]: {
              enabled: value,
            },
          },
        });
      } else {
        if ((autoSetPoliciesConfig?.enabled ?? false) === false && value === true) {
          setOptInConfirmation(true);
        } else {
          updateAutosetPolicyOptIn(value);
        }
      }
    }
  };

  const updateAutosetPolicyOptIn = (enabled: boolean) => {
    if (autoSetPoliciesConfig) {
      setAutoSetPoliciesConfig({
        ...autoSetPoliciesConfig,
        ...{
          enabled,
        },
      });
    }
  };

  /** Checks for new form field entries to be saved */
  const hasChanges =
    Object.keys(autoSetPoliciesConfig) && !objectsEqual<AutoSetPoliciesConfig>(autoSetPoliciesConfig, storedAgentConfig?.auto_set_policies);

  /** Checks if the temporary companion auto set policy exists */
  const isFeatureFlagAutoSetPolicyDeprecated =
    featureFlags && Object.keys(featureFlags).length > 0 && featureFlags["deprecate-feature-flag-auto-set-policies"];
  /** If Companion mode is not enabled.  */
  const companionOnNetworkFilterGroups = storedAgentConfig?.off_network?.filtering_groups.companion ?? [];
  const companionOffNetworkFilterGroups = storedAgentConfig?.off_network?.filtering_groups.companion ?? [];

  const isCompanionModeEnabled =
    storedAgentConfig?.off_network?.default_filtering_mode === "companion" ||
    companionOnNetworkFilterGroups.length > 0 ||
    storedAgentConfig?.on_network?.default_filtering_mode === "companion" ||
    companionOffNetworkFilterGroups.length > 0;

  const optedInForAutosetPolices = (autoSetPoliciesConfig?.enabled as boolean) ?? false;
  const companionPolicyEnabled = autoSetPoliciesConfig?.companion?.enabled ?? false;
  const contentAwarePolicyEnabled = autoSetPoliciesConfig?.content_aware?.enabled ?? false;

  let licenseIsNotExpired = true;
  if (contentAwareLicense?.startDate && contentAwareLicense?.endDate) {
    const { trialDaysLapsed } = calculateTrialPeriodDetails(contentAwareLicense?.startDate, contentAwareLicense?.endDate);
    if (trialDaysLapsed <= 0) {
      licenseIsNotExpired = false;
    }
  }
  const isContentAwareEnabled: boolean = contentAwareLicense?.status === ContentAwareLicenseStatus.Active && licenseIsNotExpired;

  return (
    <CardBasedPage
      title={t("Agent Downloads")}
      breadcrumbs={[
        { title: t("Configuration"), isActive: false },
        { title: t("Agent Downloads"), isActive: true },
      ]}
    >
      <Box>
        <Box p="sp24">
          <Box mb="48">
            <SectionHeading title={t("Linewize Connect Downloads")} />
            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <InlineNotification
                notificationDescription={
                  <>
                    Please check that your devices meet the{" "}
                    <Link
                      textDecoration="underline"
                      href="https://help.linewize.com/hc/en-gb/articles/10051669060508"
                      isExternal={true}
                      color="#2196F3"
                    >
                      Linewize Connect Minimum Requirements
                    </Link>
                  </>
                }
                status="info"
              />
              <Text fontWeight="500">Select the download that matches your devices. </Text>

              <Flex justifyContent="flex-start" flexDirection="row" gap="sp64" alignItems="stretch">
                <Box backgroundColor="neutrals.20" pt="20px" pb="20px" pl="30px" pr="30px" borderRadius="md">
                  <Img mx="auto" maxW="55px" maxH="55px" src={WindowsLogo} backgroundColor="white" p="15" borderRadius="md" />

                  <Button
                    mt="sp12"
                    variant="primary"
                    display="block"
                    width="100%"
                    onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.msi", "_blank")}
                  >
                    Download for Windows (.msi)
                    <br />
                    For school IT admins
                  </Button>
                </Box>
                <Box backgroundColor="neutrals.20" pt="20px" pb="20px" pl="30px" pr="30px" borderRadius="md">
                  <Img mx="auto" maxW="55px" maxH="55px" src={MacLogo} backgroundColor="white" p="15" borderRadius="md" />

                  <Button
                    mt="sp12"
                    variant="primary"
                    display="block"
                    width="100%"
                    onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.pkg", "_blank")}
                  >
                    Download for Mac (.pkg)
                    <br />
                    For school IT admins
                  </Button>
                </Box>
                <Box backgroundColor="neutrals.20" pt="20px" pb="20px" pl="30px" pr="30px" borderRadius="md">
                  <Img mx="auto" maxW="55px" maxH="55px" src={ChromebookLogo} backgroundColor="white" p="15" borderRadius="md" />

                  <Box
                    display="grid"
                    gridTemplateColumns={`repeat(${Boolean(isContentAwareEnabled) ? 2 : 1}, minmax(200px, 1fr))`}
                    gap={10}
                    alignItems="stretch"
                  >
                    <Box>
                      <Button
                        mt="sp12"
                        variant="primary"
                        display="block"
                        width="100%"
                        // eslint-disable-next-line
                        onClick={() => copyToClipboard(process.env.REACT_APP_LINEWIZE_CHROME_EXTENSION_ID as string)}
                      >
                        Copy Extension ID
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        mt="sp12"
                        variant="primary"
                        display="block"
                        width="100%"
                        // eslint-disable-next-line
                        onClick={() => copyToClipboard(`https://download.qoria.com/browser/${SessionStore.getSelectedDevice()}`)}
                      >
                        Copy Download URL
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        mt="sp12"
                        variant="primary"
                        display="block"
                        width="100%"
                        // eslint-disable-next-line
                        onClick={() =>
                          copyToClipboard(
                            JSON.stringify({
                              configuration: {
                                Value: {
                                  // eslint-disable-next-line
                                  region: getRegion(SessionStore.getSelectedRegion()),
                                  // eslint-disable-next-line
                                  applianceId: SessionStore.getSelectedDevice(),
                                  preSharedKey: agentAuth.key,
                                },
                              },
                            })
                          )
                        }
                      >
                        Copy Managed Config
                      </Button>
                    </Box>
                    {isContentAwareEnabled && (
                      <Box>
                        <Button
                          mt="sp12"
                          variant="primary"
                          display="block"
                          width="100%"
                          // eslint-disable-next-line
                          onClick={() => copyToClipboard(process.env.REACT_APP_LINEWIZE_BROWSER_CONTENT_AWARE_EXTENSION_ID as string)}
                        >
                          <Text whiteSpace="normal" fontWeight="500">
                            Copy Content Aware Extension ID{" "}
                          </Text>
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </Box>

          {isFeatureFlagAutoSetPolicyDeprecated && (
            <Box>
              <SectionHeading title={t("Automated Browser Policy")} />
              <Flex px="sp12" py="sp16" direction="column">
                <FormControl display="flex" flexDirection="row" alignItems="center">
                  <Checkbox id="enabled" isChecked={optedInForAutosetPolices} onChange={handleAgentConfigChange} />
                  <FormLabel htmlFor="enabled" pb="3" fontWeight="500">
                    Allow Connect for Windows to{" "}
                    <Link
                      textDecoration="underline"
                      href="https://help.linewize.com/hc/en-gb/articles/13490340432412"
                      isExternal={true}
                      color="#2196F3"
                    >
                      install browser extensions
                    </Link>
                  </FormLabel>
                </FormControl>
                {!optedInForAutosetPolices && (companionPolicyEnabled || contentAwarePolicyEnabled) && (
                  <Flex py="sp16" direction="column" gap="sp16">
                    <InlineNotification
                      notificationDescription={
                        <>Automated browser policies are turned off, without removing the policies from the devices.</>
                      }
                      status="error"
                    />
                  </Flex>
                )}
                <ConfirmationModal
                  open={optInConfirmation}
                  onClose={() => setOptInConfirmation(false)}
                  onConfirm={() => {
                    setOptInConfirmation(false);
                    updateAutosetPolicyOptIn(true);
                  }}
                  heading="Warning"
                  description="Enabling automated browser policies will allow the Linewize Agent to overwrite any browser policies you set on your Windows devices. If you are deploying browser policies and/or extensions via an MDM solution like Intune, it is recommended that you don't use this option."
                  confirmationCheckbox="I understand the agent will overwrite any MDM polices."
                  CTALabel="Enable automated polices"
                />
                {optedInForAutosetPolices && (
                  <Flex px="sp12" py="sp16" direction="column" gap="sp16">
                    {!isCompanionModeEnabled && (
                      <InlineNotification
                        notificationDescription={
                          <>
                            <Link
                              textDecoration="underline"
                              href="https://help.linewize.com/hc/en-gb/articles/13757895055900"
                              isExternal={true}
                              color="#2196F3"
                            >
                              Turn on Companion Mode
                            </Link>{" "}
                            for Windows to install the policies below
                          </>
                        }
                        status="info"
                      />
                    )}

                    {!isContentAwareEnabled && contentAwarePolicyEnabled && isCompanionModeEnabled && (
                      <InlineNotification
                        notificationDescription={<>Content Aware extension is deployed without an active license.</>}
                        status="error"
                      />
                    )}

                    <FormControl display="flex" flexDirection="row" alignItems="center">
                      <Checkbox
                        id="companion"
                        data-testid="companion-checkbox"
                        isChecked={isCompanionModeEnabled && companionPolicyEnabled}
                        onChange={handleAgentConfigChange}
                        isDisabled={!isCompanionModeEnabled}
                      />
                      <FormLabel htmlFor="enabled" pb="3" fontWeight="300">
                        Deploy Companion Mode Policy
                      </FormLabel>
                    </FormControl>

                    <FormControl mb="sp16" display="flex" flexDirection="row" alignItems="center">
                      <Checkbox
                        id="content_aware"
                        data-testid="content_aware-checkbox"
                        isChecked={isCompanionModeEnabled && contentAwarePolicyEnabled}
                        onChange={handleAgentConfigChange}
                        isDisabled={!(isCompanionModeEnabled && isContentAwareEnabled) && !contentAwarePolicyEnabled}
                      />
                      <FormLabel htmlFor="enabled" display="flex" gap="8">
                        <Text fontWeight="300">Deploy Content Aware Module</Text>
                        <Tag size="compact" variant="orange" tagLabel={t("Licensed Feature")} />
                      </FormLabel>
                    </FormControl>
                  </Flex>
                )}
              </Flex>
            </Box>
          )}
        </Box>
        {isFeatureFlagAutoSetPolicyDeprecated && (
          <Box p="sp24" backgroundColor="brand.100" borderBottomRadius="sm">
            <Button variant="primary" disabled={loading || saving || !hasChanges} onClick={handleSubmit}>
              {saving ? t("Saving..") : hasChanges ? t("Save") : t("Saved")}
            </Button>
          </Box>
        )}
      </Box>
    </CardBasedPage>
  );
};

export default MobileAgentDownloads;
