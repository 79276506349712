import React from "react";
import { orderBy } from "lodash";

import LayoutConfig from "../utils/LayoutConfig";

import SessionActions from "../actions/SessionActions";
import SessionStore from "../stores/SessionStore";
import PropTypes from "prop-types";
import Api from "../utils/Api";
import GlobalDatePickerVisibilityActions from "../actions/GlobalDatePickerVisibilityActions";

import Link from "./Link";

import {
  Flex,
  Box,
  SearchBox,
  Button,
  withTranslation,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  ButtonTd,
  TbodyInfiniteScroll,
} from "@familyzone/component-library";
import { zIndices } from "../utils/ZIndexUtil";

/**
 * When Devices component loads, it calls two backend APIs. A local lightweight query /sites,
 * which talks to AMS and returns an object {deviceid, name, region} for each device.
 * In parallel this component trigger heavyweight /ajax/devices (CMS) via AltJs Store that
 * fetches detailed info for all devices. When the light query returns, Devices page is rendered
 * with list of devices, and when heavy query resolves, again updated with state and operations.
 * NOTE: In the future, consider refactoring so that we DON'T need to request for detailed
 * information for all devices when page loads, but rather request info on-demand. This
 * will reduce load on servers, reduce payload transmitted and increase response time.
 */
class Devices extends React.Component {
  constructor(props, context) {
    super(props, context);

    const collator = new Intl.Collator({ sensitivity: "base" });

    // show devices with sensible display names first, then devices with sensible IDs,
    // and finally ones that start with numbers and non-alphabetic characters
    const compare = (s1, s2) => {
      if (!s1 && !s2) {
        return 0;
      } else if (!s1 && s2) {
        return 1;
      } else if (s1 && !s2) {
        return -1;
      }

      const c1 = /[a-zA-Z]/.test(s1[0]);
      const c2 = /[a-zA-Z]/.test(s2[0]);

      if (!c1 && !c2) {
        return 0;
      } else if (!c1 && c2) {
        return 1;
      } else if (c1 && !c2) {
        return -1;
      }

      return collator.compare(s1, s2);
    };

    this.defaultComparator = (d1, d2) => {
      const n1 = d1.device?.user_defined_name;
      const n2 = d2.device?.user_defined_name;
      if (!n1 && !n2) {
        return compare(d1.deviceid, d2.deviceid);
      }
      return compare(n1, n2);
    };

    this.state = {
      devices: [],
      loaded: false,
      supportAdmin: SessionStore.isSupportAdmin(),
      fallbackDevices: [],
      fallbackLoaded: false,
      searchTerm: "",
      sortDirection: "desc",
      sortColumn: "",
      shownRowCount: 30,
    };
  }

  componentDidMount() {
    if (SessionStore.isSupportAdmin() && !SessionStore.getAvailableDevicesLoaded()) {
      // The /sites data is used to speed up the page load for Support Admins as this
      // request is 1000x faster than the 'available devices' lookup due to the latter
      // returning the entire tables for Support Admins (~8MB of data at the moment)
      Api.get("/sites", this.handle_UpdateSiteInfo);
    }
    SessionStore.listen(this.onChange);
    setTimeout(() => {
      SessionActions.fetchAvailableDevices();
      GlobalDatePickerVisibilityActions.hideGlobalDatePicker();
    }, 0);
  }

  handle_UpdateSiteInfo = (data) => {
    this.setState((state) => {
      if (state.devices.length > 0 || !SessionStore.isSupportAdmin()) {
        return state;
      }

      const devices = data.result
        .map((device) => ({
          deviceid: device.deviceid,
          device: {
            region: device.region,
            user_defined_name: device.name,
          },
          device_stats: {
            last_os_version: "",
          },
        }))
        .sort(this.defaultComparator);

      return { ...state, devices, fallbackLoaded: true };
    });
  };

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState((state) => {
      // avoid updating and re-rendering the device list repeatedly since the SessionStore
      // triggers the `onChange` callback multiple times for various (unrelated) changes
      if (state.loaded || !SessionStore.getAvailableDevicesLoaded()) {
        return state;
      }

      const devices = SessionStore.getAvailableDevices()
        .map((d) => {
          // Make sure null gets sorted as empty string
          if (!d.device_stats?.last_os_version) {
            d.device_stats = d.device_stats ?? {};
            d.device_stats.last_os_version = "";
          }
          return d;
        })
        .sort(this.defaultComparator);

      return { ...state, devices, loaded: true };
    });
  };

  select_Device = (device) => {
    let starting_page = [
      { role: "owner", page: "/surfwize" },
      { role: "surfwize_admin", page: "/surfwize" },
      { role: "surfwize_reporting", page: "/surfwize" },
      { role: "surfwize_filtering", page: "/filtering" },
      { role: "edgewize", page: "/edgewize" },
      { role: "surfwize_settings", page: "/config" },
      { role: "surfwize_guest_settings", page: "/config/device/userdb/guests" },
      { role: "classroom_admin", page: "/surfwize" },
      { role: "classroom_ed-tech", page: "/surfwize" },
      { role: "community_admin", page: "/community" },
      { role: "surfwize_cloud_filter_admin", page: "/surfwize" },
    ];

    /* Are we in the right region? In prod we show devices for us-1 and au-1 */
    if (device["device"]["region"] !== LayoutConfig.region()) {
      if (device["device"]["region"] === "us-1") {
        window.location = "https://us.linewizefilter.qoria.cloud/devices/select/" + device["deviceid"];
        return;
      } else if (device["device"]["region"] === "syd-2") {
        window.location = "https://schoolmanager.au-1.familyzone.io/devices/select/" + device["deviceid"];
        return;
      }
    }

    /* To avoid the user getting confused, we need to figure out the best landing page */
    SessionActions.changeDevice(device);
    if (this.state.supportAdmin) {
      this.context.router.push("/surfwize");
      return;
    }

    for (let permission of starting_page) {
      for (let actual_permission of device["permissions"]) {
        if (permission["role"] === actual_permission) {
          this.context.router.push(permission["page"]);
          return;
        }
      }
    }
  };

  handle_ClickManageDevice = (device) => {
    SessionActions.changeDevice(device);
    this.context.router.push("/managedevice");
  };

  getData = () => {
    return this.state.devices;
  };

  isLoaded = () => {
    return this.state.loaded || this.state.fallbackLoaded;
  };

  handleSearchChange = (evt) => {
    this.setState({
      searchTerm: evt.target.value,
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
    });
  };

  handleSortChange = (sortColumn, sortDirection) => {
    this.setState({
      sortColumn,
      sortDirection,
    });
  };

  handleNewDevice = () => {
    this.context.router.push("/device/create");
  };

  showMore = () => {
    this.setState({
      shownRowCount: this.state.shownRowCount + 20,
    });
  };

  render() {
    const { t } = this.props;
    const { searchTerm, sortColumn, sortDirection } = this.state;

    return (
      <Flex bg="neutrals.0" m="sp24" p="sp24" borderRadius="sp12" flexDir="column" mx="auto" maxWidth="1200px">
        <Flex justifyContent="space-between" mb="sp16">
          <SearchBox
            value={this.state.searchTerm}
            onChange={this.handleSearchChange}
            onClear={this.handleSearchClear}
            isDisabled={!this.state.devices}
            w="530px"
          />
          <Button variant="primary" onClick={this.handleNewDevice}>
            {t("Add New Device")}
          </Button>
        </Flex>
        {/*We got `DevicesWrapper` here and pass it to InfiniteScroll because InfiniteScroll couldn't recognise the correct parent and trigger loadMore() function*/}
        <Box overflowY="auto" minWidth="900px" id="DevicesWrapper" data-testid="DevicesWrapper">
          <Table width="100%">
            <Thead position="sticky" top="0" zIndex={zIndices.thead}>
              <Tr>
                <Th
                  columnName="device.user_defined_name"
                  handleSort={this.handleSortChange}
                  sortDirection={sortColumn === "device.user_defined_name" && sortDirection}
                  headerText={t("Device Name")}
                />
                <Th
                  columnName="deviceid"
                  handleSort={this.handleSortChange}
                  sortDirection={sortColumn === "deviceid" && sortDirection}
                  headerText={t("Device ID")}
                />
                {this.state.supportAdmin && (
                  <Th
                    columnName="device_stats.last_os_version"
                    handleSort={this.handleSortChange}
                    sortDirection={sortColumn === "device_stats.last_os_version" && sortDirection}
                    headerText={t("OS Version")}
                    style={{ width: 0, minWidth: "fit-content", whiteSpace: "nowrap" }}
                  />
                )}
                <Th />
              </Tr>
            </Thead>
            <TbodyInfiniteScroll
              fetchData={this.showMore}
              hasMore={this.getData().length >= this.state.shownRowCount}
              parentElemId="DevicesWrapper"
              loaded={this.isLoaded()}
              searched={!!this.state.searchTerm}
            >
              {this.getData() &&
                orderBy(this.getData(), sortColumn, sortDirection)
                  .filter(
                    (device) =>
                      !searchTerm ||
                      device.deviceid.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      device.device?.user_defined_name?.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .filter((_r, i) => i <= this.state.shownRowCount)
                  .map((device) => (
                    <Tr key={device.deviceid}>
                      <Td verticalAlign="middle">
                        <Link onClick={() => this.select_Device(device)}>{device.device?.user_defined_name}</Link>
                      </Td>
                      <Td>
                        <Link onClick={() => this.select_Device(device)}>{device.deviceid}</Link>
                      </Td>
                      {this.state.supportAdmin && <Td>{device.device_stats?.last_os_version}</Td>}
                      <ButtonTd buttonIconName="fa-gear" buttonText={t("Settings")} onClick={() => this.handle_ClickManageDevice(device)} />
                    </Tr>
                  ))}
            </TbodyInfiniteScroll>
          </Table>
        </Box>
      </Flex>
    );
  }
}

Devices.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default withTranslation()(Devices);
