import { TFunction } from "i18next";
import { MenuFeatureToggle } from "./NavHelpers";

export interface NavRoute {
  to: string;
  name: string;
  allowedRoles: string[];
  hidden?: boolean;
  icon?: string;
  dataCy?: string;
  isNew?: boolean;
  childItems?: NavRoute[];
}

export function getNavRoutes(t: TFunction, menuFeatureToggles: MenuFeatureToggle): NavRoute[] {
  return [
    {
      icon: "fa-analytics",
      to: "/surfwize",
      name: "Statistics",
      dataCy: "statistics",
      allowedRoles: [
        "owner",
        "surfwize_admin",
        "surfwize_reporting",
        "classroom_admin",
        "classroom_ed-tech",
        "surfwize_cloud_filter_admin",
      ],
      childItems: [
        {
          to: "/surfwize/dashboard",
          name: t("Dashboard"),
          allowedRoles: [
            "owner",
            "surfwize_admin",
            "surfwize_reporting",
            "classroom_admin",
            "classroom_ed-tech",
            "surfwize_cloud_filter_admin",
          ],
          isNew: true,
        },
        {
          to: "/surfwize/reporting/types",
          name: t("Apps and Websites"),
          allowedRoles: [
            "owner",
            "surfwize_admin",
            "surfwize_reporting",
            "classroom_admin",
            "classroom_ed-tech",
            "surfwize_cloud_filter_admin",
          ],
        },
        {
          to: "/surfwize/reporting/users",
          name: t("Active Users"),
          allowedRoles: [
            "owner",
            "surfwize_admin",
            "surfwize_reporting",
            "classroom_admin",
            "classroom_ed-tech",
            "surfwize_cloud_filter_admin",
          ],
        },
        {
          to: "/surfwize/device/network",
          name: t("Network"),
          allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
          childItems: [
            {
              to: "/surfwize/device/network/hosts",
              name: t("Hosts"),
              allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/surfwize/device/network/destip",
              name: t("Destination Ip"),
              allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/surfwize/device/network/destports",
              name: t("Destination Ports"),
              allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
            },
          ],
        },
        {
          to: "/surfwize/device/status",
          name: t("Realtime"),
          allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech"],
          childItems: [
            {
              to: "/surfwize/device/status/hosts",
              name: t("Users and Hosts"),
              allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech"],
            },
            {
              to: "/surfwize/device/status/connections",
              name: t("Connections"),
              allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech"],
            },
            {
              to: "/surfwize/device/status/events",
              name: t("Alerts and Events"),
              allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech"],
            },
          ],
        },
      ],
    },
    {
      icon: "fa-file-alt",
      to: "/reports",
      name: t("Reports"),
      dataCy: "reports",
      allowedRoles: ["owner", "surfwize_admin"],
      hidden: !menuFeatureToggles["/reports"],
      childItems: [
        {
          to: "/reports/advanced",
          name: t("Advanced Search"),
          allowedRoles: ["owner", "surfwize_admin"],
        },
        {
          to: "/reports/wellbeing",
          name: t("Student Wellbeing"),
          allowedRoles: ["owner", "surfwize_admin"],
          hidden: !menuFeatureToggles["/reports/wellbeing"],
        },
        {
          to: "/reports/behaviour",
          name: t("Student Behaviour"),
          allowedRoles: ["owner", "surfwize_admin"],
          hidden: !menuFeatureToggles["/reports/behaviour"],
        },
        {
          to: "/reports/activity",
          name: t("Student Activity"),
          allowedRoles: ["owner", "surfwize_admin"],
          hidden: !menuFeatureToggles["/reports/activity"],
        },
        {
          to: "/reports/distracted",
          name: t("Digital Distraction"),
          allowedRoles: ["owner", "surfwize_admin"],
          hidden: !menuFeatureToggles["/reports/distracted"],
        },
        {
          to: "/reports/compliance",
          name: t("Activity and Compliance"),
          allowedRoles: ["owner", "surfwize_admin"],
          hidden: !menuFeatureToggles["/reports/compliance"],
        },
        {
          to: "/reports/applicationsusage",
          name: t("Applications Usage"),
          allowedRoles: ["owner", "surfwize_admin"],
          hidden: !menuFeatureToggles["/reports/applicationsusage"],
        },
      ],
    },
    {
      icon: "fa-shield-halved",
      name: t("Cyber Safety"),
      to: "/cybersafety",
      dataCy: "cyber_safety",
      allowedRoles: [
        "owner",
        "surfwize_admin",
        "surfwize_reporting",
        "classroom_admin",
        "classroom_ed-tech",
        "surfwize_cloud_filter_admin",
      ],
      childItems: [
        {
          to: "/cybersafety/wellbeing",
          name: t("Red Flags"),
          allowedRoles: [
            "owner",
            "surfwize_admin",
            "surfwize_reporting",
            "classroom_admin",
            "classroom_ed-tech",
            "surfwize_cloud_filter_admin",
          ],
          hidden: !menuFeatureToggles["/cybersafety/wellbeing"] || false,
        },
        {
          to: "/cybersafety/search",
          name: t("Searches"),
          allowedRoles: [
            "owner",
            "surfwize_admin",
            "surfwize_reporting",
            "classroom_admin",
            "classroom_ed-tech",
            "surfwize_cloud_filter_admin",
          ],
        },
        {
          to: "/cybersafety/video",
          name: t("Videos"),
          allowedRoles: [
            "owner",
            "surfwize_admin",
            "surfwize_reporting",
            "classroom_admin",
            "classroom_ed-tech",
            "surfwize_cloud_filter_admin",
          ],
        },
        {
          to: "/cybersafety/blocks",
          name: t("Blocked"),
          allowedRoles: [
            "owner",
            "surfwize_admin",
            "surfwize_reporting",
            "classroom_admin",
            "classroom_ed-tech",
            "surfwize_cloud_filter_admin",
          ],
        },
      ],
    },
    {
      icon: "fa-filter",
      name: t("Filtering"),
      dataCy: "filtering",
      to: "/filtering",
      allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "surfwize_cloud_filter_admin"],
      childItems: [
        {
          to: "/filtering/policies",
          name: t("Content Filtering"),
          allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/filtering/content-aware",
          name: t("Content Aware"),
          hidden: !menuFeatureToggles["/filtering/content-aware"],
          allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/filtering/modifications",
          name: t("Content Modification"),
          allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/filtering/outside-school-hours",
          name: t("Outside School Hours"),
          allowedRoles: ["owner", "surfwize_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/filtering/dns",
          name: t("Cloud DNS Filtering"),
          allowedRoles: ["surfwize_cloud_dns_filter_admin"],
        },
        {
          to: "/filtering/cloudsafe",
          name: t("Cloud Safe Network"),
          hidden: !menuFeatureToggles["/filtering/cloudsafe"],
          allowedRoles: ["surfwize_cloud_dns_filter_admin"],
        },
        {
          to: "/filtering/safesearch",
          name: t("Safe Search"),
          allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/filtering/alerting/reports",
          name: t("Reports and Alerts"),
          allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/filtering/bypass",
          name: t("Bypass Codes"),
          allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "classroom_admin", "surfwize_cloud_filter_admin"],
          childItems: [
            {
              to: "/filtering/bypass/available",
              name: t("Available"),
              allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/filtering/bypass/active",
              name: t("Active"),
              allowedRoles: ["owner", "surfwize_admin", "surfwize_filtering", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
          ],
        },
      ],
    },
    {
      icon: "fa-earth-americas",
      name: t("Edge Networking"),
      dataCy: "edge_networking",
      to: "/edgewize",
      allowedRoles: ["edgewize"],
      childItems: [
        {
          to: "/edgewize/status",
          name: t("Dashboard"),
          allowedRoles: ["edgewize"],
        },
        {
          to: "/edgewize/device/firewall",
          name: t("Firewall"),
          allowedRoles: ["edgewize"],
        },
        {
          to: "/edgewize/device/wan",
          name: t("Wan"),
          allowedRoles: ["edgewize"],
          childItems: [
            {
              to: "/edgewize/device/wan/general",
              name: t("Configuration"),
              allowedRoles: ["edgewize"],
            },
            {
              to: "/edgewize/device/wan/portforwarding",
              name: t("Port Forwarding"),
              allowedRoles: ["edgewize"],
            },
            {
              to: "/edgewize/device/wan/onetoone",
              name: t("1-1 Nat"),
              allowedRoles: ["edgewize"],
            },
            {
              to: "/edgewize/device/wan/manytoone",
              name: t("Many-1 Nat"),
              allowedRoles: ["edgewize"],
            },
            {
              to: "/edgewize/device/wan/exceptions",
              name: t("Nat Exceptions"),
              allowedRoles: ["edgewize"],
            },
          ],
        },
        {
          to: "/edgewize/device/mdns",
          name: t("MDNS Bridge"),
          allowedRoles: ["edgewize"],
        },
        {
          to: "/edgewize/device/vpn",
          name: t("Gateway VPN"),
          allowedRoles: ["edgewize"],
        },
        {
          to: "/edgewize/device/clientvpn",
          name: t("Client VPN"),
          allowedRoles: ["edgewize"],
        },
        {
          to: "/edgewize/device/qos",
          name: t("QOS"),
          allowedRoles: ["edgewize"],
          childItems: [
            {
              to: "/edgewize/device/qos/ratelimiter",
              name: t("Rate Limiting"),
              allowedRoles: ["edgewize"],
            },
            {
              to: "/edgewize/device/qos/priority",
              name: t("Priority"),
              allowedRoles: ["edgewize"],
            },
          ],
        },
      ],
    },
    {
      name: t("Community"),
      to: "/community",
      icon: "fa-family",
      allowedRoles: ["owner", "community_admin"],
      hidden: !menuFeatureToggles["/community"],
      dataCy: "community",
      childItems: [
        {
          to: "/community/dashboard",
          name: t("Dashboard"),
          allowedRoles: ["owner", "community_admin"],
          hidden: !menuFeatureToggles["/community/dashboard"],
        },
        {
          to: "/community/parent-management",
          name: t("Parent Management"),
          allowedRoles: ["owner", "community_admin"],
        },
        {
          to: "/community/parent-data-upload",
          name: t("Parent Data Upload"),
          allowedRoles: ["owner", "community_admin"],
        },
        {
          to: "/community/configure",
          name: t("Configure Community"),
          allowedRoles: ["owner", "community_admin"],
        },
      ],
    },
    {
      name: t("Configuration"),
      to: "/config",
      icon: "fa-cog",
      allowedRoles: [
        "owner",
        "surfwize_settings",
        "surfwize_guest_settings",
        "classroom_admin",
        "classroom_ed-tech",
        "community_admin",
        "surfwize_cloud_filter_admin",
      ],
      dataCy: "configuration",
      childItems: [
        {
          to: "/config/device/dashboard",
          name: t("Dashboard"),
          allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/config/device/calendar",
          name: t("Calendar"),
          allowedRoles: [
            "owner",
            "surfwize_settings",
            "classroom_admin",
            "classroom_ed-tech",
            "surfwize_cloud_filter_admin",
            "community_admin",
          ],
        },
        {
          to: "/config/licenses",
          name: t("Licences"),
          allowedRoles: [],
          hidden: !menuFeatureToggles["/config/licenses"],
          childItems: [
            {
              to: "/config/licenses/filter",
              name: t("Filtering"),
              allowedRoles: [],
            },
          ],
        },
        {
          to: "/config/device/network",
          name: t("Networking"),
          allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
          childItems: [
            {
              to: "/config/device/network/devices",
              name: t("Interfaces"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/network/routing",
              name: t("Routing"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/network/dns",
              name: t("DNS"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/network/httpsinspection",
              name: t("HTTPS inspection"),
              hidden: !menuFeatureToggles["/config/device/network/httpsinspection"],
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/network/highavailability",
              name: t("High Availability"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
          ],
        },
        {
          to: "/config/device/authentication",
          name: t("Authentication"),
          allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
          childItems: [
            {
              to: "/config/device/authentication/portals",
              name: t("Captive Portal"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/authentication/google",
              name: t("Google"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/authentication/azure",
              name: t("Microsoft Entra ID"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/authentication/sis-integration",
              name: t("SIS Integration"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/authentication/clever",
              name: t("Clever"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
              hidden: !menuFeatureToggles["/config/device/authentication/clever"],
            },
            {
              to: "/config/device/authentication/ldap",
              name: t("LDAP"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/authentication/servers",
              name: t("LDAP Servers"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/authentication/wmi",
              name: t("WMI"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/authentication/radius",
              name: t("RADIUS"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/authentication/syslog",
              name: t("Syslog"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/authentication/agent",
              name: t("NAC API & Auth Agent"),
              allowedRoles: [],
            },
            {
              to: "/config/device/authentication/advanced",
              name: t("Advanced Configuration"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/authentication/timeouts",
              name: t("Timeouts"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
          ],
        },
        {
          to: "/config/device/userdb",
          name: t("Users and Groups"),
          allowedRoles: ["owner", "surfwize_settings", "surfwize_guest_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
          childItems: [
            {
              to: "/config/device/userdb/unified-users",
              name: t("Unified Users"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
              hidden: !menuFeatureToggles["/config/device/userdb/unified-users"],
            },
            {
              to: "/config/device/userdb/users",
              name: t("Users"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/userdb/guests",
              name: t("Guests"),
              allowedRoles: ["owner", "surfwize_settings", "surfwize_guest_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/userdb/groups",
              name: t("Groups"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/userdb/sessions",
              name: t("Associations"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
            {
              to: "/config/device/userdb/exceptions",
              name: t("Associations Exceptions"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin"],
            },
          ],
        },
        {
          to: "/config/device/objects",
          name: t("Objects"),
          allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
          childItems: [
            {
              to: "/config/device/objects/pools",
              name: t("Pools"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/objects/securitygroups",
              name: t("Security Groups"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/objects/timeperiods",
              name: t("Time Periods"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"],
            },
          ],
        },
        {
          to: "/config/device/classwize",
          name: t("Classwize"),
          allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
          childItems: [
            {
              to: "/config/device/classwize/licencing",
              name: t("Licenced Features"),
              allowedRoles: [],
            },
            {
              to: "/config/device/classwize/classrooms",
              name: t("Classrooms"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/classwize/enabled-features",
              name: t("Feature Flags"),
              allowedRoles: [],
            },
            {
              to: "/config/device/classwize/visibility-restrictions",
              name: t("Screen Visibility Restrictions"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/classwize/courses",
              name: t("Courses"),
              allowedRoles: ["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/config/device/classwize/courses/efront",
              name: t("eFront"),
              allowedRoles: [],
            },
          ],
        },
        {
          to: "/config/device/cybersafety",
          name: t("Cyber Safety"),
          allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/config/device/walledgarden",
          name: t("Walled Garden"),
          allowedRoles: ["owner", "surfwize_admin", "classroom_admin"],
        },
        {
          to: "/config/device/mobileagentconfiguration",
          name: t("Mobile Agent"),
          allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/config/device/agent-downloads",
          name: t("Agent Downloads"),
          allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/config/device/blockpage",
          name: t("Block Page"),
          allowedRoles: ["owner", "surfwize_admin", "classroom_admin", "surfwize_cloud_filter_admin"],
        },
      ],
    },
    {
      icon: "fa-bug",
      name: t("Debugging"),
      dataCy: "debugging",
      to: "/managedevice",
      allowedRoles: ["owner", "surfwize_cloud_filter_admin", "community_admin"],
      childItems: [
        {
          to: "/managedevice/settings/device",
          name: t("Device Options"),
          allowedRoles: ["owner", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/managedevice/settings/updates",
          name: t("Device Updates"),
          allowedRoles: ["owner"],
        },
        {
          to: "/managedevice/settings/permissions",
          name: t("Permissions"),
          allowedRoles: ["owner", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/managedevice/settings/snapshots",
          name: t("Snapshots"),
          allowedRoles: ["owner", "surfwize_cloud_filter_admin"],
        },
        {
          to: "/managedevice/settings/audit",
          name: t("Config Audit"),
          allowedRoles: ["owner", "surfwize_cloud_filter_admin", "community_admin"],
        },

        {
          to: "/managedevice/settings/diagnostics",
          name: t("Diagnostics"),
          allowedRoles: ["owner", "surfwize_cloud_filter_admin"],
          childItems: [
            {
              to: "/managedevice/settings/diagnostics/alarms",
              name: t("Alarms"),
              allowedRoles: ["owner", "surfwize_cloud_filter_admin"],
            },
            {
              to: "/managedevice/settings/diagnostics/logs",
              name: t("Logfiles"),
              allowedRoles: ["owner"],
            },
            {
              to: "/managedevice/settings/diagnostics/coredumps",
              name: t("Coredumps"),
              allowedRoles: ["owner"],
            },
            {
              to: "/managedevice/settings/diagnostics/metrics",
              name: t("Metrics"),
              allowedRoles: ["owner"],
            },
            {
              to: "/managedevice/settings/diagnostics/advancedmetrics",
              name: t("Performance Metrics"),
              allowedRoles: ["owner"],
            },
            {
              to: "/managedevice/settings/diagnostics/interfacemetrics",
              name: t("Interface Metrics"),
              allowedRoles: [], // Load for Support Admin only
            },
            {
              to: "/managedevice/settings/diagnostics/advancedconfiguration",
              name: t("Advanced Configuration"),
              allowedRoles: ["owner"],
            },
            {
              to: "/managedevice/settings/diagnostics/netconsole",
              name: t("Netconsole"),
              allowedRoles: ["owner"],
            },
          ],
        },
        {
          to: "/managedevice/settings/feature-flags",
          name: t("Feature Flags"),
          allowedRoles: [],
        },
      ],
    },
  ];
}
