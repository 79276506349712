import React, { useEffect, useState } from "react";
import Link from "../Link";

import SessionActions from "../../actions/SessionActions";
import SessionStore from "../../stores/SessionStore";
import "../../../css/login.css";
import Api from "../../utils/Api";

import GoogleLogo from "../../../images/logo_google.svg";

import clsx from "clsx";

import { Box, Button, Flex, InlineNotification, Password, Text, TextInput } from "@familyzone/component-library";
import LoginWrapper from "./LoginWrapper";
import { RedirectNotification } from "./RedirectNotification";
import { useLoginStore } from "../../storez/LoginStore";

export const AcknowledgedRedirectKey = "ACKNOWLEDGED_REDIRECT";

const Login = ({ location }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginClicked, setLoginClicked] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [generalErrorText, setGeneralErrorText] = useState("");

  const emailFieldId = "username";
  const passwordFieldId = "password";

  const [showRedirectNotification, setShowRedirectNotification] = useState(localStorage.getItem(AcknowledgedRedirectKey) !== "true");

  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const passwordResetSuccessDurationMilliseconds = 10000;

  useEffect(() => {
    /* Check to see if we have been supplied the account details in URI form */
    const username = location.query.username;
    const password = location.query.password;
    if (username && password) {
      SessionStore.listen(onSessionStoreChange);
      SessionActions.authenticate({ username: username, password: password });
    }
    setTimeout(function () {
      useLoginStore
        .getState()
        .getOrFetch()
        .then((qauthLoginFeature) => {
          if (qauthLoginFeature.enabled) {
            let redirectUrl = "/login/redirect";
            if (process.env.NODE_ENV === "development") {
              redirectUrl = "/#/login/redirect";
            }
            window.location = redirectUrl;
          } else {
            SessionActions.logout();
          }
        });
    }, 0);

    return () => {
      SessionStore.unlisten(onSessionStoreChange);
    };
    // We only want this to run once (it replaces componentWillMount/componentWillUnmount) and don't care about dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.query.passwordreset) {
      setPasswordResetSuccess(true);
      setTimeout(() => {
        setPasswordResetSuccess(false);
      }, passwordResetSuccessDurationMilliseconds);
    }
  }, [location.query.passwordreset]);

  const onSessionStoreChange = () => {
    if (SessionStore.isAuthenticated()) {
      window.location = "/";
    }

    if (SessionStore.failedLogin()) {
      setLoginClicked(false);
      setEmailError(true);
      setPasswordError(true);
      setGeneralErrorText("The email or password is incorrect");
    }
  };

  const clearErrors = () => {
    setEmailError(false);
    setEmailErrorText("");
    setPasswordError(false);
    setPasswordErrorText("");
    setGeneralErrorText("");
  };

  const handleChangeUsername = (event) => {
    clearErrors();
    setUsername(event.target.value);
  };

  const handleChangePassword = (event) => {
    clearErrors();
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    if (!username) {
      setEmailError(true);
      setEmailErrorText("Email is required");
      document.getElementById(emailFieldId).focus();
    }

    if (!password) {
      setPasswordError(true);
      setPasswordErrorText("Password is required");

      if (username) {
        document.getElementById(passwordFieldId).focus();
      }
    }

    if (!username || !password) {
      return;
    }

    clearErrors();
    setLoginClicked(true);
    SessionStore.listen(onSessionStoreChange);
    SessionStore.resetLoginFailure();
    if (event) {
      event.preventDefault();
    }
    const data = { username: username, password: password };
    SessionActions.authenticate(data);
  };

  const handleEnterPressed = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleLoginWithGoogle = () => {
    Api.unauthenticated_post(
      "/login/google",
      {},
      function (data) {
        window.location = data["url"];
      },
      () => {}
    );
  };

  const footer = () => {
    if (passwordResetSuccess) {
      return (
        <InlineNotification
          notificationTitle="Password reset successful"
          notificationDescription="We have successfully reset your password. Please try signing in."
          status="success"
          borderRadius="6px"
        />
      );
    }
    return undefined;
  };

  const handleAcknowledgeRedirect = (dontShowAgain) => {
    if (dontShowAgain) {
      localStorage.setItem(AcknowledgedRedirectKey, "true");
    }
    setShowRedirectNotification(false);
  };

  const redirectUrl = () => {
    if (window.location.href.includes("uk")) {
      return "uk.linewizefilter.qoria.cloud";
    }
    if (window.location.href.includes("au")) {
      return "au.linewizefilter.qoria.cloud";
    }
    return "us.linewizefilter.qoria.cloud";
  };

  const LoginPage = () => (
    <LoginWrapper header="Linewize Filter" footer={footer()}>
      <RedirectNotification open={showRedirectNotification} onClose={handleAcknowledgeRedirect} url={redirectUrl()} />
      <Box mt="sp32" pt="sp16" onKeyPress={handleEnterPressed}>
        <Button
          onClick={handleLoginWithGoogle}
          size="default"
          variant="outline"
          w="100%"
          mb="sp32"
          leftIcon={<img alt="Google Logo" src={GoogleLogo} />}
        >
          Sign in with Google
        </Button>

        <Flex w="100%" alignItems="center" justifyContent="center" className="loginSeparator" mb="sp32">
          <Text px="sp16" color="text.paragraph.regular" fontFamily="paragraphs">
            or
          </Text>
        </Flex>
        <Box mb="sp16">
          <TextInput
            className={clsx(emailError && "login-error")}
            id={emailFieldId}
            label="Email"
            placeholder="Enter your registered email"
            type="text"
            value={username}
            onChange={handleChangeUsername}
          />
          <Text h="16px" mt="sp4" fontFamily="paragraphs" color="error.plainText" fontSize="sm">
            {emailErrorText}
          </Text>
        </Box>

        <Box>
          <Password
            className={clsx(passwordError && "login-error")}
            id={passwordFieldId}
            label="Password"
            placeholder="Enter password"
            value={password}
            onChange={handleChangePassword}
          />
          <Text h="16px" mt="sp4" fontFamily="paragraphs" color="error.plainText" fontSize="sm">
            {passwordErrorText}
          </Text>
        </Box>
        <Text h="12px" mb="sp32" fontFamily="paragraphs" color="error.plainText" fontSize="sm">
          {generalErrorText}
        </Text>

        <Box mb="sp32">
          <Link to="/login/resetpassword" fontFamily="links" fontSize="sm" fontWeight="500">
            Forgot Password?
          </Link>
        </Box>

        <Button
          isLoading={loginClicked}
          disabled={loginClicked}
          onClick={handleSubmit}
          variant="primary"
          rightIcon={<i className="fa fa-arrow-right" aria-hidden="true"></i>}
        >
          Sign in
        </Button>
      </Box>
    </LoginWrapper>
  );

  return LoginPage();
};

export default Login;
