import React from "react";
import { Link as RRLink } from "react-router";
import { Link as CLLink } from "@familyzone/component-library";

const Link = (props) => (
  <CLLink
    as={RRLink}
    fontSize="md"
    textDecoration="none"
    _hover={{ cursor: "pointer" }}
    _active={{ textDecoration: "none" }}
    _focus={{ textDecoration: "none" }}
    {...props}
  />
);

export default Link;
