import TagManager from "react-gtm-module";

export function initGoogleTagManager(region: string): void {
  const gtm_id = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

  if (!gtm_id) {
    console.error("GTM ID is not defined.");
    return;
  }

  if (region === "beta-1" || region === "sit") {
    return;
  }

  // Initialize GTM only if region is not 'beta-1' or 'sit'
  const tagManagerArgs = {
    gtmId: gtm_id,
  };

  TagManager.initialize(tagManagerArgs);
}
