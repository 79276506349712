import { Box, Button, FileUpload, Icon, Link, Text, useToast, useTranslation } from "@familyzone/component-library";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { CsvUploadState, CsvUploadStatus, ParseImportStats } from "../../types/Community";
import Api from "../../utils/Api";
import { StatusLabel } from "../label/StatusLabel";
import CardBasedPage from "../templates/CardBasedPage";

const SimpleCSVLink = () => (
  <Link href="/static/files/parent-data-upload-simple-template.csv" target="_blank" textDecoration="underline" download>
    simple CSV
  </Link>
);

const OneRosterCSVLink = () => (
  <Link href="/static/files/parent-data-upload-template.csv" target="_blank" textDecoration="underline" download>
    OneRoster CSV
  </Link>
);

const HelpLink = () => (
  <Link
    href="https://help.linewize.com/hc/en-gb/articles/8633491752220-Add-parent-and-guardian-Details-into-Community-using-CSV"
    target="_blank"
    textDecoration="underline"
  >
    here
  </Link>
);

const ParentDataUploadNew: React.FC = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const showSuccessToast = (options: { title: string; description: string }): void => {
    successToast({ ...options, isClosable: true });
  };

  const showErrorToast = (options: { title: string; description: string }): void => {
    errorToast({ ...options, isClosable: true });
  };

  const title = t("Parent Data Upload");
  const breadcrumbs = [
    { title: t("Community"), url: "/community", isActive: false },
    { title: t("Parent Data Upload"), isActive: true },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [uploadStatus, setUploadStatus] = useState<CsvUploadStatus>();
  const pendingSyncStatusFetchRequest = useRef<ReturnType<typeof setTimeout>>();

  const fetchSyncStatus = (): void => {
    Api.get(
      `/config/ajax/guardians/csv-upload-status`,
      (result: CsvUploadStatus) => {
        result.startTime = result.start || result.startTime;
        result.finishTime = result.finish || result.finishTime;

        setUploadStatus(result);
        setLoading(false);

        if (result.status === CsvUploadState.Complete || result.status === CsvUploadState.Failed) {
          pendingSyncStatusFetchRequest.current = undefined;
        } else {
          pendingSyncStatusFetchRequest.current = setTimeout(fetchSyncStatus, 5000);
        }
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    pendingSyncStatusFetchRequest.current = setTimeout(fetchSyncStatus, 0);

    return () => {
      clearTimeout(pendingSyncStatusFetchRequest.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = (file: File, uploadComplete: (success: boolean) => void): void => {
    setUploadStatus(undefined);

    const formData = new FormData();
    formData.append("data_file", file);

    Api.post_file(
      `/config/ajax/guardians/csv-upload`,
      formData,
      () => {
        uploadComplete(true);
        showSuccessToast({
          title: "CSV uploaded successfully",
          description: "Processing will take a few minutes to complete.",
        });
        pendingSyncStatusFetchRequest.current = setTimeout(fetchSyncStatus, 0);
      },
      () => {
        uploadComplete(false);
        showErrorToast({
          title: "Please try again",
          description: "An unexpected error occurred while uploading the file.",
        });
      }
    );
  };

  const initiateDownload = (file: string): void => {
    Api.post(
      `/config/ajax/guardians/csv-download-link`,
      { file: file },
      (data: { url: string }) => {
        window.open(data.url, "_blank");
      },
      () => {
        showErrorToast({
          title: "Please try again",
          description: "An unexpected error occurred while downloading the file.",
        });
      }
    );
  };

  const handleSourceDownload = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (uploadStatus?.files.inputDataUrl) {
      initiateDownload(uploadStatus?.files.inputDataUrl);
    }
  };

  const handleErrorReportDownload = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (uploadStatus?.files.failureReportUrl) {
      initiateDownload(uploadStatus?.files.failureReportUrl);
    }
  };

  const handleHideStatus = (): void => {
    setUploadStatus(undefined);
  };

  const hasErrors = (status: CsvUploadStatus): boolean => {
    const result = status.success && ParseImportStats(status.success);
    return !!result && (result.invalid_rows ?? 0) > 0;
  };

  const processing = uploadStatus?.status === CsvUploadState.Initiated || uploadStatus?.status === CsvUploadState.Running;
  const erroneous = uploadStatus?.status === CsvUploadState.Complete && hasErrors(uploadStatus);
  const success = uploadStatus?.status === CsvUploadState.Complete && !erroneous;

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box minHeight="200px" p="sp24">
        <Text color="text.title" fontSize="md">
          Add or manage parent-student relationship using CSV upload.
        </Text>

        <Text color="text.paragraph.regular" fontSize="sm" mt="sp4">
          To get started, download either a <SimpleCSVLink /> or <OneRosterCSVLink /> template and populate it with your own data. Find out
          more <HelpLink />.
        </Text>

        <Text color="text.title" fontSize="sm" mt="sp32">
          The CSV file must have student username, parent first name, parent surname and parent email address.
        </Text>

        <Box fontFamily="Roboto" my="sp12">
          <FileUpload
            primaryText="Select a CSV file or drag and drop here"
            secondaryText="Only CSV format is supported"
            accept="text/csv"
            onUpload={handleUpload}
            clearOnUpload={true}
            disabled={loading || processing}
          />
        </Box>

        <AnimatePresence>
          {processing && (
            <StatusLabel variant="info" onHide={handleHideStatus}>
              The last CSV upload on {new Date(uploadStatus.startTime).toLocaleDateString()} at{" "}
              {new Date(uploadStatus.startTime).toLocaleTimeString()} was successful and the file is being processed
            </StatusLabel>
          )}

          {success && (
            <StatusLabel variant="success" onHide={handleHideStatus}>
              The data from{" "}
              <Link href="#" textDecoration="underline" onClick={handleSourceDownload}>
                last uploaded CSV
              </Link>{" "}
              on {new Date(uploadStatus.startTime).toLocaleDateString()} at {new Date(uploadStatus.startTime).toLocaleTimeString()} has been
              processed successfully
            </StatusLabel>
          )}

          {erroneous && (
            <StatusLabel variant="error" onHide={handleHideStatus}>
              There were some errors in the{" "}
              <Link href="#" textDecoration="underline" onClick={handleSourceDownload}>
                CSV file
              </Link>{" "}
              uploaded on {new Date(uploadStatus.startTime).toLocaleDateString()} at {new Date(uploadStatus.startTime).toLocaleTimeString()}
              .
              <br />
              You can download the file with error notes and re-upload the file after fixing errors.
              <br />
              <Button variant="primary" size="sm" mt="sp12" onClick={handleErrorReportDownload}>
                <Icon icon="fa-download" variant="solid" mr="sp8" />
                {t("Download Error File")}
              </Button>
            </StatusLabel>
          )}

          {uploadStatus?.status === CsvUploadState.Failed && (
            <StatusLabel variant="error" onHide={handleHideStatus}>
              The last{" "}
              <Link href="#" textDecoration="underline" onClick={handleSourceDownload}>
                CSV file
              </Link>{" "}
              uploaded on {new Date(uploadStatus.startTime).toLocaleDateString()} at {new Date(uploadStatus.startTime).toLocaleTimeString()}{" "}
              was invalid.
              <br />
              Please ensure the file matches <SimpleCSVLink /> or <OneRosterCSVLink /> format.
            </StatusLabel>
          )}
        </AnimatePresence>
      </Box>
    </CardBasedPage>
  );
};

export default ParentDataUploadNew;
