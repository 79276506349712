import React from "react";
import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { formatBytes } from "../../utils/StringUtil";
dayjs.extend(utc);
dayjs.extend(timezone);

export default class DiagnosticsLogfiles extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      logfiles: [],
      loaded: false,
      tzString: "",
    };
  }

  handle_load = () => {
    let self = this;
    Api.get("/managedevice/ajax/device/logfiles", function (result) {
      let data = result["data"] ?? [];

      data.forEach((i) => {
        // Parse the time the file was archived (a much more useful piece of
        // information than when it was uploaded) out of the file name. This
        // should probably be an actual meta-data field but this will do for
        // now. 10 = digits in epoch time stamp between 2001 and 2286
        const m = i["file"].match(/\.archived\.(?<epoch>\d{10})\./);
        i.archived_timestamp = m ? m.groups.epoch : null;
      });

      self.setState({
        logfiles: data,
        loaded: true,
      });
    });
  };

  componentDidMount() {
    Api.get("/managedevice/ajax/device", (result) => {
      let data = result["data"];
      this.setState({ tzString: data["timezone"] });
    });
    this.handle_load();
  }

  render__buttons = () => {
    return <div />;
  };

  handle_Download = (file_entity) => {
    window.location.href = "/managedevice/ajax/device/logfile/" + file_entity["file"];
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Upload Time",
        data: function (row) {
          let tz = self.state.tzString || "UTC";
          let day = dayjs.unix(row["timestamp"]).tz(tz);
          return day.format("YYYY-MM-DD HH:mm:ss ZZ");
        },
        sortable: FlexibleTable.sortable__multi_string_field("timestamp", "file"),
        search: FlexibleTable.search__single_date_field("timestamp"),
        onclick: undefined,
      },
      {
        title: "Archive Time",
        data: function (row) {
          if (row["archived_timestamp"]) {
            const tz = self.state.tzString || "UTC";
            const ts = dayjs.unix(row["archived_timestamp"]).tz(tz);
            return ts.format("YYYY-MM-DD HH:mm:ss ZZ");
          }
          return "";
        },
        sortable: FlexibleTable.sortable__multi_string_field("archived_timestamp", "file"),
        search: FlexibleTable.search__single_date_field("archived_timestamp"),
        onclick: undefined,
      },
      {
        title: "Filename",
        data: function (row) {
          return row["file"];
        },
        sortable: FlexibleTable.sortable__single_string_field("file"),
        search: FlexibleTable.search__single_string_field("file"),
        onclick: undefined,
      },
      {
        title: "File Size",
        data: function (row) {
          const fileSize = row["file_size"];
          if (fileSize === undefined || fileSize < 0) return "";
          else return formatBytes(fileSize);
        },
        sortable: FlexibleTable.sortable__single_string_field("file_size"),
        search: FlexibleTable.search__single_string_field("file_size"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div className="link-button" onClick={() => self.handle_Download(row)}>
              Download
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.logfiles} loaded={this.state.loaded} sort_asc={false} sort_column={1} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <TableCentricPage icon="fa fa-file" title="Logfiles" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
