import React, { useState, useEffect } from "react";
import { applyErrorStyling, removeErrorStyling } from "../../../utils/DynamicStyling";
import { Link as TextLink } from "@familyzone/component-library";
import { SISProvider } from "./SisIntegration";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Text, Box } from "@chakra-ui/react";

interface Props {
  rowToEdit: SISProvider | null;
  show: boolean;
  handleHide: () => void;
  handleSubmit: (fields: PowerschoolOnHandle, rowToEdit: SISProvider | null) => void;
}

export interface PowerschoolOnHandle {
  respectEnrolmentDate: boolean;
  respectSessions: boolean;
  integrationType: string;
  name: string;
  endpointUrl: string;
  tokenUrl: string;
  clientId: string;
  clientSecret: string;
}

export const SisPowerSchoolForm: React.FC<Props> = ({ rowToEdit, show, handleHide, handleSubmit }) => {
  const [state, setState] = useState<PowerschoolOnHandle>({
    respectEnrolmentDate: false,
    respectSessions: false,
    integrationType: "Powerschool",
    name: "",
    endpointUrl: "",
    tokenUrl: "",
    clientId: "",
    clientSecret: "",
  });

  const [nameInput, setNameInput] = useState<HTMLInputElement | null>(null);
  const [endpointUrlInput, setEndpointUrlInput] = useState<HTMLInputElement | null>(null);
  const [tokenUrlInput, setTokenUrlInput] = useState<HTMLInputElement | null>(null);
  const [clientIdInput, setClientIdInput] = useState<HTMLInputElement | null>(null);
  const [clientSecretInput, setClientSecretInput] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        respectEnrolmentDate: rowToEdit?.respect_enrolment_date || false,
        respectSessions: rowToEdit?.respect_sessions || false,
        integrationType: "Powerschool",
        name: rowToEdit?.name || "",
        endpointUrl: rowToEdit?.api_configuration.endpoint_url || "",
        tokenUrl: rowToEdit?.api_configuration.token_url || "",
        clientId: rowToEdit?.api_configuration.client_id || "",
        clientSecret: rowToEdit?.api_configuration.client_secret || "",
      };
    });
  }, [rowToEdit]);

  const handleSubmitClick = () => {
    removeErrorStyling(nameInput, endpointUrlInput, tokenUrlInput, clientIdInput, clientSecretInput);

    const erroredInputs = [];
    if (!state.name) erroredInputs.push(nameInput);
    if (!state.endpointUrl) erroredInputs.push(endpointUrlInput);
    if (!state.tokenUrl) erroredInputs.push(tokenUrlInput);
    if (!state.clientId) erroredInputs.push(clientIdInput);
    if (!state.clientSecret) erroredInputs.push(clientSecretInput);

    if (erroredInputs.length > 0) {
      applyErrorStyling(...erroredInputs);
      return;
    }

    handleSubmit(state, rowToEdit);
  };

  return (
    <Modal isOpen={show} onClose={handleHide} isCentered scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent maxWidth={"800px"} maxHeight={"80%"}>
        <Box className="base-modal-close">
          <img
            alt="Close modal button"
            className="modal-close-icon"
            width="13"
            height="13"
            src="/static/images/icon_modal_close.svg"
            onClick={handleHide}
          />
        </Box>
        <ModalHeader>{!rowToEdit ? "Configure PowerSchool Sync" : "Edit PowerSchool Sync"}</ModalHeader>
        <ModalBody
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <TextLink isExternal textAlign={"center"} href={"https://help.linewize.com/hc/en-gb/articles/5733217613596"}>
            Configuring PowerSchool integration Guide
          </TextLink>

          <Text textAlign={"center"}>
            {
              "PowerSchool Integration requires a Plugin to be installed within PowerSchool. Please discuss with your Linewize Success or Account Manager to have this Plugin Provided."
            }
          </Text>

          <Text textAlign={"center"}>
            {"Provide your PowerSchool account details below to integrate your school system with Linewize Filter"}
          </Text>

          <Box
            as={"form"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginTop: "2rem",
            }}
          >
            <Box>
              <label>Name</label>
              <input
                ref={(el) => setNameInput(el)}
                className="form-input"
                placeholder="Name"
                value={state.name}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, name: e.target.value };
                  })
                }
              />
            </Box>
            <Box>
              <label>Endpoint URL</label>
              <input
                ref={(el) => setEndpointUrlInput(el)}
                className="form-input"
                placeholder="Endpoint URL"
                value={state.endpointUrl}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, endpointUrl: e.target.value };
                  })
                }
              />
            </Box>
            <Box>
              <label>OAuth2 Token URL</label>
              <input
                ref={(el) => setTokenUrlInput(el)}
                className="form-input"
                placeholder="OAuth2 Token URL"
                value={state.tokenUrl}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, tokenUrl: e.target.value };
                  })
                }
              />
            </Box>

            <Box>
              <label>Client ID</label>
              <input
                ref={(el) => setClientIdInput(el)}
                className="form-input"
                placeholder="Client ID"
                value={state.clientId}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, clientId: e.target.value };
                  })
                }
              />
            </Box>
            <Box>
              <label>Client Secret</label>
              <input
                ref={(el) => setClientSecretInput(el)}
                className="form-input"
                placeholder="Client Secret"
                value={state.clientSecret}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, clientSecret: e.target.value };
                  })
                }
              />
            </Box>
            <Box>
              <Box
                className="mui-checkbox"
                title={
                  "When this is ticked, syncs will not add students to classes if a student does not have a valid enrolment date for that class."
                }
              >
                <label>
                  <input
                    type="checkbox"
                    checked={state.respectEnrolmentDate}
                    onChange={() =>
                      setState((prevState) => {
                        return { ...prevState, respectEnrolmentDate: !state.respectEnrolmentDate };
                      })
                    }
                  />
                  Respect Enrolment Date
                </label>
              </Box>

              <Box className="mui-checkbox" title={"When this is ticked, syncs exclude classes lacking an active academic session."}>
                <label>
                  <input
                    type="checkbox"
                    checked={state.respectSessions}
                    onChange={() =>
                      setState((prevState) => {
                        return { ...prevState, respectSessions: !state.respectSessions };
                      })
                    }
                  />
                  Respect Academic Sessions
                </label>
              </Box>
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          <button className="mui-btn mui-btn--raised modal-button" onClick={handleSubmitClick}>
            Submit
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
