/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback } from "react";
import SessionStore from "../stores/SessionStore";
import SnapEngage from "./SnapEngage";
import Zendesk from "./Zendesk";
import { useDeviceOptionsStore } from "../storez/DeviceOptionsStore";
import ForethoughtAI from "./ForethoughtAI";

const zendesk = <Zendesk />;
const snapEngage = <SnapEngage />;
const forethought = <ForethoughtAI />;

const ChatWidget = (): JSX.Element => {
  // the device config is loaded in `AppRoot` to avoid multiple fetch calls as that data is required
  // by several components; once the data is available, this view will automatically re-render
  const [deviceOptions] = useDeviceOptionsStore(useCallback((state) => [state.options] as const, []));

  if (!SessionStore.isAuthenticated() || !SessionStore.getSelectedDevice()) {
    return <></>;
  }

  if (deviceOptions?.allow_support_zendesk) {
    return zendesk;
  }

  if (deviceOptions?.allow_support_school_manager) {
    return snapEngage;
  }

  if (deviceOptions?.allow_support_forethought_ai) {
    return (
      <>
        {forethought}
        {/*we still need snap engage for handoff*/}
        {snapEngage}
      </>
    );
  }

  return <></>;
};

export default ChatWidget;
