import React, { useEffect, useState } from "react";
import SearchableSelect from "../../../modules/SearchableSelect";
import SleekModal from "../../../modules/SleekModal";
import { removeErrorStyling, applyErrorStyling } from "../../../utils/DynamicStyling";
import { Link as TextLink } from "@familyzone/component-library";
import { SISProvider } from "./SisIntegration";

interface Props {
  rowToEdit: SISProvider | null;
  show: boolean;
  handleHide: () => void;
  handleSubmit: (fields: WondeFormOnHandle, rowToEdit: SISProvider | null) => void;
}

interface OnChange {
  label: string;
  value: string;
}

export interface WondeFormOnHandle {
  integration_type: string;
  name: string;
  domain: string;
  district_id: string;
  access_token: string;
  username_key: string;
  email_key: string;
  user_match: string;
  user_email_key: string;
  classname_keys: string;
}

const SisWondeForm: React.FC<Props> = ({ rowToEdit, show, handleHide, handleSubmit }) => {
  const classname_keys_subject_code = "class.subject.data.code - class.description";
  const classname_keys_class_code = "class.code - class.description";

  const [state, setState] = useState<WondeFormOnHandle>({
    integration_type: "Other",
    name: "",
    domain: "api.wonde.com",
    district_id: "",
    access_token: "",
    username_key: "mis_id",
    email_key: "work",
    user_match: "username",
    user_email_key: "primary",
    classname_keys: classname_keys_subject_code,
  });

  const [nameIdInput, setNameIdInput] = useState<HTMLInputElement | null>(null);
  const [schoolIdInput, setSchoolIdInput] = useState<HTMLInputElement | null>(null);
  const [accessTokenInput, setAccessTokenInput] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        integration_type: "Other",
        name: rowToEdit?.name || "",
        domain: rowToEdit?.api_configuration.domain || "api.wonde.com",
        district_id: rowToEdit?.api_configuration.district_id || "",
        access_token: rowToEdit?.api_configuration.access_token || "",
        username_key: rowToEdit?.api_configuration.username_key || "mis_id",
        email_key: rowToEdit?.api_configuration.email_key || "work",
        user_match: rowToEdit?.api_configuration.user_match || "username",
        user_email_key: rowToEdit?.api_configuration.user_email_key || "primary",
        classname_keys: rowToEdit?.api_configuration.classname_keys || classname_keys_subject_code,
      };
    });
  }, [rowToEdit]);

  const onSubmit = () => {
    removeErrorStyling(schoolIdInput, accessTokenInput, nameIdInput);
    const erroredInputs = [];
    if (!state.name) erroredInputs.push(nameIdInput);
    if (!state.district_id) erroredInputs.push(schoolIdInput);
    if (!state.access_token) erroredInputs.push(accessTokenInput);

    if (erroredInputs.length > 0) {
      applyErrorStyling(...erroredInputs);
      return;
    }

    handleSubmit({ ...state }, rowToEdit);
  };

  return (
    <SleekModal
      title={rowToEdit ? "Edit Wonde Sync" : "Add Wonde Sync"}
      description="Provide your Wonde account details below to integrate your school system with Linewize Filter"
      subTitle={
        <TextLink isExternal href={"https://help.linewize.com/hc/en-gb/articles/5733216183324"}>
          Configuring Wonde integration Guide
        </TextLink>
      }
      show={show}
      handleHide={handleHide}
      buttons={
        <React.Fragment>
          <button className="mui-btn mui-btn--raised modal-button" onClick={onSubmit}>
            Submit
          </button>
        </React.Fragment>
      }
      centerButtons
    >
      <form className="modal-form modal-form-overflow">
        <label>Name</label>
        <input
          ref={(el) => setNameIdInput(el)}
          className="form-input"
          placeholder="Enter a name"
          value={state.name}
          onChange={(e) =>
            setState((prev) => {
              return { ...prev, name: e.target.value };
            })
          }
        />
        <label>SIS username field</label>
        <SearchableSelect
          value={state.username_key}
          clearable={false}
          onChange={(e: OnChange) =>
            setState((prev) => {
              return { ...prev, username_key: e.value };
            })
          }
          valueDataMap={[
            ["active_directory_username", "active_directory_username"],
            ["mis_id", "mis_id"],
            ["username", "username"],
          ]}
        ></SearchableSelect>
        <label>SIS teacher email field</label>
        <SearchableSelect
          value={state.email_key}
          clearable={false}
          onChange={(e: OnChange) =>
            setState((prev) => {
              return { ...prev, email_key: e.value };
            })
          }
          valueDataMap={[
            ["work", "work"],
            ["email", "email"],
            ["primary", "primary"],
            ["home", "home"],
          ]}
        ></SearchableSelect>
        <label>Field to match students</label>
        <SearchableSelect
          value={state.user_match}
          clearable={false}
          onChange={(e: OnChange) =>
            setState((prev) => {
              return { ...prev, user_match: e.value };
            })
          }
          valueDataMap={[
            ["username", "username"],
            ["email", "email"],
          ]}
        ></SearchableSelect>
        {state.user_match === "email" ? (
          <div>
            <label>SIS student email field</label>
            <SearchableSelect
              value={state.user_email_key}
              clearable={false}
              onChange={(e: OnChange) =>
                setState((prev) => {
                  return { ...prev, user_email_key: e.value };
                })
              }
              valueDataMap={[
                ["work", "work"],
                ["email", "email"],
                ["primary", "primary"],
                ["home", "home"],
              ]}
            ></SearchableSelect>
          </div>
        ) : null}
        <label>Class name field</label>
        <SearchableSelect
          value={state.classname_keys}
          clearable={false}
          onChange={(e: OnChange) =>
            setState((prev) => {
              return { ...prev, classname_keys: e.value };
            })
          }
          valueDataMap={[
            [classname_keys_subject_code, classname_keys_subject_code],
            [classname_keys_class_code, classname_keys_class_code],
          ]}
        ></SearchableSelect>
        <label>Domain</label>
        <SearchableSelect
          value={state.domain}
          clearable={false}
          onChange={(e: OnChange) =>
            setState((prev) => {
              return { ...prev, domain: e.value };
            })
          }
          valueDataMap={[
            ["api.wonde.com", "api.wonde.com"],
            ["api-ap-southeast-2.wonde.com", "api-ap-southeast-2.wonde.com"],
          ]}
        ></SearchableSelect>
        <label>School ID</label>
        <input
          ref={(el) => setSchoolIdInput(el)}
          className="form-input"
          placeholder="School ID"
          value={state.district_id}
          onChange={(e) =>
            setState((prevState) => {
              return { ...prevState, district_id: e.target.value };
            })
          }
        />
        <label>Access Token</label>
        <input
          ref={(el) => setAccessTokenInput(el)}
          className="form-input"
          placeholder="Access Token"
          value={state.access_token}
          onChange={(e) =>
            setState((prevState) => {
              return { ...prevState, access_token: e.target.value };
            })
          }
        />
      </form>
    </SleekModal>
  );
};

export default SisWondeForm;
