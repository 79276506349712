import moment from "moment";

import Api from "../utils/Api";

import alt from "../alt";
import DeviceStatusActions from "../actions/DeviceStatusActions";
import SessionActions from "../actions/SessionActions";
import SessionStore from "../stores/SessionStore";

class DeviceStatusStore {
  constructor() {
    this.loaded = false;
    this.version = false;
    this.newest_version = false;
    this.stack_disabled_flag = undefined;
    this.recent_alerts = [];
    this.activeConfigurationVersion = 0;
    this.is_updating = false;
    this.exportPublicMethods({
      getLoaded: this.getLoaded,
      getVersion: this.getVersion,
      getUpdateAvailable: this.getUpdateAvailable,
      getNewestVersion: this.getNewestVersion,
      getNumberOfAlerts: this.getNumberOfAlerts,
      getRecentAlerts: this.getRecentAlerts,
      resetAlerts: this.resetAlerts,
      getStackStatus: this.getStackStatus,
      getActiveConfigurationVersion: this.getActiveConfigurationVersion,
    });

    this.bindListeners({
      handleFetch: DeviceStatusActions.FETCH,
      handleFetchUpdated: DeviceStatusActions.FETCH_UPDATED,

      handleDestroy: SessionActions.DESELECT_DEVICE,
      handleSelectDevice: SessionActions.CHANGE_DEVICE,

      handleDisableStack: DeviceStatusActions.DISABLE_STACK,
      handleEnableStack: DeviceStatusActions.ENABLE_STACK,
    });
  }

  getStackStatus = () => {
    return this.stack_disabled_flag;
  };

  getLoaded = () => {
    return this.loaded;
  };

  getVersion = () => {
    return this.version;
  };

  getNewestVersion = () => {
    return this.newest_version;
  };

  getUpdateAvailable = () => {
    if (!this.version || !this.newest_version) return false;

    let o = this.version.split(".").map(Number);
    let n = this.newest_version.split(".").map(Number);

    while (o.length < n.length) o.push(0);
    while (n.length < o.length) n.push(0);

    for (let i = 0; i < n.length; i++) {
      if (n[i] > o[i]) return true;
      if (n[i] < o[i]) return false;
    }
    return false;
  };

  getNumberOfAlerts = () => {
    return this.recent_alerts.length;
  };

  getRecentAlerts = () => {
    return this.recent_alerts;
  };

  resetAlerts = () => {
    let alertList = this.recent_alerts;
    if (alertList) {
      for (let alertItem of alertList) {
        if (alertItem) {
          this.clearUnreadAlert(alertItem["_id"]);
        }
      }
    }
  };

  clearUnreadAlert(id) {
    console.log("clearing: " + id);
    Api.get(
      "/ajax/device/alerts/" + id + "/ack",
      function (result) {},
      (err) => {
        console.log(`Error calling /ajax/device/alerts/${id}/ack. Error: `, err);
      }
    );
  }

  getActiveConfigurationVersion = () => {
    return this.activeConfigurationVersion;
  };

  handleDisableStack = () => {
    let self = this;
    Api.post(
      "/managedevice/ajax/device/advanced",
      {
        key: "STACK_DISABLED_FLAG",
        value: moment().unix() + 300,
      },
      function () {
        self.handleDestroy();
        DeviceStatusActions.fetch();
      }
    );
  };

  handleEnableStack = () => {
    const self = this;
    Api.post("/managedevice/ajax/device/advanced", { key: "STACK_DISABLED_FLAG", value: 0 }, function () {
      self.handleDestroy();
      DeviceStatusActions.fetch();
    });
  };

  handleDestroy = () => {
    this.loaded = false;
    this.stack_disabled_flag = 0;
    this.version = false;
    this.newest_version = false;
    this.recent_alerts = [];
    this.activeConfigurationVersion = 0;
  };

  handleSelectDevice = () => {
    this.loaded = false;
    this.version = false;
    this.newest_version = false;
    this.stack_disabled_flag = 0;
    this.recent_alerts = [];
    this.activeConfigurationVersion = 0;

    this.handleFetch();
  };

  handleFetchUpdated(result) {
    this.loaded = true;
    if (result.device_information) {
      this.version = result.device_information.os + "";
      this.stack_disabled_flag = result.device_information.stack_disabled_flag;
      let update = result.update_information.update;
      this.newest_version = update ? update.major + "." + update.minor : this.version;
      this.recent_alerts = result["alerts"];
      this.activeConfigurationVersion = result["configuration_tracker"] && result["configuration_tracker"]["device.version"];
    }
  }

  handleFetch = () => {
    let self = this;
    if (!self.is_updating) {
      self.is_updating = true;
      if (SessionStore.getSelectedDevice() !== undefined && SessionStore.getSelectedDevice() != null) {
        Api.get(
          "/info",
          function (result) {
            self.is_updating = false;
            DeviceStatusActions.fetchUpdated(result);
          },
          (err) => {
            self.is_updating = false;
            console.log("Error calling /info. Error: ", err);
          }
        );
      }
    }
  };
}

export default alt.createStore(DeviceStatusStore, "DeviceStatusStore");
