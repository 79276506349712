import React, { useEffect } from "react";
import { loginRedirectUser } from "../../utils/api/Users";
import { Box } from "@familyzone/component-library";

const LoginRedirect: React.FC = () => {
  useEffect(() => {
    loginRedirectUser();
  }, []);

  return <Box data-testid="loginRedirect"></Box>;
};

export default LoginRedirect;
