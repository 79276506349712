import React, { useState } from "react";
import Api from "../../utils/Api";
import LoginWrapper from "./LoginWrapper";

import { Box, Button, Text, TextInput } from "@familyzone/component-library";
import ReturnToSignIn from "../ReturnToSignIn";

const LoginResetPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [resetClicked, setResetClicked] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!email) {
      setErrorText("The email is required");
    } else {
      setResetClicked(true);
      Api.unauthenticated_post(
        "/reset/send-email",
        {
          email: email,
        },
        function () {
          window.location.href = "/login/resetpassword/check";
        },
        function () {
          window.location.href = "/login/resetpassword/check";
        }
      );
    }
  };

  const handleChangeEmail = (event: { preventDefault: () => void; target: { value: React.SetStateAction<string> } }) => {
    event.preventDefault();
    setEmail(event.target.value);
    setErrorText("");
  };

  return (
    <LoginWrapper header={"Forgot Password?"}>
      <Box pt="sp16">
        <Text fontFamily="paragraphs" color="text.title" mb="sp32">
          Don't worry - just enter your registered email to receive a password reset link.
        </Text>
        <Box mb="sp32">
          <TextInput
            data-testid="resetpasswordinput"
            id="username"
            label="Email"
            placeholder="Enter your registered email"
            type="text"
            value={email}
            onChange={handleChangeEmail}
            errorMessage={errorText}
          />
        </Box>
        <Button
          data-testid="resetpasswordbutton"
          mb="sp32"
          isLoading={resetClicked}
          disabled={resetClicked}
          onClick={handleSubmit}
          variant="primary"
          rightIcon={<i className="fa fa-paper-plane" aria-hidden="true" />}
        >
          {/* TODO: The above icon (and other uses of <i /> on the login pages) 
          should use the Icon component once that is working. 
          <Icon icon="fa-paper-plane" variant="solid" color="neutrals.0" />
        */}
          Send reset link
        </Button>
      </Box>
      <ReturnToSignIn />
    </LoginWrapper>
  );
};

export default LoginResetPassword;
