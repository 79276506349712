import { BoxProps } from "@chakra-ui/layout";
import { Box, CircularProgress, Container, Flex, Link, Text, useToast, useTranslation } from "@familyzone/component-library";
import React, { ReactChild, useCallback, useEffect, useMemo, useState } from "react";
import { Link as ReactLink } from "react-router";
import { useCommunityDashboardStore } from "../../storez/CommunityDashboardStore";
import { GroupSearchSelector } from "../GroupSearch/GroupSearchSelector";
import PageWithHeader from "../templates/PageWithHeader";
import { GroupOption } from "../../types/Groups";
import { ResponseError } from "../../types/Api";

interface CardProps extends BoxProps {
  title: string;
  loading: boolean;
  linkTo: string;
  linkText: string;
  chart: ReactChild;
}

const CommunityDashboardLinkStatsCard: React.FC<CardProps> = ({ title, loading, linkTo, linkText, chart, ...props }) => {
  return (
    <Box background="neutrals.0" {...props}>
      <Container centerContent>
        <Text fontFamily="heading" mt="sp24" fontSize="xl">
          {title}
        </Text>
        <Box w="288px" py="sp12">
          <Flex direction="column" justify="center" h="220px">
            <Container centerContent>{chart}</Container>
          </Flex>
          {!loading && (
            <Container centerContent>
              <Link mt="sp24" as={ReactLink} to={linkTo} href="#">
                {linkText}
              </Link>
            </Container>
          )}
        </Box>
      </Container>
    </Box>
  );
};

const CommunityTakeUpLabel: React.FC<{
  loading: boolean;
  errored: boolean;
  percent: number;
}> = ({ loading, errored, percent }) => {
  const { t } = useTranslation();

  return loading ? (
    <></>
  ) : (
    <Text fontFamily="heading" fontWeight="bold" fontSize="xxl">
      {errored ? t(`No data`) : `${percent}%`}
    </Text>
  );
};

const ClaimedStudentsLabel: React.FC<{
  loading: boolean;
  errored: boolean;
  claimed: number;
  linked: number;
}> = ({ loading, errored, claimed, linked }) => {
  const { t } = useTranslation();

  return loading ? (
    <></>
  ) : (
    <>
      <Text fontFamily="heading" fontWeight="bold" fontSize="xxl">
        {errored ? t(`No data`) : claimed}
      </Text>
      {errored || <Text fontSize="xl">{t(`out of ${linked}`)}</Text>}
    </>
  );
};

const CommunityDashboard: React.FC = () => {
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const title = t("Dashboard");
  const breadcrumbs = [
    { title: t("Community"), url: "/community", isActive: false },
    { title: t("Dashboard"), isActive: true },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedGroup, setSelectedGroup] = useState<GroupOption | null>(null);
  const [errored, setErrored] = useState<boolean>(false);

  const [dashboardData, fetchDashboardData] = useCommunityDashboardStore(useCallback((state) => [state.data, state.fetch] as const, []));

  const calculateIntegerPercent = (numerator: number, denominator: number): number => {
    if (numerator === 0) {
      return 0;
    }
    if (denominator === 0) {
      return 100;
    }
    return Math.min(Math.round((100 * numerator) / denominator), 100);
  };

  const onGroupSelection = (selected: GroupOption | null): void => {
    if (selected?.value) {
      setLoading(true);
    }
    setSelectedGroup(selected);
  };

  useEffect(() => {
    fetchDashboardData({ groupId: selectedGroup?.id as string }).then(
      () => {
        setLoading(false);
      },
      (err: ResponseError) => {
        setErrored(true);
        setLoading(false);
        errorToast({
          title: t("Please try again"),
          description: t(err.message),
          isClosable: true,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDashboardData, selectedGroup]);

  const usersUptakePercent = useMemo(() => {
    return calculateIntegerPercent(dashboardData.users.claimedCount, dashboardData.users.linkedCount);
  }, [dashboardData.users]);

  return (
    <>
      <PageWithHeader title={title} breadcrumbs={breadcrumbs}>
        <Flex direction="column">
          <Box width="288px" m="24px 0 0 24px">
            <GroupSearchSelector disabled={loading} placeholderText={t("Filter by Group")} handleChange={onGroupSelection} />
          </Box>
          <Flex p="sp24" gap="sp24">
            <CommunityDashboardLinkStatsCard
              data-testid="community-takeup-card"
              title={t("Community Take Up")}
              loading={loading}
              linkTo={"/community/parent-management"}
              linkText={t("View all parents")}
              chart={
                <CircularProgress
                  value={usersUptakePercent}
                  isIndeterminate={loading}
                  size="200px"
                  thickness="8px"
                  color="#06A59A"
                  capIsRound
                >
                  <CommunityTakeUpLabel loading={loading} errored={errored} percent={usersUptakePercent} />
                </CircularProgress>
              }
            />
            <CommunityDashboardLinkStatsCard
              data-testid="claimed-students-card"
              title={t("Connected Students")}
              loading={loading}
              linkTo={"/community/parent-management"}
              linkText={t("View parent student relationship")}
              chart={
                <CircularProgress
                  value={usersUptakePercent}
                  isIndeterminate={loading}
                  size="200px"
                  thickness="8px"
                  color="#0D9DDA"
                  capIsRound
                >
                  <ClaimedStudentsLabel
                    loading={loading}
                    errored={errored}
                    claimed={dashboardData.users.claimedCount}
                    linked={dashboardData.users.linkedCount}
                  />
                </CircularProgress>
              }
            />
          </Flex>
        </Flex>
      </PageWithHeader>
    </>
  );
};

export default CommunityDashboard;
