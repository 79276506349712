import byteSize from "byte-size";
import React, { FC, useCallback, useEffect, useState } from "react";
import LeftPanel from "../../../utils/LeftPanel";
import Api from "../../../utils/Api";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useMount } from "react-use";
import "../../../../css/RedFlags.css";
import GlobalDatePickerVisibilityActions from "../../../actions/GlobalDatePickerVisibilityActions";
import ComponentLoading from "../../../modules/ComponentLoading";
import { TopSixApps } from "../../../pages/statistics/overview/TopSixApps";
import { TopBlocked } from "../../../pages/statistics/overview/TopBlocked";
import { TransferRate } from "../../../pages/statistics/overview/TransferRate";
import { TrendingData } from "../../../pages/statistics/overview/TrendingData";
import FindUserModal from "../../modals/FindUserModal";
import ProtectedComponent from "../../ProtectedComponent";
import DashboardRedFlags from "./DashboardRedFlags";
import { Box, Button, DateTimePicker, Flex, Icon, Modal, ModalBody, Text } from "@familyzone/component-library";
import { dayjsDateToApiDate, shouldUseUSDateFormat, ApiDates, getDateTime } from "../../../utils/DateTimeUtil";
import PersonaMiniCard from "./PersonaMiniCard";
import dayjs from "dayjs";
import { zIndices } from "../../../utils/ZIndexUtil";
import MasqueradeClasswizeSignInAsTeacherModal from "../../config/MasqueradeModal";
import AuthorizationWrapper from "../../../modules/AuthorizationWrapper";
import { useFeatureFlagStore } from "../../../storez/FeatureFlagStore";
import { useContentAwareStore } from "../../../storez/ContentAwareStore";

const redFlagsQueryKey = {
  id: "red-flags",
  dates: (dateOverride: { [s: string]: unknown } | ArrayLike<unknown>) => [redFlagsQueryKey.id, Object.values(dateOverride).join("-")],
};

interface Props {
  location?: {
    query?: ApiDates;
  };
}

const Dashboard: FC<Props> = (props) => {
  const [startDate, setStartDate] = useState<string>(dayjs().startOf("day").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState<string>(dayjs().endOf("day").format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState<string>(dayjs().startOf("day").format("HH:mm:ss"));
  const [endTime, setEndTime] = useState<string>(dayjs().endOf("day").format("HH:mm:ss"));

  const [showFindUser, setShowFindUser] = useState<boolean>(false);
  const [useUSDateFormat, setUseUSDateFormat] = useState<boolean>(false);

  const [teacherGroups, setTeacherGroups] = useState<string[]>([]);

  const [featureFlags, getOrFetchFeatureFlags] = useFeatureFlagStore(useCallback((state) => [state.flags, state.getOrFetch] as const, []));

  // Content Aware trial license popup
  const fetchContentAwareLicense = useContentAwareStore((state) => state.fetchContentAwareLicense);
  const contentAwareLicenseModalMessage = useContentAwareStore((state) => state.getModalMessage);
  const contentAwareLicenseModalIsSeen = useContentAwareStore((state) => state.modalMessageIsSeen);
  const [, setContentAwarePopupToggle] = useState<boolean>(false);
  const closeContentAwareTrialPopup = () => {
    contentAwareLicenseModalIsSeen(true);
    setContentAwarePopupToggle((toggle) => !toggle);
  };

  /* eslint-disable */
  const apiDateOverride: ApiDates = { startDate: startDate, endDate: endDate, startTime: startTime, endTime: endTime };
  /* eslint-enable */

  const activeUsersKey = {
    id: "active-users",
    dates: (dateOverride: { [s: string]: unknown } | ArrayLike<unknown>) => [activeUsersKey.id, Object.values(dateOverride).join("-")],
  };

  const activeDevicesKey = {
    id: "active-devices",
    dates: (dateOverride: { [s: string]: unknown } | ArrayLike<unknown>) => [activeDevicesKey.id, Object.values(dateOverride).join("-")],
  };

  useEffect(() => {
    Api.get("/config/ajax/classwize", function (result: { [x: string]: { [x: string]: React.SetStateAction<string[]> } }) {
      if (result["classwize"]) {
        setTeacherGroups(result["classwize"]["classroom_groups"] ?? []);
      }
    });
  }, []);

  const activeUsersQuery = useQuery(
    activeUsersKey.dates(apiDateOverride),
    () => Api.get_analyticsAsync("/surfwize/device/analytics/clients/users", { dateOverride: apiDateOverride }),
    {
      select: useCallback((result: { data: unknown[] }): string => result.data.length.toLocaleString(), []),
    }
  );

  const activeDevicesQuery = useQuery(
    activeDevicesKey.dates(apiDateOverride),
    () => Api.get_analyticsAsync("/edgewize/device/analytics/clients/hosts", { dateOverride: apiDateOverride }),
    {
      select: useCallback((result: { data: unknown[] }): string => result.data.length.toLocaleString(), []),
    }
  );

  const dataTransferQuery = useQuery("data-transfer", () => Api.getAsync("/surfwize/analytics/transfer/dashboard"), {
    select: useCallback(
      (result: { today_transfer: number; week_transfer: number }) => ({
        today_transfer: byteSize(result.today_transfer).toString(),
        week_transfer: byteSize(result.week_transfer).toString(),
      }),
      []
    ),
  });

  const redFlagsQuery = useQuery(
    redFlagsQueryKey.dates(apiDateOverride),
    () => Api.get_analyticsAsync("/surfwize/device/ajax/analytic/riskselector", { dateOverride: apiDateOverride }),
    {
      select: useCallback((result: { data: unknown[] }) => result.data, []),
    }
  );

  useEffect(() => {
    const checkDateFormat = async () => {
      setUseUSDateFormat(await shouldUseUSDateFormat());
    };
    void checkDateFormat();
  }, []);

  useEffect(() => {
    async function fetchData() {
      await fetchContentAwareLicense();
    }
    void fetchData();
  }, [fetchContentAwareLicense]);

  useEffect(() => {
    void getOrFetchFeatureFlags();
  }, [getOrFetchFeatureFlags]);

  // The following eslint-disable is re-enabled together with the next disable after onDateChanged.
  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  useMount(() => {
    setTimeout(() => {
      GlobalDatePickerVisibilityActions.hideGlobalDatePicker();

      if (props.location?.query && props.location.query.startDate && props.location.query.endDate) {
        setStartDate(props.location.query.startDate);
        setEndDate(props.location.query.endDate);
        setStartTime(props.location.query.startTime ? props.location.query.startTime : "00:00:00");
        setEndTime(props.location.query.endTime ? props.location.query.endTime : "23:59:59");
      }
    }, 0);
  });

  const handleFindUserBtnClick = () => {
    setShowFindUser(true);
  };

  const handleHideFindUser = () => {
    setShowFindUser(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  return featureFlags ? (
    <LeftPanel>
      <div className="personaDashboardPage">
        <div className="personaDashboardRow">
          <div className="personaDashboardHeader-h4">Dashboard</div>
        </div>
        <Box className="personaDashboardRow" overflow="visible">
          <Flex justifyContent="space-between">
            <Flex zIndex={zIndices.datepicker}>
              <DateTimePicker
                initialStart={getDateTime(startDate, startTime)}
                initialEnd={getDateTime(endDate, endTime)}
                useUSDateFormat={useUSDateFormat}
                onDateChange={(start, end) => {
                  const apiDates = dayjsDateToApiDate(dayjs(start), dayjs(end));
                  setStartDate(apiDates.startDate);
                  setEndDate(apiDates.endDate);
                  setStartTime(apiDates.startTime);
                  setEndTime(apiDates.endTime);
                }}
                direction="right"
                maxSpan={{ value: 12, unit: "months" }}
                calendarZIndex={zIndices.datepicker}
              />
            </Flex>
            <div>
              <Button variant="primary" onClick={handleFindUserBtnClick} leftIcon={<Icon icon="fa-magnifying-glass" variant="solid" />}>
                Find user
              </Button>
              {!featureFlags["hide-dashboard-teacher-signin"] ? (
                <AuthorizationWrapper allowedRoles={["surfwize_reporting", "owner"]}>
                  <>
                    <span style={{ marginLeft: "10px" }} />
                    <MasqueradeClasswizeSignInAsTeacherModal teacherGroups={teacherGroups} />
                  </>
                </AuthorizationWrapper>
              ) : (
                <></>
              )}
            </div>
          </Flex>
        </Box>
        <Box>
          <div className="personaCardRow row">
            {featureFlags["hide-red-flags"] ? (
              <div className="personaCardCol col-md-12">
                <TopBlocked apiDateOverride={apiDateOverride} />
              </div>
            ) : (
              <>
                <div className="personaCardCol col-md-6">
                  <DashboardRedFlags
                    data={redFlagsQuery.data}
                    isQueryLoading={redFlagsQuery.isLoading}
                    startDate={startDate}
                    endDate={endDate}
                    startTime={startTime}
                    endTime={endTime}
                  />
                </div>
                <div className="personaCardCol col-md-6">
                  <TopBlocked apiDateOverride={apiDateOverride} />
                </div>
              </>
            )}
          </div>
          <div className="personaCardRow row">
            <div className="personaCardCol col-md-3">
              <TrendingData apiDateOverride={apiDateOverride} />
            </div>
            <div className="personaCardCol col-md-3">
              <TopSixApps apiDateOverride={apiDateOverride} />
            </div>

            <ProtectedComponent allowedRoles={["owner"]}>
              <div className="personaCardCol col-md-3">
                <TransferRate />
              </div>

              <div className="personaCardCol col-md-3">
                <div className="personaMiniCardsContainer">
                  <div className="personaMiniCardsRow">
                    <PersonaMiniCard
                      total={activeUsersQuery.data ?? ""}
                      title="Active Users"
                      infoText="Active users on your network"
                      viewLink="/surfwize/device/status/hosts"
                    />
                    <PersonaMiniCard
                      total={activeDevicesQuery.data ?? ""}
                      title="Active Devices"
                      infoText="Active devices on your network"
                      viewLink="/surfwize/device/status/hosts"
                    />
                  </div>
                  <div className="personaMiniCardsRow">
                    <PersonaMiniCard
                      total={dataTransferQuery.data?.today_transfer ?? ""}
                      title="Today"
                      infoText="Total data downloaded and uploaded today"
                      viewLink="/surfwize/reporting/types"
                    />
                    <PersonaMiniCard
                      total={dataTransferQuery.data?.week_transfer ?? ""}
                      title="This week"
                      infoText="Total data downloaded and uploaded this week, from Monday"
                      viewLink="/surfwize/reporting/types"
                    />
                  </div>
                </div>
              </div>
            </ProtectedComponent>
          </div>
        </Box>
      </div>
      <FindUserModal open={showFindUser} onClose={handleHideFindUser} />

      {!contentAwareLicenseModalMessage()?.seen && contentAwareLicenseModalMessage()?.title && (
        <Modal
          isOpen={!contentAwareLicenseModalMessage()?.seen}
          onClose={closeContentAwareTrialPopup}
          size="sm"
          onPrimaryCTAClick={closeContentAwareTrialPopup}
          headerText={contentAwareLicenseModalMessage()?.title || ""}
          variant={contentAwareLicenseModalMessage()?.status}
          primaryCTALabel={"Ok, Got it"}
        >
          <ModalBody>
            <Text mb="sp24"></Text>
            <Text mb="sp24" dangerouslySetInnerHTML={{ __html: contentAwareLicenseModalMessage()?.description || "" }}></Text>
          </ModalBody>
        </Modal>
      )}
    </LeftPanel>
  ) : (
    <div className="centered">
      <ComponentLoading />
    </div>
  );
};

Dashboard.contextTypes = { router: PropTypes.object.isRequired };

export default Dashboard;
