import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { MultiSearchSelector, Option } from "@familyzone/component-library";
import { optionArrayToStringArray, parseDomain, stringArrayToOptionArray } from "./utils";

interface Props {
  placeholder: string;
  domains: string[];
  disabled?: boolean;
  onDomainsChanged: (domains: string[]) => void;
}

export const DomainsMultiSelectInput: FunctionComponent<Props> = (props) => {
  const [domainsOptions, setDomainOptions] = useState<string[]>([]);

  function inputChanged(e: ChangeEvent<HTMLInputElement>) {
    const parsedDomain = parseDomain(e.target.value);
    if (parsedDomain === null) {
      setDomainOptions([]);
      return;
    }
    setDomainOptions([parsedDomain]);
  }

  function selectedValuesChanged(selectedOptions: Option[]) {
    props.onDomainsChanged(optionArrayToStringArray(selectedOptions));
    setDomainOptions([]);
  }

  return (
    <MultiSearchSelector
      data-testid="domains-multi-select-input"
      placeholder={props.placeholder}
      options={stringArrayToOptionArray(domainsOptions)}
      selected={stringArrayToOptionArray(props.domains)}
      onInputChange={inputChanged}
      onChange={selectedValuesChanged}
      clearInputOnSelect
      filterOptions={false}
      disabled={props.disabled === true}
    />
  );
};
