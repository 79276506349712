import React from "react";
import { Link } from "react-router";
import Api from "../../utils/Api";
import PrivacyPolicyOld from "../PrivacyPolicyOld";

export default class LoginRegisterWelcome extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      error: "",
    };
  }

  componentDidMount() {
    let self = this;
    Api.unauthenticated_post(
      "/register/verify/" + this.props.params.email + "/" + this.props.params.code,
      {},
      function (data) {},
      function (data) {
        self.setState({
          error: "Error, please try again or contact support",
        });
      }
    );
  }

  handle_login = () => {
    this.props.history.push("/login");
  };

  render() {
    if (this.state.error) {
      return (
        <div id="login-main-content">
          <div className="background"></div>
          <div className="flex-row-center">
            <div className="login-container">
              <div id="logo"></div>
              <h1 className="title-password-reset">We could not verify your account</h1>
              <p className="subtitle">Try copy and pasting the URL that we sent in the activation email instead, or contact support</p>
              <div className="login-input-container">
                <div className="link-container">
                  <Link className="link-as-button" to="/login">
                    <div title={"log in"}>Log in</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <PrivacyPolicyOld />
        </div>
      );
    }
    return (
      <div id="login-main-content">
        <div className="background"></div>
        <div className="flex-row-center">
          <div className="login-container">
            <div id="logo"></div>
            <h1 className="title-password-reset">Account verified</h1>
            <p className="subtitle">Thanks for activating your account</p>
            <p className="subtitle">Use the temporary password in your activation email to log in to your account</p>
            <div className="login-input-container">
              <div className="link-container">
                <Link className="link-as-button" to="/login">
                  <div title={"log in"}>Log in</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <PrivacyPolicyOld />
      </div>
    );
  }
}
