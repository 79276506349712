import Api from "../../utils/Api";
import SessionActions from "../../actions/SessionActions";
import { SessionState } from "../../storez/SessionStore";

export interface LoginInitiationRequest {
  appState: string;
  /**
   * redirectLocal: Optional Boolean value only to used for localhost redirects and testing locally
   * by default QAuth will not work when running locally.
   * This field will enabled testing QAuth login flow locally and redirect to localhost callback.
   * NOTE: This is only available for SIT region for dev purpose only not available in any other regions.
   */
  redirectLocal?: boolean;
}

export interface InitiateLoginResponse {
  loginUrl: string;
  codeChallengeMethod: string;
}

export interface QAuthLoginResponse {
  code: string;
  state: string;
}

interface TokenExchangeRequest {
  state: string;
  code: string;
  /**
   * redirectLocal: Optional Boolean value only to used for localhost redirects and testing locally
   * by default QAuth will not work when running locally.
   * This field will enabled testing QAuth login flow locally and redirect to localhost callback.
   * NOTE: This is only available for SIT region for dev purpose only not available in any other regions.
   */
  redirectLocal?: boolean;
}

export const GetLoginUrl = (callback: (data: InitiateLoginResponse) => void): void => {
  try {
    const appState = `sm_login_${Math.floor(Math.random() * 1000 + 1)}`;
    const requestBody: LoginInitiationRequest = {
      appState: appState,
    };
    Api.unauthenticated_post(
      "/auth/v1/initiate",
      requestBody,
      (data: unknown) => callback(data as InitiateLoginResponse),
      (e: Error) => console.error(e)
    );
  } catch (e) {
    console.error(e);
  }
};

export const TokenExchange = (code: string, state: string): void => {
  try {
    const request: TokenExchangeRequest = {
      state: state,
      code: code,
    };
    Api.unauthenticated_post(
      "/auth/v1/token",
      request,
      (data: unknown) => {
        const session = data as SessionState;
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return */
        SessionActions.authenticateSuccess(session);
      },
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return */
      (e: Error) => SessionActions.authenticateFailure(e)
    );
  } catch (e) {
    console.error(e);
  }
};
