import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { FormControl, FormHelperText } from "@chakra-ui/form-control";
import { Box, Button, useTranslation, useToast, Flex, Checkbox } from "@familyzone/component-library";
import CardBasedPage from "../../templates/CardBasedPage";
import {
  getClassroomFeatureFlags,
  ClassroomFeatureFlagsResponseShape,
  updateClassroomFeatureFlags,
} from "../../../utils/api/ClassroomFeatureFlags";
import { convertArrayToFeatureFlags, convertFeatureFlagsToArray } from "./utils";
import { ClassroomFeatureFlags, defaultClassroomFlags, useClassroomFeatureFlagStore } from "../../../storez/ClassroomFeatureFlagStore";
import { SectionHeading } from "../../shared/SectionHeading";

const ClassroomFeatureFlagsPage: FunctionComponent = () => {
  const classroomFeatureFlagStore = useClassroomFeatureFlagStore();

  const { t } = useTranslation();
  const title = t("Feature Flags (Support Admin Only)");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Classwize"), url: "/config/device/classwize", isActive: false },
    { title: t("Feature Flags"), isActive: true },
  ];

  const { successToast, errorToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  const [featureFlags, setFeatureFlags] = useState(defaultClassroomFlags);

  const handleCheckboxChange = (flag: keyof ClassroomFeatureFlags) => (event: ChangeEvent<HTMLInputElement>) => {
    setFeatureFlags({
      ...featureFlags,
      [flag]: event.target.checked,
    });
  };

  async function fetchScreenshotConfig(): Promise<void> {
    setLoading(true);

    const featureFlagsResponse = await getClassroomFeatureFlags();
    const featureFlagsJson = (await featureFlagsResponse.json()) as ClassroomFeatureFlagsResponseShape;
    setFeatureFlags(convertArrayToFeatureFlags(featureFlagsJson.enabledFeatures));

    setLoading(false);
  }

  useEffect(() => {
    fetchScreenshotConfig().then(
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  function saveFeatureFlags(): void {
    const featuresArray = convertFeatureFlagsToArray(featureFlags);

    updateClassroomFeatureFlags(featuresArray)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save feature flags");
        }

        // Update state so the navbar shows/hides the menu item
        classroomFeatureFlagStore.setFlags(featureFlags);

        successToast({
          title: t("Saved successfully"),
          description: t("Screenshot configuration has been saved successfully."),
          position: "top",
          isClosable: true,
        });
      })
      .catch(() => {
        errorToast({
          title: t("Error saving feature flags"),
          description: "Please raise a dev esco if the issue persists.",
          position: "top",
          isClosable: true,
        });
      })
      .finally(() => {
        setSaving(false);
      });

    return;
  }

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box p="sp24">
        <SectionHeading
          title={t("Classroom Feature Flags")}
          tooltip={t("These feature flags are only for Classwize and can only be toggled by Support Admins.")}
        />

        <Flex px="sp12" py="sp16" direction="column" gap="sp16" mt={10}>
          <FormControl isDisabled={loading || saving}>
            <Checkbox
              id="teacher_screen_share"
              isChecked={featureFlags.screenShare}
              isDisabled={loading || saving}
              onChange={handleCheckboxChange("screenShare")}
            >
              {t("Enable Screen Share")}
            </Checkbox>
            <FormHelperText ml="sp24" color="neutrals.200">
              {t("Allows teachers to share their screen with their students in an active class.")}
            </FormHelperText>
          </FormControl>
        </Flex>

        <Flex px="sp12" py="sp16" direction="column" gap="sp16" mt={10}>
          <FormControl isDisabled={loading || saving}>
            <Checkbox
              id="teacher_screen_share"
              isChecked={featureFlags.schoolAssociations}
              isDisabled={loading || saving}
              onChange={handleCheckboxChange("schoolAssociations")}
            >
              {t("Enable School Associations (In development, DO NOT USE)")}
            </Checkbox>
            <FormHelperText ml="sp24" color="neutrals.200">
              {t("Allows teachers to add only students from their own school to their classes.")}
            </FormHelperText>
          </FormControl>
        </Flex>
      </Box>
      <Box bg={"#F5FCFF"} p="sp24" overflow={"hidden"}>
        <Button variant="primary" disabled={saving || loading} onClick={saveFeatureFlags} data-testid="save-feature-flags-button">
          {saving ? t("Saving") : t("Save")}
        </Button>
      </Box>
    </CardBasedPage>
  );
};

export default ClassroomFeatureFlagsPage;
