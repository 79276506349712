import React from "react";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import SearchableSelect from "../../modules/SearchableSelect";

import DeviceStatusStore from "../../stores/DeviceStatusStore";
import SessionStore from "../../stores/SessionStore";
import SessionActions from "../../actions/SessionActions";

import ConfigStore from "../../stores/ConfigStore";
import ConfigActions from "../../actions/ConfigActions";

import { validateContentHubUrl } from "../../utils/WebsiteUtilNew";
import { useCommunityFeatureFlagStore } from "../../storez/CommunityFeatureFlagStore";

export default class ManageDeviceSettings extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      deviceid: "",
      devices: [],
      key: "",
      user_defined_name: "",
      display_name: "",
      content_hub_url: "",
      timezone: "",
      timezones: [],
      all_timezones: [],
      dmi_info: {},

      isSupportAdmin: SessionStore.isSupportAdmin(),
      saving: false,
      changes: false,
      validUrl: true,
      validDisplayName: true,

      update_allow_auto: false,
      update_branch: "",
      update_start_time: "",
      update_end_time: "",
      update_arch: "",
      current_version: "",
      isParentDevice: false,
      parentDevice: "",
      enableExtensionChromebooksOnly: false,
      studentAutoEnrolment: false,
      allowSupportClassroom: false,
      customer_internal_company: "",
      hubspot_company_id: "",
    };

    this.customer_internal_company_mapping = [
      ["linewize", "Linewize"],
      ["smoothwall", "Smoothwall"],
    ];
  }

  componentDidMount() {
    this.handle_load();
    DeviceStatusStore.listen(this.onChange);
    SessionStore.listen(this.onChange);
    ConfigStore.listen(this.configChange);
    setTimeout(() => {
      void useCommunityFeatureFlagStore.getState().getOrFetch();
      SessionActions.fetchAvailableDevices();
      ConfigActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    DeviceStatusStore.unlisten(this.onChange);
    SessionStore.unlisten(this.onChange);
    ConfigStore.unlisten(this.configChange);
  }

  onChange = () => {
    this.setState({
      devices: SessionStore.getAvailableDevices(),
      current_version: DeviceStatusStore.getVersion(),
      isSupportAdmin: SessionStore.isSupportAdmin(),
    });
  };

  configChange = () => {
    let self = this;
    self.setState({
      deviceid: ConfigStore.getItemInConfig("deviceid"),
      key: ConfigStore.getItemInConfig("key"),
      user_defined_name: ConfigStore.getItemInConfig("user_defined_name"),
      display_name: ConfigStore.getItemInConfig("display_name"),
      content_hub_url: ConfigStore.getItemInConfig("content_hub_url"),
      timezone: ConfigStore.getItemInConfig("timezone"),
      update_allow_auto: ConfigStore.getItemInConfig("update_allow_auto"),
      update_branch: ConfigStore.getItemInConfig("update_branch"),
      update_start_time: ConfigStore.getItemInConfig("update_start_time"),
      update_end_time: ConfigStore.getItemInConfig("update_end_time"),
      update_arch: ConfigStore.getItemInConfig("update_arch"),
      parentDevice: ConfigStore.getItemInConfig("parent_id"),
      isParentDevice: ConfigStore.getItemInConfig("is_parent"),
      enableExtensionChromebooksOnly: ConfigStore.getItemInConfig("enable_extension_chromebooks_only"),
      studentAutoEnrolment: ConfigStore.getItemInConfig("student_auto_enrolment"),
      allowSupportClassroom: ConfigStore.getItemInConfig("allow_support_classroom"),
      customer_internal_company: ConfigStore.getItemInConfig("customer_internal_company"),
      hubspot_company_id: ConfigStore.getItemInConfig("hubspot_company_id"),
    });
  };

  handle_load = () => {
    let self = this;
    Api.get("/managedevice/ajax/device/timezones", function (result) {
      self.setState({
        timezones: result["result"],
      });
    });
    Api.get("/managedevice/ajax/device/all-timezones", function (result) {
      self.setState({
        all_timezones: result["result"],
      });
    });
    if (this.state.isSupportAdmin) {
      Api.get("/info", function (result) {
        self.setState({
          dmi_info: result?.device_information?.dmi_info || {},
        });
      });
    }
  };

  handle_Submit = () => {
    if (this.state.changes) {
      this.setState({
        saving: true,
        changes: false,
      });

      let settings = {
        key: this.state.key,
        user_defined_name: this.state.user_defined_name,
        display_name: this.state.display_name,
        content_hub_url: this.state.content_hub_url,
        timezone: this.state.timezone,
        update_allow_auto: this.state.update_allow_auto,
        update_branch: this.state.update_branch,
        update_start_time: this.state.update_start_time,
        update_end_time: this.state.update_end_time,
        update_arch: this.state.update_arch,
        is_parent: this.state.isParentDevice,
        parent_id: this.state.parentDevice,
        enable_extension_chromebooks_only: this.state.enableExtensionChromebooksOnly,
        student_auto_enrolment: this.state.studentAutoEnrolment,
        allow_support_classroom: this.state.allowSupportClassroom,
        customer_internal_company: this.state.customer_internal_company,
        hubspot_company_id: this.state.hubspot_company_id,
      };

      let self = this;
      Api.post("/managedevice/ajax/device", settings, function (e) {
        self.setState({
          saving: false,
        });
      });
    }
  };

  handle_ChangeKey = (event) => {
    this.setState({
      key: event.target.value,
      changes: true,
    });
  };

  handle_ChangeName = (event) => {
    this.setState({
      user_defined_name: event.target.value,
      changes: true,
    });
  };

  handle_ChangeDisplayName = (event) => {
    const newName = event.target.value;

    // School name is required when Community is enabled; otherwise, optional
    const validDisplayName = useCommunityFeatureFlagStore.getState().flags?.enableCommunity ? !!newName : true;

    this.setState({
      display_name: newName,
      validDisplayName: validDisplayName,
      changes: true,
    });
  };

  handle_ChangeContentHubUrl = (event) => {
    this.setState({
      content_hub_url: event.target.value,
      validUrl: validateContentHubUrl(event.target.value),
      changes: true,
    });
  };

  handle_ChangeHubspotCompanyId = (event) => {
    if (event.target.value.trim() === "") {
      return;
    }

    this.setState({
      hubspot_company_id: event.target.value,
      changes: true,
    });
  };

  handle_ChangeTimezone = (event) => {
    this.setState({
      timezone: event.value,
      changes: true,
    });
  };

  get_SelectionMap = (current_timezone) => {
    let ret = [];
    for (let timezone of this.state.timezones) {
      ret.push([timezone, timezone]);
    }
    if (!this.state.timezones.includes(current_timezone)) {
      ret.push([current_timezone, current_timezone]);
    }
    return ret;
  };

  handle_ChangeUpdateEnable = (event) => {
    this.setState({
      update_allow_auto: event.target.checked,
      changes: true,
    });
  };

  handle_ChangeIsParent = (event) => {
    this.setState({
      isParentDevice: event.target.checked,
      changes: true,
    });
  };

  handle_ChangeEnableExtensionChromebooksOnly = (event) => {
    this.setState({
      enableExtensionChromebooksOnly: event.target.checked,
      changes: true,
    });
  };

  handle_ChangeStudentAutoEnrolment = (event) => {
    this.setState({
      studentAutoEnrolment: event.target.checked,
      changes: true,
    });
  };

  handle_ChangeAllowSupportClassroom = (event) => {
    this.setState({
      allowSupportClassroom: event.target.checked,
      changes: true,
    });
  };

  handle_changeParentDevice = (event) => {
    this.setState({
      parentDevice: event.value,
      changes: true,
    });
  };

  handle_ChangeUpdateBranch = (event) => {
    this.setState({
      update_branch: event.value,
      changes: true,
    });
  };
  handle_ChangeArch = (event) => {
    this.setState({
      update_arch: event.value,
      changes: true,
    });
  };

  handle_ChangeUpdateStartTime = (event) => {
    this.setState({
      update_start_time: event.value,
      changes: true,
    });
  };

  handle_ChangeUpdateEndTime = (event) => {
    this.setState({
      update_end_time: event.value,
      changes: true,
    });
  };

  handle_ChangeCustomerType = (entry) => {
    this.setState({
      customer_internal_company: entry.value,
      changes: true,
    });
  };

  get_DeviceSelectionMap() {
    let ret = [];
    for (let device of this.state.devices) {
      if (this.state.deviceid !== device.deviceid) {
        ret.push([device.deviceid, device.device.user_defined_name + " (" + device.deviceid + ")"]);
      }
    }
    return ret;
  }

  canSubmit() {
    return this.state.validUrl && this.state.validDisplayName;
  }

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Device Options</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Device ID</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.deviceid} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Device Key</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          value={this.state.key}
                          onChange={this.handle_ChangeKey}
                          data-cy="device-options-device-key-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Device Name</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          value={this.state.user_defined_name}
                          onChange={this.handle_ChangeName}
                          data-cy="device-options-device-name-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title" id="display-name-input">
                      Organisation Name
                    </div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          aria-labelledby="display-name-input"
                          value={this.state.display_name}
                          onChange={this.handle_ChangeDisplayName}
                          data-cy="device-options-device-display-name-input"
                        />
                        <p className={this.state.validDisplayName ? "hide" : "errorMessage"}>
                          Organisation name can't be empty because Community is enabled
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Content Hub</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          aria-label="content-hub-input"
                          value={this.state.content_hub_url}
                          onChange={this.handle_ChangeContentHubUrl}
                          data-cy="device-options-device-content-hub-input"
                        />
                        <p className={this.state.validUrl ? "hide" : "errorMessage"}>Please enter a valid url</p>
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Timezone</div>
                    <div className="formgroup-element-fields">
                      <SearchableSelect
                        value={this.state.timezone}
                        clearable={false}
                        onChange={this.handle_ChangeTimezone}
                        valueDataMap={this.get_SelectionMap(this.state.timezone)}
                        data-cy="device-options-timezone"
                      ></SearchableSelect>
                      {this.state.timezone && !this.state.all_timezones.includes(this.state.timezone) && (
                        <p className="errorMessage" data-testid="invalid-tz-error-msg">
                          Please select a valid timezone
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Multi-Device Management</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Is Parent</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.isParentDevice}
                          onChange={this.handle_ChangeIsParent}
                          disabled={this.state.parentDevice}
                          data-cy="device-options-is-parent-checkbox"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Parent Device</div>
                    <div className="formgroup-element-fields">
                      <SearchableSelect
                        value={this.state.parentDevice}
                        onChange={this.handle_changeParentDevice}
                        valueDataMap={this.get_DeviceSelectionMap()}
                        disabled={this.state.isParentDevice}
                        data-cy="device-options-parent-device"
                      ></SearchableSelect>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Agent</div>
                </div>
                <div className="formgroup-content" title={"Enable browser extension for Chromebooks only"}>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Enable Browser Extension for Chromebooks Only</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.enableExtensionChromebooksOnly}
                          onChange={this.handle_ChangeEnableExtensionChromebooksOnly}
                          data-cy="device-options-enable-extension-checkbox"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formgroup-content" title={"Student Auto Enrolment"}>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Student Auto Enrolment</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.studentAutoEnrolment}
                          onChange={this.handle_ChangeStudentAutoEnrolment}
                          data-cy="device-options-student-enrolment-checkbox"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.isSupportAdmin && (
                <div className="formgroup">
                  <div className="formgroup_title">
                    <div className="formgroup_title_title">Advanced Configuration</div>
                  </div>
                  <div className="formgroup-content">
                    <div className="formgroup-element">
                      <div className="formgroup-element-title">Internal Company of Customer</div>
                      <div className="formgroup-element-fields">
                        <SearchableSelect
                          value={this.state.customer_internal_company}
                          onChange={this.handle_ChangeCustomerType}
                          valueDataMap={this.customer_internal_company_mapping}
                          placeholder={"Select a customer type..."}
                        />
                      </div>
                    </div>
                    <div className="formgroup-element">
                      <div className="formgroup-element-title">QID</div>
                      <div className="formgroup-element-fields">
                        <div className="mui-textfield">
                          <input
                            type="text"
                            aria-label="hubspot-company-id"
                            value={this.state.hubspot_company_id}
                            onChange={this.handle_ChangeHubspotCompanyId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.isSupportAdmin && Object.keys(this.state.dmi_info).length > 0 && (
                <div className="formgroup">
                  <div className="formgroup_title">
                    <div className="formgroup_title_title">Appliance DMI Information</div>
                  </div>
                  <div className="formgroup-content">
                    <table style={{ fontSize: "inherit" }}>
                      <tbody>
                        {Object.entries(this.state.dmi_info).map(([k, v]) => (
                          <tr key={k}>
                            <td style={{ padding: "0.5em 0", paddingRight: "1em" }}>{k}</td>
                            <td style={{ padding: "0.5em 0", wordBreak: "break-all" }}>{v}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton
              saving={this.state.saving}
              changes={this.state.changes}
              onClick={this.handle_Submit}
              disabled={!this.canSubmit()}
            />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
