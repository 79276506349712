import moment, { Moment } from "moment";
import dayjs from "dayjs";
import Api from "../utils/Api";

export type ApiDates = {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  relativeKey?: string;
};

export function humanizeDiffUnixAndNow(unixTimestamp: number | undefined, defaultStr: string): string {
  if (!unixTimestamp) {
    return defaultStr;
  }

  const unixTimestampMoment = moment.unix(unixTimestamp);
  return moment.duration(unixTimestampMoment.diff(moment())).humanize(true);
}

/*
 * Formats database's timestamp to human-readable string based on current timezone
 * Input: "2020-12-02T04:07:49+00:00"
 * Output: "02/12/2020 12:07:49 pm"
 */
export function getDateTimeString(date: string, locale: string): string {
  const dateObject = new Date(date);
  return dateObject.toLocaleDateString(locale) + " " + dateObject.toLocaleTimeString(locale);
}

/*
 * Formats unix timestamp to human-readable string
 * Output: "1st Feb 23:05"
 */
export function formatUnixTimestamp(unixTimestamp: number | string | undefined, format = "Do MMM HH:mm"): string {
  if (unixTimestamp) {
    return moment.unix(Number(unixTimestamp)).format(format);
  }
  return "";
}

/**
 * Given an input string, auto-inserts a colon
 * @param {string} inputStr
 * @returns
 */
export const timeInputAutoInsertColon = (inputStr: string): string => {
  if (inputStr.length === 2 && /\d{2}/.test(inputStr)) {
    inputStr += ":";
  }
  return inputStr;
};

/**
 * Given an input string, auto-inserts the leading zero
 * @param {string} inputStr
 * @returns
 */
export const timeInputAutoInsertLeadingZero = (inputStr: string): string => {
  if (inputStr.length === 2 && /\d:/.test(inputStr)) {
    inputStr = "0" + inputStr;
  }
  return inputStr;
};

/**
 * Given an input string, returns true if time format in HH:MM
 * @param {string} inputStr
 * @returns
 */
export const isValidTime = (inputStr: string): boolean => {
  return inputStr.length === 5 && /([0-1][0-9]|2[0-3]):[0-5][0-9]/.test(inputStr) ? true : false;
};

/**
 * Converts a number into `HH:MM` formatted string.
 *
 * @param time time represented as `hours * 100 + minutes`.
 * @returns time in `HH:MM` format if given `time` is a number greater than
 * or equal to `0`; otherwise, an empty string.
 */
export const convertToPrintableTime = (time?: number): string => {
  let value = "" + String(time);

  if (!time) {
    // (!time) can also be 0 so handle that here.
    if (time === 0) value = "0000";
    else return "";
  }

  if (time < 0) {
    return "";
  } else if (time < 6) {
    value = "000" + String(time);
  } else if (time < 60) {
    value = "00" + String(time);
  } else if (time < 1000) {
    value = "0" + String(time);
  }

  const hours = value.slice(0, 2);
  const minutes = value.slice(2, 4);
  return hours + ":" + minutes;
};

/**
 * Converts `HH:MM` or `HHMM` formatted time string into a number.
 *
 * @param time time in `HH:MM` or `HHMM` format.
 * @returns time in `hours * 100 + minutes` format if given a valid time string; otherwise, `-1`.
 */
export const convertToIntegerTime = (time?: string): number => {
  if (!time) return -1;

  const hasColon = time.indexOf(":") >= 0;

  // Add ":" after the 2nd number from the end of time string
  if (!hasColon) {
    const timeWithColon = time.split("");
    timeWithColon.splice(-2, 0, ":");
    time = timeWithColon.join("");
  }

  const components = time.split(":");
  const hours = parseInt(components[0]);
  const minutes = parseInt(components[1]);
  return hours * 100 + minutes;
};

/** Expands a date string into a date, start hour, end hour given a minutes window */
export const getDateWindowRange = (dateString: string, minutesWindow: number): [number, number] => {
  const date = new Date(dateString);
  const start = new Date(date.getTime() - minutesWindow * 60000); // subtract minutes from date in milliseconds
  const end = new Date(date.getTime() + minutesWindow * 60000); // add minutes to date in milliseconds

  return [start.getTime(), end.getTime()];
};

export const momentDateToApiDate = (startDate: Moment, endDate: Moment): ApiDates => {
  return {
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
    startTime: startDate.format("HH:mm:ss"),
    endTime: endDate.format("HH:mm:ss"),
  };
};

export const dayjsDateToApiDate = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): ApiDates => {
  return {
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
    startTime: startDate.format("HH:mm:ss"),
    endTime: endDate.format("HH:mm:ss"),
  };
};

export const shouldUseUSDateFormat = async (): Promise<boolean> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const result: { data: { timezone: string } } = await Api.getAsync("/managedevice/ajax/device");
  const data = result["data"];
  const timezone = data["timezone"];
  return !!timezone && (timezone.startsWith("US") || timezone.startsWith("America"));
};

export const getDateTime = (date: string, time: string): dayjs.Dayjs => {
  return dayjs(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
};

/**
 * Computes the trial period details using the provided start and end dates.
 *
 * This function calculates the following details:
 * - **Trial Start Date**: The formatted start date of the trial period.
 * - **Trial Expiry Date**: The formatted end date of the trial period.
 * - **Trial Duration**: The total length of the trial period in days (calculated regardless of the current date).
 * - **Trial Days Lapsed**: The number of days elapsed from the start date up to the current date.
 *
 * If the start date is after the end date, the returned values may not reflect a valid trial period,
 * so this scenario should be handled appropriately by the caller.
 *
 * @param sDate - The UNIX timestamp (in seconds) representing the trial's start date.
 * @param eDate - The UNIX timestamp (in seconds) representing the trial's end date.
 * @returns An object containing formatted dates and trial period metrics.
 */
export const calculateTrialPeriodDetails = (
  sDate: string,
  eDate: string
): {
  trialStartDate: string;
  trialExpiryDate: string;
  trialDuration: number;
  trialDaysLapsed: number;
} => {
  const startDate = dayjs.unix(Number(sDate));
  const endDate = dayjs.unix(Number(eDate));

  const trialStartDate = startDate.format("MMM D, YYYY");
  const trialExpiryDate = endDate.format("MMM D, YYYY");
  const trialDuration = endDate.diff(startDate, "days");
  const trialDaysLapsed = endDate.diff(dayjs().startOf("day"), "days");

  return {
    trialStartDate,
    trialExpiryDate,
    trialDuration,
    trialDaysLapsed,
  };
};
