import { Box, Flex, Icon, InlineNotification, TableIconButton, Td, Text, Tooltip, Tr, useTranslation } from "@familyzone/component-library";
import React from "react";
import CreateTemporarySession from "../../../modules/CreateTemporarySession";
import { DeleteButtonNew } from "../../../modules/DeleteButtonNew";
import TableBasedPage from "../../templates/TableBasedPage";
import AddUser from "./AddUser";
import PurgeUsers from "./PurgeUsers";
import { formatUsers } from "./UsersNewHelpers";
import ArchivedStatusPill from "../ArchivedStatusPill";
import Link from "../../Link";
import { UserUMS } from "../../../types/Users";
import { PurgeType } from "../../../utils/api/Helpers";

interface UsersProps {
  users: UserUMS[];
  loaded: boolean;
  onPurgeUsers: (usersToPurge: PurgeType) => void;
  onDeleteUser: (id: string) => void;
  withinTableChildren: JSX.Element;
  isSearching?: boolean;
}

const UsersNew: React.FC<UsersProps> = ({ users, loaded, onPurgeUsers, onDeleteUser, withinTableChildren, isSearching = false }) => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Users and Groups"), url: "/config/device/userdb", isActive: false },
    { title: t("Users"), url: "/config/device/userdb/users", isActive: true },
  ];

  const columns = [
    { headerText: t("Username"), columnName: "username", sortable: true, searchable: true },
    { headerText: t("DN"), columnName: "dn", sortable: true, searchable: true },
    { headerText: t("Email"), columnName: "email", sortable: true, searchable: true },
    { headerText: t("First Name"), columnName: "firstName", sortable: true, searchable: true },
    { headerText: t("Last Name"), columnName: "lastName", sortable: true, searchable: true },
    { headerText: t("Status"), columnName: "archived", sortable: true, searchable: false },
    { headerText: t("Provider"), columnName: "sourceType", sortable: false, searchable: false },
    { headerText: t("Operations"), columnName: "operations", sortable: false, searchable: false },
  ];

  const operations = (username: string, id: string, isLocal: boolean, archived?: boolean): JSX.Element => {
    if (archived || !isLocal) {
      return (
        <Flex>
          <Tooltip label={t("View")} hasArrow variant={"dark"}>
            <Box mr="sp8">
              <Link to={`/config/device/userdb/users/id/${id}`}>
                <TableIconButton
                  data-testid="users-view-button"
                  aria-label="View"
                  icon={<Icon icon="fa-eye" variant="solid" color="text.paragraph.light" />}
                />
              </Link>
            </Box>
          </Tooltip>
        </Flex>
      );
    } else {
      return (
        <Flex>
          <Tooltip label={t("Edit")} hasArrow variant={"dark"}>
            <Box mr="sp8">
              <Link to={`/config/device/userdb/users/id/${id}`}>
                <TableIconButton
                  data-testid="users-edit-button"
                  aria-label="Edit"
                  icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
                />
              </Link>
            </Box>
          </Tooltip>
          <Tooltip label={t("Delete")} hasArrow variant={"dark"}>
            <Box mr="sp8">
              <DeleteButtonNew onClick={() => onDeleteUser(id)} titleToDelete="User" bodyToDelete="user" />
            </Box>
          </Tooltip>

          <Tooltip label={t("Create Temporary Session")} hasArrow variant={"dark"}>
            <Box mr="sp8">
              <CreateTemporarySession username={username} />
            </Box>
          </Tooltip>
        </Flex>
      );
    }
  };

  const tableDataMap = (user: UserUMS, index: number): JSX.Element => {
    return (
      <Tr key={index}>
        <Td data-testid="user-username">
          <Link to={`/config/device/userdb/users/id/${user.id}`}>
            <Text fontSize={"sm"}>{user.username}</Text>
          </Link>
        </Td>
        <Td data-testid="user-dn">
          <Text>{user.distinguishedName}</Text>
        </Td>
        <Td data-testid="user-email">
          <Text>{user.email}</Text>
        </Td>
        <Td data-testid="user-first-name">
          <Text>{user.firstName}</Text>
        </Td>
        <Td data-testid="user-last-name">
          <Text>{user.lastName}</Text>
        </Td>
        <Td data-testid="user-status">
          <ArchivedStatusPill archived={user.archived} />
        </Td>
        <Td data-testid="user-provider">
          <Text>{user.sourceType}</Text>
        </Td>
        <Td>
          {operations(user.username, user.id, user.sourceType !== undefined && user.sourceType.toLowerCase() === "local", user.archived)}
        </Td>
      </Tr>
    );
  };

  const aboveTableChildren = (): JSX.Element => {
    return (
      <Flex mt="sp24" mr="sp24" justifyContent="end">
        <Box mr="sp8">
          <AddUser />
        </Box>
        <PurgeUsers onPurgeUsers={onPurgeUsers} />
      </Flex>
    );
  };

  const moreResultsNotification = (): JSX.Element => {
    if (!loaded || isSearching) {
      return <></>;
    }
    return (
      <Box ml="sp12" minW="300px" maxH="sp12">
        <InlineNotification
          notificationDescription="Please use search to display more users."
          notificationTitle={`Showing you ${users.length} user(s)`}
          status="info"
        />
      </Box>
    );
  };

  const tableChildren = (): JSX.Element => {
    return (
      <Flex>
        {withinTableChildren}
        {moreResultsNotification()}
      </Flex>
    );
  };

  return (
    <TableBasedPage
      title="Users"
      loaded={loaded}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={formatUsers(users)}
      tableDataMap={tableDataMap}
      children={aboveTableChildren()}
      childrenInTableHeader={tableChildren()}
      showSearch={false}
    />
  );
};

export default UsersNew;
