import React, { useState } from "react";
import PropTypes from "prop-types";

import { UserUMS } from "../../../types/Users";
import { deleteUser, purgeUsers, searchUsers } from "../../../utils/api/Users";
import UsersNew from "./UsersNew";
import { useToast } from "@familyzone/component-library";
import { PurgeType } from "../../../utils/api/Helpers";
import UserSearchBox from "../../UserSearch/UserSearchBox";

const Users: React.FC = () => {
  const { successToast, errorToast } = useToast();
  const [users, setUsers] = useState<UserUMS[]>([]);
  const [loading, setLoading] = useState(false);

  const handleDelete = (id: string) => {
    deleteUser(id)
      .then(() => {
        successToast({
          title: "Success!",
          description: "We've received the request and it has been sent for processing.",
        });
      })
      .catch(() => {
        errorToast({
          title: "Error",
          description: "We're not able to delete the user at this time. Please try again later.",
        });
      });
  };

  const handlePurgeUsers = (type: PurgeType) => {
    purgeUsers(type)
      .then(() => {
        successToast({
          title: "Success!",
          description: "We've received the request and it has been sent for processing.",
        });
      })
      .catch(() => {
        errorToast({
          title: "Error",
          description: "We're not able to process the purge at this time. Please try again later.",
        });
      });
  };

  return (
    <UsersNew
      users={users}
      loaded={!loading}
      onPurgeUsers={handlePurgeUsers}
      onDeleteUser={handleDelete}
      withinTableChildren={
        <UserSearchBox
          searchEnabled={true}
          setUsers={setUsers}
          setLoading={setLoading}
          excludeArchivedUsers={false}
          searchUsers={searchUsers}
        />
      }
      isSearching={loading}
    />
  );
};

Users.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default Users;
