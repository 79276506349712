class LayoutConfig {
  constructor() {
    this.layout = {
      familyzone: {
        logo: "/static/images/logo.png",
        login_logo: "/static/images/logo_family_zone_horizontal_blue.svg",
        classwize_link: "https://classwize.syd-2.linewize.net",
        title: "Linewize Filter",
        region: "syd-2",
      },
      "us-1": {
        logo: "/static/images/logo.png",
        login_logo: "/static/images/logo_family_zone_horizontal_blue.svg",
        classwize_link: "https://us.classroom.qoria.cloud",
        title: "Linewize Filter",
        region: "us-1",
      },
      "au-1": {
        logo: "/static/images/logo.png",
        login_logo: "/static/images/logo_family_zone_horizontal_blue.svg",
        classwize_link: "https://classroom.au-1.familyzone.io",
        title: "Linewize Filter",
        region: "syd-2",
      },
      "uk-1": {
        logo: "/static/images/logo.png",
        login_logo: "/static/images/logo_family_zone_horizontal_blue.svg",
        classwize_link: "https://classwize.uk-1.linewize.net",
        title: "Linewize Filter",
        region: "uk-1",
      },
      beta: {
        logo: "/static/images/logo.png",
        login_logo: "/static/images/logo.png",
        login_logo_inverted: "/static/images/logo_inverted.png",
        classwize_link: "https://classwize.qoria-stg.cloud",
        title: "Beta Region",
        region: "beta-1",
      },
      sit: {
        logo: "/static/images/logo.png",
        login_logo: "/static/images/logo.png",
        login_logo_inverted: "/static/images/logo_inverted.png",
        classwize_link: "https://sit.classwize.linewize.net",
        title: "Dev/Test Region",
        region: "sit",
      },
    };

    this.layout["cloud.sit.linewize.net"] = this.layout["sit"];
    this.layout["linewizefilter.qoria-sit.cloud"] = this.layout["sit"];
    this.layout["schoolmanager.qoria-sit.cloud"] = this.layout["sit"];

    this.layout["localhost"] = this.layout["beta"];
    this.layout["cloud.beta-1.linewize.net"] = this.layout["beta"];
    this.layout["linewizefilter.qoria-stg.cloud"] = this.layout["beta"];
    this.layout["schoolmanager.qoria-stg.cloud"] = this.layout["beta"];

    this.layout["schoolmanager.uk-1.linewize.net"] = this.layout["uk-1"];
    this.layout["uk.linewizefilter.qoria.cloud"] = this.layout["uk-1"];

    this.layout["cloud.syd-2.linewize.net"] = this.layout["au-1"];
    this.layout["schoolmanager.au-1.familyzone.io"] = this.layout["au-1"];
    this.layout["au.linewizefilter.qoria.cloud"] = this.layout["au-1"];

    this.layout["cloud.syd-1.linewize.net"] = this.layout["us-1"];
    this.layout["schoolmanager.us-1.familyzone.io"] = this.layout["us-1"];
    this.layout["us.linewizefilter.qoria.cloud"] = this.layout["us-1"];
  }

  getResource(name) {
    if (this.layout[window.location.hostname]) {
      return this.layout[window.location.hostname][name];
    }
    return this.layout["us-1"][name];
  }

  logo() {
    return this.getResource("logo");
  }

  region() {
    return this.getResource("region");
  }

  login_logo(inverted) {
    if (inverted && this.getResource("login_logo_inverted")) {
      return this.getResource("login_logo_inverted");
    }
    return this.getResource("login_logo");
  }

  classwize_link() {
    return this.getResource("classwize_link");
  }

  title() {
    return this.getResource("title");
  }
}

const Layout = new LayoutConfig();
export default Layout;
